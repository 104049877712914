import moment from 'moment';

import { TEACHER_SCHEDULE_ACTIONS } from '../actions/types';

const initialState = {
  calendarType: 'day',
  currentDate: moment(),
  listOfReasons: [],
  schedule: [],
  activeEvent: {},
};

export const teacherScheduleReducer = (state = initialState, action) => {
  const { payload, type } = action;

  switch (type) {
    case TEACHER_SCHEDULE_ACTIONS.SET_CURRENT_DATE:
      return {
        ...state,
        currentDate: payload,
      };

    case TEACHER_SCHEDULE_ACTIONS.SET_CALENDAR_TYPE:
      return {
        ...state,
        calendarType: payload,
      };

    case TEACHER_SCHEDULE_ACTIONS.SET_LIST_OF_REASONS:
      return {
        ...state,
        listOfReasons: payload,
      };

    case TEACHER_SCHEDULE_ACTIONS.SET_SCHEDULE:
      return {
        ...state,
        schedule: payload,
      };

    case TEACHER_SCHEDULE_ACTIONS.EDIT_SCHEDULE:
      return {
        ...state,
        schedule: state.schedule.map(event => {
          if (event.lessonId === payload.lessonId) {
            return payload;
          }

          return event;
        }),
      };

    case TEACHER_SCHEDULE_ACTIONS.SET_ACTIVE_EVENT:
      return {
        ...state,
        activeEvent: payload,
      };

    default:
      return state;
  }
};
