import {
  BASE_FILE_SERVICE_URL,
  BASE_PAYMENT_SERVICE_URL,
  BASE_SCHOOL_SERVICE_URL
} from '../constants/api'
import axios from 'axios'

export const UPLOAD_TYPES = {
  LOGO: 'LOGO',
  QR: 'QR'
}

export const getBillSettingsBySchoolId = async (schoolId) => {
  const url = `${BASE_PAYMENT_SERVICE_URL}/api/bill-settings/${schoolId}`
  const response = await axios.get(url)
  return response && response.data && response.data.data
    ? response.data.data
    : {
      advertisingText: '',
      precision: '',
      roundingMode: null,
      minSumPrice: null,
      id: null
    }
}

export const updateBillSettingsBySchoolId = async (
  schoolId, billSettingsId,
  { description, format, precision, minSumPrice, prefix }) => {
  const url = `${BASE_PAYMENT_SERVICE_URL}/api/bill-settings/${schoolId}`
  const response = await axios.put(url, {
    schoolId,
    advertisingText: description,
    precision: precision,
    roundingMode: format,
    minSumPrice,
    prefix
  })
  return response && response.data && response.data.data
    ? response.data.data
    : {}
}

export const createBillSettingsToSchool = async (
  schoolId, { description, format, precision, prefix, minSumPrice }) => {
  const url = `${BASE_PAYMENT_SERVICE_URL}/api/bill-settings`

  const response = await axios.post(url, {
    schoolId,
    advertisingText: description,
    precision: precision,
    roundingMode: format,
    prefix,
    minSumPrice
  })
  return response && response.data && response.data.data
    ? response.data.data
    : {}
}
export const shareSettingsToOtherSchools = async (schoolId, schoolsList) => {
  const url = `${BASE_PAYMENT_SERVICE_URL}/api/bill-settings/to-schools`
  const response = await axios.put(url,
    { billSettingsId: schoolId, schoolIds: schoolsList })
  return response && response.data && response.data.data
    ? response.data.data
    : {}
}
export const getAllSchools = async () => {
  const url = `${BASE_SCHOOL_SERVICE_URL}/schools`
  const response = await axios.get(url)
  return response && response.data && response.data.data
    ? response.data.data
    : []
}
export const getBillSettingsLogo = async (schoolId) => {
  const url = `${BASE_FILE_SERVICE_URL}/api/bill-files?billFileType=${UPLOAD_TYPES.LOGO}&origin_id=${schoolId}`
  const response = await axios.get(url, {
    responseType: 'blob'
  })
  return response && response.data ? URL.createObjectURL(response.data) : null
}
export const getBillSettingsQRCode = async (schoolId) => {
  const url = `${BASE_FILE_SERVICE_URL}/api/bill-files?billFileType=${UPLOAD_TYPES.QR}&origin_id=${schoolId}`
  const response = await axios.get(url, {
    responseType: 'blob'
  })
  return response && response.data
    ? URL.createObjectURL(response.data)
    : undefined
}
export const createBillSettingsLogo = async (schoolId, imageUrl) => {
  const url = `${BASE_FILE_SERVICE_URL}/api/bill-files/upload`
  console.log({ imageUrl })
  const image = await fetch(imageUrl)
  const blob = await image.blob()
  const bodyFormData = new FormData()
  bodyFormData.append('billFileType', UPLOAD_TYPES.LOGO)
  bodyFormData.append('file', blob)
  bodyFormData.append('origin_id', schoolId)

  const response = await axios({
    url,
    method: 'post',
    data: bodyFormData,
    headers: { 'Content-Type': 'multipart/form-data' }
  })

  console.log('createLogo', { blob, bodyFormData, imageUrl, response })
}
export const createBillSettingsQRCode = async (schoolId, imageUrl) => {
  const url = `${BASE_FILE_SERVICE_URL}/api/bill-files/upload`
  const image = await fetch(imageUrl)
  const blob = await image.blob()
  const bodyFormData = new FormData()
  bodyFormData.append('billFileType', UPLOAD_TYPES.QR)
  bodyFormData.append('file', blob)
  bodyFormData.append('origin_id', schoolId)

  const response = await axios({
    url,
    method: 'post',
    data: bodyFormData,
    headers: { 'Content-Type': 'multipart/form-data' }
  })

  console.log('createQR', { blob, bodyFormData, imageUrl, response })
}
export const updateBillFiles = async (fileId, imageUrl) => {
  const url = `${BASE_FILE_SERVICE_URL}/api/bill-files/update`
  const image = await fetch(imageUrl)
  const blob = await image.blob()
  // console.log({blob});
  const bodyFormData = new FormData()
  // bodyFormData.append('billFileType', UPLOAD_TYPES.LOGO);
  bodyFormData.append('file', blob)
  bodyFormData.append('file_id', fileId)

  // console.log({blob, bodyFormData, imageUrl});
  const response = await axios({
    url,
    method: 'put',
    data: bodyFormData,
    headers: { 'Content-Type': 'multipart/form-data' }
  })

  console.log('updateLogo', { blob, bodyFormData, imageUrl, response })
  // console.log({response});
}
// export const updateBillSettingsQRCode = async (schoolId, imageUrl) => {
//   // todo
//   const url = `${BASE_FILE_SERVICE_URL}/api/bill-files/upload`;
//   const image = await fetch(imageUrl);
//   const blob = await image.blob();
//   // console.log({blob});
//   const bodyFormData = new FormData();
//   bodyFormData.append('billFileType', UPLOAD_TYPES.QR);
//   bodyFormData.append('file', blob);
//   bodyFormData.append('origin_id', schoolId);
//
//   const response = await axios({
//     url,
//     method: 'put',
//     data: bodyFormData,
//     headers: { 'Content-Type': 'multipart/form-data' },
//   });
//
//   console.log('updateQR', {blob, bodyFormData, imageUrl, response});
// };
