import {t} from 'i18n-js';

export const COMMUNICATION_CHANNELS = {
  SMS: 'SMS',
  VIBER: 'VIBER',
  VISIT: 'VISIT',
  EMAIL: 'EMAIL',
  CALL: 'CALL'
};
export const COMMUNICATION_CHANNELS_NAMES = {
  [COMMUNICATION_CHANNELS.SMS]: t('CommunicationChannels.sms'),
  [COMMUNICATION_CHANNELS.VIBER]: t('CommunicationChannels.viber'),
  [COMMUNICATION_CHANNELS.VISIT]: t('CommunicationChannels.visit'),
  [COMMUNICATION_CHANNELS.EMAIL]: t('CommunicationChannels.email'),
  [COMMUNICATION_CHANNELS.CALL]: t('CommunicationChannels.call'),
};
