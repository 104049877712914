import { SELECTED_PERSON_ACTIONS } from '../actions/types'

const initialState = {
  events: {
    events: [],
    current: 1,
    total: 0,
    pageSize: 5,
    selectedTypes: [],
    dateRange: [],
    filters: {}
  }
}

export const selectedPersonReducer = (state = initialState, action) => {
  switch (action.type) {
    case SELECTED_PERSON_ACTIONS.SET_EVENTS:
      return { ...state, events: { ...state.events, events: action.payload } }

    case SELECTED_PERSON_ACTIONS.SET_EVENTS_TOTAL_AMOUNT:
      return { ...state, events: { ...state.events, total: action.payload } }

    case SELECTED_PERSON_ACTIONS.SET_EVENTS_CURRENT_PAGE:
      return { ...state, events: { ...state.events, current: action.payload } }

    case SELECTED_PERSON_ACTIONS.SET_EVENTS_PAGE_SIZE:
      return { ...state, events: { ...state.events, pageSize: action.payload } }

    case SELECTED_PERSON_ACTIONS.SET_SELECTED_TYPES:
      return { ...state, events: { ...state.events, selectedTypes: action.payload } }

    case SELECTED_PERSON_ACTIONS.SET_DATE_RANGE:
      return { ...state, events: { ...state.events, dateRange: action.payload } }

    case SELECTED_PERSON_ACTIONS.SET_EVENTS_FILTERS:
      return { ...state, events: { ...state.events, filters: action.payload } }

    default:
      return state
  }
}
