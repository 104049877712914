import * as api from '../../utils/billSettings'
import { notification } from 'antd'
import i18n, { t } from 'i18n-js'
import {
  CheckCircleOutlined,
  ExclamationCircleOutlined
} from '@ant-design/icons'
import MyButton from '../../components/ui/MyButton/MyButton'
import React from 'react'

export const BILL_SETTINGS_ACTIONS = {
  GET_CURRENT_SCHOOL_BILL_SETTINGS: 'GET_CURRENT_SCHOOL_BILL_SETTINGS',
  CREATE_SCHOOL_BILL_SETTINGS: 'CREATE_SCHOOL_BILL_SETTINGS',
  UPDATE_CURRENT_SCHOOL_BILL_SETTINGS: 'UPDATE_CURRENT_SCHOOL_BILL_SETTINGS',
  SHARE_BILL_SETTINGS_TO_OTHER_SCHOOLS: 'SHARE_BILL_SETTINGS_TO_OTHER_SCHOOLS',
  GET_ALL_SCHOOLS: 'GET_ALL_SCHOOLS',
  SET_SCHOOL_LOGO: 'SET_SCHOOL_LOGO',
  SET_SCHOOL_QR: 'SET_SCHOOL_QR'
}

export const getBillSettingsBySchoolId = (schoolId) => async (dispatch) => {
  try {
    const response = await api.getBillSettingsBySchoolId(schoolId)
    console.log({ response })
    dispatch({
      type: BILL_SETTINGS_ACTIONS.GET_CURRENT_SCHOOL_BILL_SETTINGS,
      payload: response
    })
  } catch (err) {
    console.error(`catch an error ${err}`)
  }
}

export const updateBillSettingsBySchoolId = (
  schoolId, billSettingsId, data) => async (dispatch) => {
  try {
    const response = await api.updateBillSettingsBySchoolId(schoolId,
      billSettingsId, data)

    dispatch({
      type: BILL_SETTINGS_ACTIONS.UPDATE_CURRENT_SCHOOL_BILL_SETTINGS,
      payload: response
    })
    notification.success({
      message: `${t('CheckSettings.successSaveSettings')}`,
      duration: 3,
      icon: <CheckCircleOutlined style={{ color: 'green' }} />
    })
  } catch (err) {
    console.error(`catch an error ${err}`)
    notification.error({
      message: `${t('CheckSettings.errorSaveSettings')}`,
      duration: 3,
      key: 'errBillSettings',
      icon: <ExclamationCircleOutlined style={{ color: 'red' }} />,
      btn: <MyButton
        onClick={() => notification.close('errBillSettings')}
        blueBackgroundColor
      >
        {i18n.t('general.button.close')}
      </MyButton>
    })
  }
}

export const createBillSettingsToSchool = (
  schoolId, data) => async (dispatch) => {
  try {
    const response = await api.createBillSettingsToSchool(schoolId, data)
    dispatch({
      type: BILL_SETTINGS_ACTIONS.CREATE_SCHOOL_BILL_SETTINGS,
      payload: response
    })
    notification.success({
      message: `${t('CheckSettings.successSaveSettings')}`,
      duration: 3,
      key: 'successBillSettings',
      icon: <CheckCircleOutlined style={{ color: 'green' }} />
    })
  } catch (err) {
    console.error(`catch an error ${err}`)
    notification.error({
      message: `${t('CheckSettings.errorSaveSettings')}`,
      duration: 3,
      key: 'errBillSettings',
      icon: <ExclamationCircleOutlined style={{ color: 'red' }} />,
      btn: <MyButton
        onClick={() => notification.close('errBillSettings')}
        blueBackgroundColor
      >{i18n.t('general.button.close')}
      </MyButton>
    })
  }
}

export const shareSettingsToOtherSchools = (
  schoolId, schoolsList = []) => async (dispatch) => {
  try {
    const response = await api.shareSettingsToOtherSchools(schoolId,
      schoolsList)

    dispatch({
      type: BILL_SETTINGS_ACTIONS.SHARE_BILL_SETTINGS_TO_OTHER_SCHOOLS,
      payload: response
    })
  } catch (err) {
    console.error(`catch an error ${err}`)
  }
}

export const getAllSchools = () => async (dispatch) => {
  try {
    const response = await api.getAllSchools()

    dispatch({
      type: BILL_SETTINGS_ACTIONS.GET_ALL_SCHOOLS,
      payload: response
    })
  } catch (err) {
    console.error(`catch an error ${err}`)
  }
}

function setLogo (dispatch, logo) {
  dispatch({
    type: BILL_SETTINGS_ACTIONS.SET_SCHOOL_LOGO,
    payload: logo
  })
}

function setQR (dispatch, qr) {
  dispatch({
    type: BILL_SETTINGS_ACTIONS.SET_SCHOOL_QR,
    payload: qr
  })
}

export const getBillSettingsLogo = (schoolId) => async (dispatch) => {
  try {
    const logo = await api.getBillSettingsLogo(schoolId)
    setLogo(dispatch, logo)
  } catch (err) {
    console.error(`catch an error ${err}`)
    setLogo(dispatch, null)
  }
}

export const getBillSettingsQRCode = (schoolId) => async (dispatch) => {
  try {
    const qr = await api.getBillSettingsQRCode(schoolId)
    setQR(dispatch, qr)
  } catch (err) {
    console.error(`catch an error ${err}`)
    setQR(dispatch, null)
  }
}

export const createBillSettingsLogo = (schoolId, image) => async (dispatch) => {
  try {
    console.log({ schoolId, image })
    await api.createBillSettingsLogo(schoolId, image)
    // setLogo(dispatch, logo);
    await getBillSettingsLogo(schoolId)(dispatch)
    notification.success({
      message: `${t('CheckSettings.successUploadLogo')}`,
      duration: 3,
      icon: <CheckCircleOutlined style={{ color: 'green' }} />
    })
  } catch (err) {
    console.error(`catch an error ${err}`)
  }
}

export const createBillSettingsQRCode = (
  schoolId, image) => async (dispatch) => {
  try {
    const qr = await api.createBillSettingsQRCode(schoolId, image)
    // setQR(dispatch, qr);
    await getBillSettingsQRCode(schoolId)(dispatch)
    notification.success({
      message: `${t('CheckSettings.successUploadQrCode')}`,
      duration: 3,
      icon: <CheckCircleOutlined style={{ color: 'green' }} />
    })
  } catch (err) {
    console.error(`catch an error ${err}`)
  }
}

// export const updateBillSettingsFile = (fileId, image) => async (dispatch) => {
//   try {
//     console.log({fileId, image});
//     const logo = await api.updateBillFiles(fileId, image);
//     setLogo(dispatch, logo);
//   } catch (err) {
//     console.error(`catch an error ${err}`);
//   }
// };

// export const updateBillSettingsQRCode = (fileId, image) => async (dispatch) => {
//   try {
//     const qr = await api.updateBillFiles(fileId, image);
//     setQR(dispatch, qr);
//   } catch (err) {
//     console.error(`catch an error ${err}`);
//   }
// };
