import {BILL_SETTINGS_ACTIONS} from '../actions/billSettings';

const initialState = {
  advertisingText: null,
  id: null,
  precision: null,
  roundingMode: null,
  logo: null,
  QRCode: null,
  minSumPrice: null,
  schools: [],
};

export const billSettingsReducer = (state = initialState, action) => {
  switch (action.type) {
    case BILL_SETTINGS_ACTIONS.GET_CURRENT_SCHOOL_BILL_SETTINGS:
      return {...state, ...action.payload};
    case BILL_SETTINGS_ACTIONS.UPDATE_CURRENT_SCHOOL_BILL_SETTINGS:
      return {...state, ...action.payload};
    case BILL_SETTINGS_ACTIONS.CREATE_SCHOOL_BILL_SETTINGS:
      return {...state, ...action.payload};
    case BILL_SETTINGS_ACTIONS.SHARE_BILL_SETTINGS_TO_OTHER_SCHOOLS:
      return {...state, ...action.payload};
    case BILL_SETTINGS_ACTIONS.GET_ALL_SCHOOLS:
      return {...state, schools: action.payload};
    case BILL_SETTINGS_ACTIONS.SET_SCHOOL_LOGO:
      return {...state, logo: action.payload};
    case BILL_SETTINGS_ACTIONS.SET_SCHOOL_QR:
      return {...state, QRCode: action.payload};
    default:
      return state;
  }
};