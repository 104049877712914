import { ACTION, FAMILY_ACTION, FORM_ACTIONS } from './types'
import {
  addApplicationToFamily,
  deleteLeadApplication,
  deleteLeeds,
  editLeadApplication,
  editLeeds,
  getLeadsData,
  getLeedAdditionalFields,
  getSimilarLeadsAndClients,
  submitNewLeedData,
  submitNewLeedFieldData
} from '../../utils/leeds'
import { SUBSTATUS } from '../../constants/text'
import { history } from '../../history'
import { updateFile, uploadFile } from '../../utils/albums'
import { getEventTypes } from '../../utils/api'
import { getFamilyById } from '../../utils/family'
import { getFamily } from './family'
import {
  getSchoolGroupByEmployeeId,
  getSchoolGroupIdBySchoolId
} from '../../utils/schools'
import { setLeedsTableLoading, setTotalRecords } from './lead'
import { notification } from 'antd'
import i18n from 'i18n-js'

/* --- sign in actions START --- */

export const showSigninError = (showError) => ({
  type: ACTION.SHOW_SIGNIN_ERROR,
  showError
})

export const setSigninErrorText = (text) => ({
  type: ACTION.SET_SIGNIN_ERROR_TEXT,
  text
})

export const showResetPswdAlert = (showAlert) => ({
  type: ACTION.SHOW_RESET_PSWD_ALERT,
  showAlert
})

export const setResetPswdAlertText = (text) => ({
  type: ACTION.SET_RESET_PSWD_ALERT_TEXT,
  text
})

/* --- sign in actions END --- */

/* --- menu actions START --- */

export const setLeftMenuHovered = (hovered) => ({
  type: ACTION.SET_LEFT_MENU_HOVERED,
  hovered
})

export const togglePagesMenuExpanded = () => ({
  type: ACTION.TOGGLE_PAGES_MENU_EXPANDED
})

export const setPagesMenu = (pagesMenu) => ({
  type: ACTION.SET_PAGES_MENU,
  pagesMenu
})

export const setPagesMenuDownloaded = (downloaded) => ({
  type: ACTION.SET_PAGES_MENU_DOWNLOADED,
  downloaded
})

/* --- menu actions END --- */

/* --- leads actions START --- */

export const setLeedsStatusFilter = (value) => ({
  type: ACTION.SET_LEEDS_STATUS_FILTER,
  value
})

export const setInProgressLeedsSubStatusFilter = (value) => ({
  type: ACTION.SET_IN_PROGRESS_LEEDS_SUBSTATUS_FILTER,
  value
})

export const setCompletedLeedsSubStatusFilter = (value) => ({
  type: ACTION.SET_COMPLETED_LEEDS_SUBSTATUS_FILTER,
  value
})

export const setLeedsPaginationValue = (value) => ({
  type: ACTION.SET_LEEDS_PAGINATION_VALUE,
  value
})

export const setInProgressLeedsPopupVisible = (value) => ({
  type: ACTION.SET_IN_PROGRESS_LEEDS_POPUP_VISIBLE,
  value
})

export const setInProgressLeedSubStatusValue = (value) => ({
  type: ACTION.SET_IN_PROGRESS_LEED_SUBSTATUS_VALUE,
  value
})

export const setCompletedLeedsPopupVisible = (value) => ({
  type: ACTION.SET_COMPLETED_LEEDS_POPUP_VISIBLE,
  value
})

export const setCompletedLeedSubStatusValue = (value) => ({
  type: ACTION.SET_COMPLETED_LEED_SUBSTATUS_VALUE,
  value
})

export const setSelectedLeedsIds = (ids) => ({
  type: ACTION.SET_SELECTED_LEEDS_IDS,
  ids
})

export const setLeedsDataIsLoading = (isLoading) => ({
  type: ACTION.SET_LEEDS_DATA_IS_LOADING,
  isLoading
})

export const setLeedsData = (leedsData) => ({
  type: ACTION.SET_LEEDS_DATA,
  leedsData
})

export const loadLeadsData = (options) => async (dispatch, getState) => {
  try {
    dispatch(setLeedsTableLoading(true))
    const { schoolGroup } = getState().auth
    if (!options.schoolIdList?.length && schoolGroup) {
      options.schoolIdList = schoolGroup.map(item => item.id)
    }
    const response = await getLeadsData(options)

    if (
      response.data?.statusCode?.code === 0 ||
      (response.data?.statusCode?.code === 1 &&
        response.data?.statusCode?.description === 'No leads are found.')
    ) {
      const data = response.data.data

      dispatch(setLeedsData(data.leadDTOPage.content))
      dispatch(setTotalRecords(data.leadDTOPage.totalElements))
      dispatch(
        setLeedsCount({
          newLeadsCount: data.newLeadsCount,
          leadsInWorkCount: data.leadsInWorkCount,
          finishedLeadsCount: data.finishedLeadsCount
        })
      )
      dispatch(setLeedPossibleSchools(data.schoolNameTimezoneDTOS))
    }
  } catch (err) {
    console.error(err.message)
  } finally {
    dispatch(setLeedsTableLoading(false))
  }
}

export const setOptionsToLoadLeads = (options) => ({
  type: ACTION.SET_OPTIONS_TO_LOAD_LEADS,
  options
})

export const setLoadLeedSDataError = (isError) => ({
  type: ACTION.SET_LOAD_LEEDS_DATA_ERROR,
  isError
})

export const setLeedsTableLastActivitySort = (value) => ({
  type: ACTION.SET_LEEDS_TABLE_LAST_ACTIVITY_SORT,
  value
})

export const setLeedsTableRegRangeDateFilter = (rangeDate) => ({
  type: ACTION.SET_LEEDS_TABLE_REG_RANGE_DATE_FILTER,
  payload: rangeDate
})

export const setLeedsTablePhoneFilter = (phone) => ({
  type: ACTION.SET_LEEDS_TABLE_PHONE_FILTER,
  phone
})

export const setLeedsTableCustomerTypeFilter = (customerType) => ({
  type: ACTION.SET_LEEDS_TABLE_CUSTOMER_TYPE_FILTER,
  customerType
})

export const setLeedsTableSchoolsFilter = (schools) => ({
  type: ACTION.SET_LEEDS_TABLE_SCHOOLS_FILTER,
  schools
})

export const setLeedsTableInBlackListFilter = (inBlackList) => ({
  type: ACTION.SET_LEEDS_TABLE_IN_BLACK_LIST_FILTER,
  inBlackList
})

export const setLeedsTableAdditionalFilters = (filters) => ({
  type: ACTION.SET_LEEDS_TABLE_ADDITIONAL_FILTER,
  filters
})

export const setLeedsGroupActionStatus = (value) => ({
  type: ACTION.SET_LEEDS_GROUP_ACTION_STATUS,
  value
})

export const setLeedsGroupActionInProgressSubstatus = (value) => ({
  type: ACTION.SET_LEEDS_GROUP_ACTION_IN_PROGRESS_SUBSTATUS,
  value
})

export const setLeedsGroupActionCompletedSubstatus = (value) => ({
  type: ACTION.SET_LEEDS_GROUP_ACTION_COMPLETED_SUBSTATUS,
  value
})

export const setLeedsCount = (leedsCount) => ({
  type: ACTION.SET_LEEDS_COUNT,
  leedsCount
})

export const setLeedToUpdateStatusSubStatus = (leed) => ({
  type: ACTION.SET_LEED_TO_UPDATE_STATUS_SUBSTATUS,
  leed
})

export const setLeedNewStatusSubStatus = (
  leed,
  newStatus,
  newSubStatus,
  fromLeedDetailsPage,
  cb = () => {}
) => async (dispatch, getState) => {
  try {
    dispatch(setLeedNewStatusSubStatusIsSubmitting(true)) // disable button
    // eslint-disable-next-line
    await editLeadApplication(leed.applicationId, {
      status: newSubStatus, // subStatus is called "status" on this backend api :)
      studentId: leed.studentId,
      source: leed.source,
      comment: leed.comment,
      author: leed.author
    })
    dispatch(setInProgressLeedsPopupVisible(false)) // close popup
    dispatch(setCompletedLeedsPopupVisible(false)) // close popup
    dispatch(setInProgressLeedSubStatusValue(SUBSTATUS.DISABLED))
    dispatch(setCompletedLeedSubStatusValue(SUBSTATUS.DISABLED))
    cb() // reset communication type and message
    // update leedsData to reflect status/substatus change
    if (fromLeedDetailsPage) {
      if (newSubStatus === SUBSTATUS.PAID_SUBSCRIPTION) {
        history.push(`/dashboard/clients/client/${leed.id}`)
      } else {
        dispatch(getFamily(leed.id))
        // dispatch(getPersonEvents(leed.id))
      }
    } else {
      const { optionsToLoadLeads } = getState().leeds
      dispatch(loadLeadsData(optionsToLoadLeads))
    }
  } catch (e) {
    console.log(e)
  } finally {
    dispatch(setLeedNewStatusSubStatusIsSubmitting(false)) // enable button after any request result
  }
}

export const setLeedNewStatusSubStatusIsSubmitting = (isSubmitting) => ({
  type: ACTION.SET_LEED_NEW_STATUS_SUBSTATUS_IS_SUBMITTING,
  isSubmitting
})

export const deleteSelectedLeeds = (ids) => async (dispatch, getState) => {
  try {
    dispatch(setDeleteLeedsIsSubmitting(true)) // disable button
    await deleteLeeds(ids)
    dispatch(setSelectedLeedsIds([]))
    dispatch(setSelectedLeeds([]))
    // update leedsData to reflect status/substatus change
    const { optionsToLoadLeads } = getState().leeds
    dispatch(loadLeadsData(optionsToLoadLeads))
  } catch (e) {
    console.log(e)
  } finally {
    dispatch(setDeleteLeedsIsSubmitting(false)) // enable button after any request result
  }
}

export const removeLeadApplication = (applicationId) => async (
  dispatch, getState) => {
  try {
    dispatch(setDeleteLeedsIsSubmitting(true))
    const response = await deleteLeadApplication(applicationId)

    if (response.data?.statusCode?.code === 0) {
      const { optionsToLoadLeads } = getState().leeds
      dispatch(loadLeadsData(optionsToLoadLeads))
    }
  } catch (err) {
    console.error(err.message)
  } finally {
    dispatch(setDeleteLeedsIsSubmitting(false))
  }
}

export const createApplicationForFamily = (data, closeForm) => async (
  dispatch, getState) => {
  dispatch(setSpinner(true))
  try {
    const result = await addApplicationToFamily(data)
    if (result?.data?.statusCode?.code === 0) {
      const { optionsToLoadLeads } = getState().leeds
      dispatch(loadLeadsData(optionsToLoadLeads))
      closeForm()
    }
  } catch (err) {
    console.log('create application for family', err)
  } finally {
    dispatch(setSpinner(false))
  }
}

export const setDeleteLeedsIsSubmitting = (isSubmitting) => ({
  type: ACTION.SET_DELETE_LEEDS_IS_SUBMITTING,
  isSubmitting
})

export const setSelectedLeeds = (leeds) => ({
  type: ACTION.SET_SELECTED_LEEDS,
  leeds
})

export const setSelectedLeedsNewStatusSubStatus = (
  leeds,
  newStatus,
  newSubStatus,
  cb = () => {},
  setLoading,
  setTotalRecords
) => async (dispatch, getState) => {
  try {
    dispatch(setSelectedLeedsStatusSubStatusIsSubmitting(true)) // disable button
    // eslint-disable-next-line
    const response = await editLeeds(
      leeds.map((leed) => ({
        ...leed,
        status: newStatus,
        subStatus: newSubStatus
      }))
    )
    dispatch(setSelectedLeedsIds([])) // reset selected leeds ids
    dispatch(setSelectedLeeds([])) // reset selected leeds
    dispatch(setLeedsGroupActionStatus(SUBSTATUS.DISABLED)) // reset group leeds new status
    dispatch(setLeedsGroupActionInProgressSubstatus(SUBSTATUS.DISABLED)) // reset group leeds new in_work substatus
    dispatch(setLeedsGroupActionCompletedSubstatus(SUBSTATUS.DISABLED)) // reset group leeds new finished substatus
    cb() // reset comment and close popup
    // update leedsData to reflect status/substatus change
    const { optionsToLoadLeads } = getState().leeds
    dispatch(loadLeadsData(optionsToLoadLeads))
  } catch (e) {
    console.log(e)
  } finally {
    dispatch(setSelectedLeedsStatusSubStatusIsSubmitting(false)) // enable button
  }
}

export const setSelectedLeedsStatusSubStatusIsSubmitting = (isSubmitting) => ({
  type: ACTION.SET_SELECTED_LEEDS_NEW_STATUS_SUBSTATUS_IS_SUBMITTING,
  isSubmitting
})

export const setConfirmDeleteLeedsPopupIsVisible = (isVisible) => ({
  type: ACTION.SET_CONFIRM_DELETE_LEEDS_POPUP_IS_VISIBLE,
  isVisible
})

export const setSelectedLeedSpinner = (isVisible) => ({
  type: ACTION.SET_SELECTED_LEED_SPINNER,
  isVisible
})
/* --- leads actions END --- */

/* --- teacher profile actions START ---- */
export const setProfileMainData = (data) => ({
  type: ACTION.SET_PROFILE_MAIN_DATA,
  data
})

export const setProfileCurrentRole = data => ({
  type: ACTION.SET_PROFILE_CURRENT_ROLE,
  data
})

export const setProfileDetailedData = (data) => ({
  type: ACTION.SET_PROFILE_DETAILED_DATA,
  data
})

export const setEmploymentData = (data) => ({
  type: ACTION.SET_EMPLOYMENT_DATA,
  data
})

export const setModalIsOpen = (flag) => ({
  type: ACTION.SET_MODAL_OPEN,
  payload: flag
})

export const setFinSettings = (data) => ({
  type: ACTION.SET_FINANCIAL_SETTINGS,
  data
})

export const setMonitoring = (data) => ({
  type: ACTION.SET_MONITORING,
  data
})

export const setMentorData = (data) => ({
  type: ACTION.SET_MENTOR_DATA,
  data
})

export const setChosenCourse = (data) => ({
  type: ACTION.SET_CHOSEN_COURSE,
  data
})

export const setMotivation = (data) => ({
  type: ACTION.SET_MOTIVATION,
  data
})

export const setSummaryTableCourse = (data) => ({
  type: ACTION.SET_SUMMARY_TABLE_COURSE,
  data
})

/* --- teacher profile actions END ---- */

/* --- selected Lead actions START ---- */

export const setSelectedLeedData = (data) => ({
  type: ACTION.SET_SELECTED_LEED_DATA,
  data
})

export const setSelectedLeedParents = (data) => ({
  type: ACTION.SET_SELECTED_LEED_PARENTS,
  data
})

export const setSelectedLeedChildren = (data) => ({
  type: ACTION.SET_SELECTED_LEED_CHILDREN,
  data
})

export const setLeedCompletedEvents = (data) => ({
  type: ACTION.SET_LEED_COMPLETED_EVENTS,
  data
})

export const setLeedPlannedEvents = (data) => ({
  type: ACTION.SET_LEED_PLANNED_EVENTS,
  data
})

export const setAvatar = (avatar) => ({
  type: ACTION.SET_AVATAR,
  payload: avatar
})

/* --- selected Lead actions END ---- */

/* --- new Lead actions START ---- */

export const setNewLeedPopupVisible = (isVisible) => ({
  type: ACTION.SET_NEW_LEED_POPUP_VISIBLE,
  isVisible
})

export const setNewLeedPopupDefaultValues = (data) => ({
  type: ACTION.SET_NEW_LEED_POPUP_DEFAULT_VALUES,
  payload: data
})

export const setNewLeedFieldDrawerIsVisible = (isVisible) => ({
  type: ACTION.SET_NEW_LEED_FIELD_DRAWER_IS_VISIBLE,
  isVisible
})

export const createNewLeedField = (fieldData, form) => async (dispatch) => {
  try {
    dispatch(setNewLeedFieldCreateError(false))
    dispatch(setNewLeedFieldIsSubmitting(true))
    await submitNewLeedFieldData(fieldData)
    dispatch(setNewLeedFieldDrawerIsVisible(false))
    form.resetFields()
    dispatch(loadLeedAdditionalFields())
  } catch (e) {
    dispatch(setNewLeedFieldCreateError(true))
  } finally {
    dispatch(setNewLeedFieldIsSubmitting(false))
  }
}

export const setNewLeedFieldCreateError = (isError) => ({
  type: ACTION.SET_NEW_LEED_FIELD_CREATE_ERROR,
  isError
})

export const setNewLeedFieldIsSubmitting = (isSubmitting) => ({
  type: ACTION.SET_NEW_LEED_FIELD_IS_SUBMITTING,
  isSubmitting
})

export const createNewLeed = (leedData) => async (dispatch) => {
  try {
    dispatch(setNewLeedDataSubmitError(false))
    dispatch(setNewLeedDataIsSubmitting(true))
    await submitNewLeedData(leedData)
    dispatch(setNewLeedPopupVisible(false))
  } catch (e) {
    dispatch(setNewLeedDataSubmitError(true))
  } finally {
    dispatch(setNewLeedDataIsSubmitting(false))
  }
}

export const setNewLeedDataIsSubmitting = (isSubmitting) => ({
  type: ACTION.SET_NEW_LEED_DATA_IS_SUBMITTING,
  isSubmitting
})

export const setNewLeedDataSubmitError = (isError) => ({
  type: ACTION.SET_NEW_LEED_DATA_SUBMIT_ERROR,
  isError
})

export const loadSimilarLeadsAndClients = (searchParams) => async (dispatch) => {
  dispatch(setSpinner(true))
  try {
    const response = await getSimilarLeadsAndClients(searchParams)
    if (response?.data?.statusCode?.code === 0) {
      dispatch(setSimilarLeadsAndClients(response.data.data))
    }
  } catch (err) {
    console.error(err.message)
  } finally {
    dispatch(setSpinner(false))
  }
}

export const setSimilarLeadsAndClients = (leadsAndClients) => ({
  type: ACTION.SET_SIMILAR_LEADS_AND_CLIENTS,
  leadsAndClients
})

export const getFamiliesByIds = async (ids) => {
  try {
    const responses = await Promise.all(ids.map((id) => getFamilyById(id)))
    return responses.map((response) => {
      return response.data.data
    })
  } catch (err) {
    console.error(err.message)
  }
}

/* --- new Lead actions END ---- */

export const setLeedsTableSettingsDrawerIsVisible = (isVisible) => ({
  type: ACTION.SET_LEEDS_TABLE_SETTINGS_DRAWER_IS_VISIBLE,
  isVisible
})

export const loadLeedAdditionalFields = () => async (dispatch) => {
  try {
    const additionalFields = await getLeedAdditionalFields()
    dispatch(setLeedAdditionalFields(additionalFields))
    dispatch(setLeedAdditionalFieldsDownloaded(true))
  } catch (e) {
    console.log(e)
  }
}

export const setLeedAdditionalFields = (additionalFields) => ({
  type: ACTION.SET_LEED_ADDITIONAL_FIELDS,
  additionalFields
})

export const setLeedAdditionalFieldsDownloaded = (isDownloaded) => ({
  type: ACTION.SET_LEED_ADDITIONAL_FIELDS_DOWNLOADED,
  isDownloaded
})

export const setLeedPossibleSchools = (schools) => ({
  type: ACTION.SET_LEED_POSSIBLE_SCHOOLS,
  schools
})

/* --- spinner actions START ---- */
export const setSpinner = (flag) => ({
  type: ACTION.SET_SPINNER_VISIBLE,
  payload: flag
})
/* --- spinner actions END ---- */

/* --- modal actions START ---- */
export const setFamilyConfirm = (flag) => ({
  type: FAMILY_ACTION.SET_CONFIRM_FAMILY_ACTION,
  payload: flag
})

export const setNotification = (flag) => ({
  type: ACTION.SET_NOTIFICATION,
  payload: flag
})
/* --- modal actions END ---- */

export const setFormTouched = (touched) => ({
  type: FORM_ACTIONS.SET_TOUCHED,
  payload: touched
})

export const setFormConfirmation = (flag) => ({
  type: FORM_ACTIONS.SET_CONFIRMATION,
  payload: flag
})

export const setSubmitting = (flag) => ({
  type: FORM_ACTIONS.SET_SUBMITTING,
  payload: flag
})

export const uploadFileToServer = (
  file, origin, originId, callback = () => {}) => async (dispatch) => {
  dispatch(setSpinner(true))
  const formData = new FormData()

  formData.append('file', file, file.name)
  formData.append('origin', origin)
  formData.append('origin_id', originId)
  try {
    const result = await uploadFile(formData)
    dispatch(setSpinner(false))
    if (result.data && result.data.statusCode && result.data.statusCode.code ===
      0) {
      await callback(result.data.data)
    }
  } catch (err) {
    dispatch(setSpinner(false))
    notification.error(
      {
        message: i18n.t('AvatarEditor.loadingError'),
        duration: 3
      })
    console.log('upload file error happened', err)
  }
}

export const updateAvatar = (
  file, origin, originId, fileId, callback = () => {}) => (dispatch) => {
  dispatch(setSpinner(true))
  const formData = new FormData()

  formData.append('file', file, file.name)
  formData.append('file_id', fileId)
  formData.append('origin', origin)
  formData.append('origin_id', originId)

  updateFile(formData).then((result) => {
    dispatch(setSpinner(false))
    if (result.data && result.data.statusCode && result.data.statusCode.code ===
      0) {
      callback()
    }
  }).catch((err) => {
    dispatch(setSpinner(false))
    console.log('upload file error happened', err)
  })
}

export const setEventTypes = (types) => ({
  type: ACTION.SET_EVENT_TYPES,
  payload: types
})

export const getAllEventTypes = () => (dispatch) => {
  getEventTypes().then((result) => {
    if (result.data && result.data.statusCode && result.data.statusCode.code ===
      0) {
      const types = result.data.data.reduce(
        (obj, item) => ({
          ...obj,
          [item]: item
        }), {})
      dispatch(setEventTypes(types))
    }
  }).catch((err) => {
    console.log('get event types error happened', err)
  })
}

export const setCurrentLanguage = (lang) => ({
  type: ACTION.SET_CURRENT_LANGUAGE,
  payload: lang
})

export const setSchoolGroupsTree = data => ({
  type: ACTION.SET_SCHOOL_GROUPS_TREE,
  payload: data
})

export const setCurrentSchoolGroup = data => ({
  type: ACTION.SET_CURRENT_SCHOOL_GROUP,
  payload: data
})

export const setCurrentSchoolGroupTree = data => ({
  type: ACTION.SET_CURRENT_SCHOOL_GROUP_TREE,
  payload: data
})

export const extractCurrentGroupFromTree = () => (dispatch, getState) => {
  const tree = getState().auth.schoolsTree
  const currentSchoolId = getState().auth.currentSchoolId
  if (currentSchoolId && Array.isArray(tree)) {
    const country = tree.find(item => item.schoolGroups?.find(
      group => group.schools?.find(school => school?.id === currentSchoolId)))
    const groupTree = country.schoolGroups.filter(
      group => group.schools?.find(school => school?.id === currentSchoolId))
      .map(item => ({
        value: item.id,
        key: item.name,
        title: item.name,
        children: item.schools?.map(school => ({
          key: school.id,
          value: school.id,
          title: school.name
        }))
      }))
    dispatch(setCurrentSchoolGroupTree(groupTree))
  }
}

export const getSchoolGroupsTree = (employeeId, role) => dispatch => {
  getSchoolGroupByEmployeeId(employeeId, role).then(result => {
    if (result.data?.statusCode?.code === 0 && result.data?.data) {
      dispatch(setSchoolGroupsTree(result.data.data))
    }
  }).catch(err => {
    console.log('get school group tree error', err)
  })
}

export const getSchoolGroupDataBySchool = schoolId => dispatch => {
  getSchoolGroupIdBySchoolId(schoolId).then(result => {
    if (result.data?.statusCode?.code === 0 && result.data?.data) {
      dispatch(setCurrentSchoolGroup(result.data.data))
    }
  }).catch(err => {
    console.log(err)
  })
}
