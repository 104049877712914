import { CUSTOM_FIELDS_ACTIONS } from '../actions/types';

const initialState = {
  fieldsState: []
};

export const customFieldsReducer = (state = initialState, action) => {
  switch (action.type) {
    case CUSTOM_FIELDS_ACTIONS.SET_CUSTOM_FIELDS_STATE:
      return {...state, fieldsState: action.payload};
      
    default:
      return state;
  }
};