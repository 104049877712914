import { ALBUMS_ACTION } from '../actions/types';

const initialState = {
  albums: [],
  total: 0,
  selected: [],
  startDate: null,
  endDate: null,
  courses: [],
  groups: [],
  teachers: [],
  currentImage: {},
  currentAlbum: null,
  cropperVisible: false,
  galleryVisible: false,
  confirmVisible: false,
  albumsFilters: {},
  pagination: {
    current: 1,
    pageSize: 10,
    sort: 'desc'
  },
  selectedPhotosId: [],
  isImageUpdated: false,
  activeIndex: 0,
  files: {
    files: [],
    total: 0,
  },
  spinner: false,
};

export const albumsReducer = (state = initialState, action) => {
  switch (action.type) {
    case ALBUMS_ACTION.SET_ALBUMS:
      return {...state, albums: action.payload};

    case ALBUMS_ACTION.SET_ALBUMS_START_DATE:
      return {...state, startDate: action.payload};

    case ALBUMS_ACTION.SET_ALBUMS_END_DATE:
      return {...state, endDate: action.payload};

    case ALBUMS_ACTION.SET_SELECTED_ALBUMS:
      return {...state, selected: action.payload};

    case ALBUMS_ACTION.SET_ALBUMS_COURSES:
      return {...state, courses: action.payload};

    case ALBUMS_ACTION.SET_ALBUMS_TEACHERS:
      return {...state, teachers: action.payload};

    case ALBUMS_ACTION.SET_CURRENT_IMAGE:
      return {...state, currentImage: action.payload};

    case ALBUMS_ACTION.SET_CROPPER_VISIBLE:
      return {...state, cropperVisible: action.payload};

    case ALBUMS_ACTION.SET_GALLERY_VISIBLE:
      return {...state, galleryVisible: action.payload};

    case ALBUMS_ACTION.SET_DELETE_CONFIRM_VISIBLE:
      return {...state, confirmVisible: action.payload};

    case ALBUMS_ACTION.SET_ALBUMS_PAGINATION:
      return {...state, pagination: action.payload};

    case ALBUMS_ACTION.SET_ALBUMS_FILTERS:
      return {...state, albumsFilters: action.payload};

    case ALBUMS_ACTION.SET_ALBUMS_GROUPS:
      return {...state, groups: action.payload};

    case ALBUMS_ACTION.SET_CURRENT_ALBUM:
      return {...state, currentAlbum: action.payload};

    case ALBUMS_ACTION.SET_TOTAL_ALBUMS_COUNT:
      return {...state, total: action.payload};
    
    case ALBUMS_ACTION.SET_SELECTED_PHOTOS:
      return {...state, selectedPhotosId: action.payload};
      
    case ALBUMS_ACTION.SET_IS_IMAGE_UPDATED:
      return {...state, isImageUpdated: action.payload};

    case ALBUMS_ACTION.SET_ACTIVE_GALLERY_INDEX:
      return {...state, activeIndex: action.payload};
      
    case ALBUMS_ACTION.SET_FILES:
      return {...state, files: { ...state.files, files: [...state.files.files, action.payload]} };
      
    case ALBUMS_ACTION.SET_TOTAL_FILES:
      return {...state, files: {...state.files, total: action.payload}};
      
    case ALBUMS_ACTION.SET_ALBUM_SPINNER:
      return {...state, spinner: action.payload};
    
    default:
      return state;
  }
};