import { ACTION, FAMILY_ACTION } from '../actions/types';

const initialState = {
  isOpen: false,
  confirm: false,
  notification: false,
};

export const modalReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION.SET_MODAL_OPEN:
      return {
        ...state,
        isOpen: action.payload,
      };

    case FAMILY_ACTION.SET_CONFIRM_FAMILY_ACTION:
      return {
        ...state,
        confirm: action.payload,
      };
      
    case ACTION.SET_NOTIFICATION: {
      return { ...state, notification: action.payload };
    }

    default: return state;
  }
};