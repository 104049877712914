import { PROMO_CODES_ACTIONS } from '../actions/types'

const initialState = {
  promoCodes: [],
  filters: {},
  currentPromoCode: {},
  pagination: {
    hideOnSinglePage: true,
    current: 1,
    pageSize: 10
  },
  sorter: {},
  products: [],
  services: []
}

export const promoCodesReducer = (state = initialState, action) => {
  switch (action.type) {
    case PROMO_CODES_ACTIONS.SET_PROMO_CODES:
      return { ...state, promoCodes: action.payload }

    case PROMO_CODES_ACTIONS.SET_CURRENT_PROMO_CODE:
      return { ...state, currentPromoCode: action.payload }

    case PROMO_CODES_ACTIONS.SET_FILTERS:
      return { ...state, filters: action.payload }

    case PROMO_CODES_ACTIONS.SET_SORTER:
      return { ...state, sorter: action.payload }

    case PROMO_CODES_ACTIONS.SET_PAGINATION:
      return { ...state, pagination: action.payload }

    case PROMO_CODES_ACTIONS.SET_PRODUCTS_FOR_FORM:
      return { ...state, products: action.payload }

    case PROMO_CODES_ACTIONS.SET_SERVICES_FOR_FORM:
      return { ...state, services: action.payload }

    default:
      return state
  }
}
