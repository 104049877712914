import React from 'react';
import { Button, Tooltip } from 'antd';
import classNames from 'classnames';
import styles from './MyButton.module.scss';

const MyButton = ({ disabled, onClick, blueBackgroundColor, tooltipText, btnClass, children, ...props }) => {
  return (
    <Tooltip title={tooltipText}>
      <span>
        <Button
          onClick={onClick}
          className={classNames(styles.buttonOrange, blueBackgroundColor && styles.buttonBlue, btnClass)}
          disabled={disabled}
          {...props}
        >
          {children}
        </Button>
      </span>
    </Tooltip>
  );
};

export default MyButton;