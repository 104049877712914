// Функция для тестов асинхронных данных от сервера пока endpoint еще делается
// принимает кол-во милисикунд и функцию-колбек
// то что вернется из функции кобека - будет результатом через ms
// Например :
//           const result = await delay(1000, () => [{'some test data' : 123}])
import moment from 'moment'
import { DATE_FORMATS, STATUSES } from '../../constants/text'
import i18n from 'i18n-js'
import { notification } from 'antd'

export const delay = (ms, fn) => {
  return new Promise(resolve => {
    setTimeout(() => {
      resolve(fn())
    }, ms)
  })
}

const SORT_FIELDS = {
  index: 'indexSort',
  minimumSumForPromoCode: 'minimumSumForPromoCodeSort',
  numberOfActivationsFact: 'numberOfActivationsFactSort',
  numberOfActivationsPlan: 'numberOfActivationsPlanSort',
  sumToStartRegularDiscount: 'sumToStartRegularDiscountSort',
  sumOfAllItemsByParameter: 'sumOfAllItemsByParameterSort'
}

const ORDERS = {
  ascend: 'ASC',
  descend: 'DESC'
}

export const getPromoCodesFiltersData = (filters, sort) => {
  const { field, order } = sort
  const {
    productGroup, validityPeriodFrom, productType,
    promoCodeType, status, product, activityName, code, schools,
    groupType
  } = filters

  const getStatus = () => status ? status[0] !== STATUSES.NON_ACTIVE : null

  const requestFilters = {
    productType: productType ? productType[0] : null,
    promoCodeType: promoCodeType ? promoCodeType[0] : null,
    groupName: productGroup ? productGroup[0] : null,
    activityName: activityName ? activityName[0] : null,
    validityPeriodFrom: validityPeriodFrom ? moment(validityPeriodFrom[0][0])
      .format(DATE_FORMATS.WESTERN) : null,
    validityPeriodTo: validityPeriodFrom ? moment(validityPeriodFrom[0][1])
      .format(DATE_FORMATS.WESTERN) : null,
    itemName: product ? product[0] : null,
    status: getStatus(),
    code: code ? code[0] : null,
    promoCodeLinks: schools,
    groupType: groupType ? groupType[0] : null
  }

  if (field && order) requestFilters[SORT_FIELDS[field]] = ORDERS[order]

  return requestFilters
}

export const handlePhoneNumberError = serverResponse => {
  let numbers = []
  if (serverResponse && Array.isArray(serverResponse)) {
    numbers = serverResponse.map(item => item.phone && item.phone.number)
  }

  if (numbers.length > 1) {
    numbers.reduce(
      (a, b, index) => a.concat(index < numbers.length ? ', ' : ' ', b))
  }

  const config = {
    message: i18n.t('general.feedback.numberExists'),
    description: numbers,
    duration: 4
  }
  notification.error(config)
}
