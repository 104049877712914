import { ACTION, FAMILY_ACTION } from '../actions/types'

const initialState = {
  schoolGroup: [],
  responsibleSchool: null,
  childId: null,
  familyId: null,
  family: {},
  membersState: [],
  adviceTopics: [],
  adultPopupIsVisible: false,
  adultPopupDefaultProps: {},
  student: {}
}

export const familyReducer = (state = initialState, action) => {
  let member
  let updated = []

  switch (action.type) {
    case FAMILY_ACTION.SET_FAMILY:
      return {
        ...state,
        family: action.payload
      }

    case FAMILY_ACTION.SET_FAMILY_SCHOOL_GROUP:
      return {
        ...state,
        schoolGroup: action.payload
      }

    case FAMILY_ACTION.SET_RESPONSIBLE_SCHOOL:
      return {
        ...state,
        responsibleSchool: action.payload
      }

    case ACTION.SET_CHILD_ID: {
      return {
        ...state,
        childId: action.payload
      }
    }

    case ACTION.SET_FAMILY_ID: {
      return {
        ...state,
        familyId: action.payload
      }
    }

    case FAMILY_ACTION.SET_APPLICATION_STUDENT: {
      return {
        ...state,
        student: action.payload
      }
    }

    case FAMILY_ACTION.SET_MEMBERS_STATE: {
      member = state.membersState.find(item => item.id === action.payload.id)
      if (member) {
        updated = state.membersState.filter(
          item => item.id !== action.payload.id)
      } else {
        updated = state.membersState
      }
      return {
        ...state,
        membersState: [...updated, action.payload]
      }
    }

    case FAMILY_ACTION.CLEAR_MEMBERS_STATE: {
      return {
        ...state,
        membersState: action.payload
      }
    }
    case FAMILY_ACTION.CHANGE_VISIBLE_ADULT_POPUP: {
      return {
        ...state,
        adultPopupIsVisible: action.payload
      }
    }
    case FAMILY_ACTION.SET_DEFAULT_PROPS_ADULT_POPUP: {
      return {
        ...state,
        adultPopupDefaultProps: action.payload
      }
    }

    case FAMILY_ACTION.SET_ADVICE_TOPICS: {
      return {
        ...state,
        adviceTopics: action.payload
      }
    }

    default:
      return state
  }
}
