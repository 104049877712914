import { SERVICE_PRICES_TYPES } from '../actions/servicePrices'

const initialState = {
  services: [],
  pagination: {
    total: undefined,
    pageSize: 10,
    current: 1
  },
  lastUpdated: undefined
}

export const servicePricesReducer = (state = initialState, action) => {
  const { payload, type } = action

  switch (type) {
    case SERVICE_PRICES_TYPES.GET_SERVICE_PRICES:
      return {
        ...state,
        services: payload.list,
        pagination: {
          total: payload.total,
          current: payload.current
        }
      }
      // case SERVICE_PRICES_TYPES.CREATE_SERVICE_PRICE:
      //   return {
      //     ...state,
      //     services: payload,
      //     pagination: {
      //       ...state.pagination,
      //       total: +state.pagination.total + 1
      //     }
      //   };
      // case SERVICE_PRICES_TYPES.UPDATE_SERVICE_PRICE:
      //   return {
      //     ...state,
      //     services: payload,
      //   };

    case SERVICE_PRICES_TYPES.GET_SERVICES_LAST_UPDATED:
      return { ...state, lastUpdated: payload }

    // case SERVICE_PRICES_TYPES.DELETE_SERVICE:
    //   return { ...state, services: state.services?.filter(s => s.id !== payload) };
    //
    default:
      return state
  }
}
