import { ACTION } from '../actions/types'
import i18n from 'i18n-js'

const initialState = {
  currentUser: null,
  avatar: null,
  userData: {},
  userDetails: {},
  profileCurrentRole: null,
  monitoring: [],
  mentorData: [],
  motivationData: [],
  chosenCourse: { name: i18n.t('MentorSummary.chooseCourse') },
  summaryTableCourse: { name: i18n.t('MentorSummary.chooseCourse') },
  profileNotification: {
    message: '',
    isOpen: false
  },
  teachersForReplace: []
}

export const profileReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION.SET_AVATAR:
      return {
        ...state,
        avatar: action.payload
      }

    case ACTION.SET_PROFILE_CURRENT_ROLE:
      return { ...state, profileCurrentRole: action.data }

    case ACTION.SET_PROFILE_MAIN_DATA:
      return {
        ...state,
        userData: action.data
      }

    case ACTION.SET_PROFILE_DETAILED_DATA:
      return {
        ...state,
        userDetails: action.data
      }

    case ACTION.SET_FINANCIAL_SETTINGS:
      return {
        ...state, finSettings: action.data
      }

    case ACTION.SET_MONITORING:
      return {
        ...state, monitoring: action.data
      }
    case ACTION.SET_MENTOR_DATA:
      return {
        ...state, mentorData: action.data
      }

    case ACTION.SET_CHOSEN_COURSE:
      return {
        ...state, chosenCourse: action.data
      }

    case ACTION.SET_SUMMARY_TABLE_COURSE:
      return {
        ...state, summaryTableCourse: action.data
      }

    case ACTION.SET_MOTIVATION:
      return {
        ...state, motivationData: action.data
      }

    case ACTION.SET_CURRENT_EMPLOYEE:
      return {
        ...state, currentUser: action.data
      }

    case ACTION.SET_PROFILE_NOTIFICATION:
      return { ...state, profileNotification: action.payload }

    case ACTION.SET_TEACHERS_FOR_REPLACE:
      return { ...state, teachersForReplace: action.payload }

    default:
      return state
  }
}
