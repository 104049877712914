import { ACTION } from '../actions/types'
import { LS_VALUES } from '../../constants/text'

const initialState = {
  activeSchoolGroup: [],
  currentSchools: [],
  communicationTypes: [],
  communicationDistributionData: [],
  communicationMeetingsData: [],
  communicationInfoData: [],
  communicationCallLogData: [],
  learnPaymentsHistory: [
    {
      id: 0,
      fullName: 'fullName 1',
      group: 'group 1',
      subscriptionType: 'subscriptionType 1',
      count: 'count 1',
      price: 150,
      paymentDate: 'paymentDate 1',
      discount: 20,
      sum: 130,
      paymentId: 'paymentId 1'
    },
    {
      id: 1,
      fullName: 'fullName 2',
      group: 'group 2',
      subscriptionType: 'subscriptionType 2',
      count: 'count 2',
      price: 340,
      paymentDate: 'paymentDate 2',
      discount: 5,
      sum: 335,
      paymentId: 'paymentId 2'
    },
    {
      id: 2,
      fullName: 'fullName 3',
      group: 'group 3',
      subscriptionType: 'subscriptionType 3',
      count: 'count 3',
      price: 550,
      paymentDate: 'paymentDate 3',
      discount: 15,
      sum: 535,
      paymentId: 'paymentId 3'
    },
    {
      id: 3,
      fullName: 'fullName 4',
      group: 'group 4',
      subscriptionType: 'subscriptionType 4',
      count: 'count 4',
      price: 220,
      paymentDate: 'paymentDate 4',
      discount: 10,
      sum: 210,
      paymentId: 'paymentId 4'
    },
    {
      id: 4,
      fullName: 'fullName 5',
      group: 'group 5',
      subscriptionType: 'subscriptionType 5',
      count: 'count 5',
      price: 1500,
      paymentDate: 'paymentDate 5',
      discount: 55,
      sum: 1445,
      paymentId: 'paymentId 5'
    }
  ],
  otherPaymentsHistory: [
    {
      id: 0,
      fullName: 'fullName 1',
      group: 'group 1',
      subscriptionType: 'subscriptionType 1',
      count: 'count 1',
      price: 150,
      paymentDate: 'paymentDate 1',
      discount: 20,
      sum: 130,
      paymentId: 'paymentId 1'
    },
    {
      id: 1,
      fullName: 'fullName 2',
      group: 'group 2',
      subscriptionType: 'subscriptionType 2',
      count: 'count 2',
      price: 340,
      paymentDate: 'paymentDate 2',
      discount: 5,
      sum: 335,
      paymentId: 'paymentId 2'
    }
  ],
  headerTablePaymentsHistory: [
    {
      id: 0,
      fullName: 'fullName 1',
      restOfLessons: 'restOfLessons 1',
      balanceOfPrepayments: 100,
      group: 'group 1'
    },
    {
      id: 1,
      fullName: 'fullName 2',
      restOfLessons: 'restOfLessons 2',
      balanceOfPrepayments: 200,
      group: 'group 2'
    }
  ],
  learnProcessData: [
    {
      id: 0,
      fullName: 'fullName 1',
      group: 'group 1',
      subscriptionType: 'subscriptionType 1',
      count: 'count 1',
      price: 150,
      chooseDiscount: 'chooseDiscount 1',
      discount: 20,
      sum: 130
    },
    {
      id: 1,
      fullName: 'fullName 2',
      group: 'group 2',
      subscriptionType: 'subscriptionType 2',
      count: 'count 2',
      price: 340,
      chooseDiscount: 'chooseDiscount 2',
      discount: 5,
      sum: 335
    },
    {
      id: 2,
      fullName: 'fullName 3',
      group: 'group 3',
      subscriptionType: 'subscriptionType 3',
      count: 'count 3',
      price: 550,
      chooseDiscount: 'chooseDiscount 3',
      discount: 15,
      sum: 535
    },
    {
      id: 3,
      fullName: 'fullName 4',
      group: 'group 4',
      subscriptionType: 'subscriptionType 4',
      count: 'count 4',
      price: 220,
      chooseDiscount: 'chooseDiscount 4',
      discount: 10,
      sum: 210
    },
    {
      id: 4,
      fullName: 'fullName 5',
      group: 'group 5',
      subscriptionType: 'subscriptionType 5',
      count: 'count 5',
      price: 1500,
      chooseDiscount: 'chooseDiscount 5',
      discount: 55,
      sum: 1445
    }
  ],
  otherProductsData: [
    {
      id: 0,
      fullName: 'fullName 1',
      group: 'group 1',
      goods: 'subscriptionType 1',
      count: 'count 1',
      price: 150,
      chooseDiscount: 'chooseDiscount 1',
      discount: 20,
      sum: 130
    },
    {
      id: 1,
      fullName: 'fullName 2',
      group: 'group 2',
      goods: 'subscriptionType 2',
      count: 'count 2',
      price: 340,
      chooseDiscount: 'chooseDiscount 2',
      discount: 5,
      sum: 335
    }
  ],
  clientCommunicationFilters: {
    callType: true,
    name: true,
    phone: true,
    date: true,
    callRecord: true,
    schoolName: true,
    adminFullName: true,
    callDuration: true,
    callId: true,
    reason: true,
    result: true
  },
  feedbackTableData: [],
  dataSource: [],
  dataSourceKanban: {
    activeClients: [],
    inActiveClients: [],
    schoolLeaverClients: []
  },
  dataSourceFilters: JSON.parse(
    localStorage.getItem(LS_VALUES.CLIENTS_TABLE_FILTERS_LIST)) || {
    childrenAmount: null,
    fullName: null,
    id: null,
    phone: null,
    status: null,
    blackList: ['false'],
    childName: null
  },
  schoolFilterOptions: ['Позняки', 'Осокорки', 'Оболонь', 'КПІ', 'Петровка'],
  groupFilterOptions: [],
  schoolFilterValue: [],
  groupFilterValue: [],
  isKanbanType: false,
  kanbanStatusBadges: {
    activeCount: 0,
    inActiveCount: 0,
    schoolLeaverCount: 0
  },
  childCardIsOpen: false,
  childInfo: {
    additionalFields: '',
    availableSchools: [],
    dateOfBirth: null,
    firstName: null,
    gender: 'male',
    id: 0,
    imageUrl: null,
    lastName: null,
    nickname: null,
    phone: null,
    schoolId: null,
    thirdName: null,
    schoolsList: []
  },
  childCharacteristic: [],
  childPaginationConfig: {
    current: 1,
    pageSize: 3,
    total: 0
  },
  clientsPaginationConfig: {
    current: 1,
    pageSize: 10,
    total: 10
  },
  clientsKanbanPaginationConfig: {
    current: 1,
    pageSize: null,
    total: null
  },
  isLastKanbanPage: false,
  selectedClient: {},
  selectedClientsData: [],
  stemHistory: [],
  clientsCustomFieldsPopupConfigVisible: false
}

export const clientsReducer = (state = initialState, action) => {
  const { payload, type } = action

  switch (type) {
    case ACTION.SET_CLIENTS_DATA:
      return {
        ...state,
        dataSource: payload.map((item) => ({
          ...item,
          clientId: item.id,
          fullName: `${item.firstName} ${item.lastName} ${item.thirdName}`,
          children: null,
          childrenAmount: item.children,
          status: item.clientStatus
        }))
      }

    case ACTION.ADD_CLIENTS_KANBAN_DATA: {
      const { dataSourceKanban } = state
      const { activeClients, inActiveClients, schoolLeaverClients } = payload

      return {
        ...state,
        dataSourceKanban: {
          activeClients: [...dataSourceKanban.activeClients, ...activeClients],
          inActiveClients: [
            ...dataSourceKanban.inActiveClients,
            ...inActiveClients],
          schoolLeaverClients: [
            ...dataSourceKanban.schoolLeaverClients,
            ...schoolLeaverClients]
        }
      }
    }

    case ACTION.SET_CLIENTS_KANBAN_DATA:
      return {
        ...state,
        dataSourceKanban: payload,
        isLastKanbanPage: false
      }

    case ACTION.SET_CLIENTS_FILTERS:
      return {
        ...state,
        dataSourceFilters: payload
      }

    case ACTION.SET_CLIENTS_COMMUNICATION_DISTRIBUTIONS:
      return {
        ...state,
        communicationDistributionData: payload
      }
    case ACTION.SET_CLIENTS_COMMUNICATION_MEETINGS:
      return {
        ...state,
        communicationMeetingsData: payload
      }
    case ACTION.SET_CLIENTS_COMMUNICATION_INFO:
      return {
        ...state,
        communicationInfoData: payload
      }

    case ACTION.SET_CLIENTS_COMMUNICATION_TYPES:
      return {
        ...state,
        communicationTypes: payload
      }

    case ACTION.SET_LEARN_PROCESS_DATA:
      return {
        ...state,
        learnProcessData: payload
      }

    case ACTION.SET_OTHER_PRODUCTS_DATA:
      return {
        ...state,
        otherProductsData: payload
      }

    case ACTION.SET_CLIENTS_SCHOOL:
      return {
        ...state,
        currentSchools: payload
      }

    case ACTION.SET_ACTIVE_SCHOOL_GROUP:
      return {
        ...state,
        activeSchoolGroup: payload
      }

    case ACTION.SET_PAGINATION_CONFIG:
      return {
        ...state,
        clientsPaginationConfig: payload
      }

    case ACTION.SET_KANBAN_PAGINATION_CONFIG:
      return {
        ...state,
        clientsKanbanPaginationConfig: payload
      }

    case ACTION.SET_KANBAN_STATUS_BADGES:
      return {
        ...state,
        kanbanStatusBadges: payload
      }

    case ACTION.SET_IS_LAST_KANBAN_PAGE:
      return {
        ...state,
        isLastKanbanPage: payload
      }

    case ACTION.SET_PAGINATION_CONFIG_TOTAL:
      return {
        ...state,
        clientsPaginationConfig: {
          ...state.clientsPaginationConfig,
          total: payload
        }
      }

    case ACTION.SET_CHILD_PAGINATION_CONFIG:
      return {
        ...state,
        childPaginationConfig: payload
      }

    case ACTION.ADD_NEW_CHILD_CHARACTERISTIC:
      return {
        ...state,
        childCharacteristic: [payload, ...state.childCharacteristic],
        childPaginationConfig: {
          ...state.childPaginationConfig,
          current: 1,
          total: state.childPaginationConfig.total + 1
        }
      }

    case ACTION.SET_IS_KANBAN_TYPE:
      return {
        ...state,
        isKanbanType: payload
      }

    case ACTION.SET_CHILD_CHARACTERISTIC:
      return {
        ...state,
        childCharacteristic: payload
      }

    case ACTION.SET_SCHOOL_FILTERS:
      return {
        ...state,
        schoolFilterValue: payload
      }

    case ACTION.SET_GROUP_FILTERS:
      return {
        ...state,
        groupFilterValue: payload
      }

    case ACTION.SET_CHILD_CARD_IS_OPEN:
      return {
        ...state,
        childCardIsOpen: payload
      }

    case ACTION.SET_CURRENT_CHILD_INFO:
      return {
        ...state,
        childInfo: payload
      }

    case ACTION.SET_SELECTED_CLIENT:
      return {
        ...state,
        selectedClient: payload
      }

    case ACTION.SET_SELECTED_CLIENTS_KEYS:
      console.log({ payload })
      return {
        ...state,
        selectedClientsData: payload
      }

    case ACTION.SET_COMMUNICATION_FILTERS:
      return {
        ...state,
        clientCommunicationFilters: payload
      }

    case ACTION.SET_GROUP_FILTER_OPTIONS:
      return {
        ...state,
        groupFilterOptions: payload
      }

    case ACTION.SET_STEM_HISTORY:
      return { ...state, stemHistory: payload }

    case ACTION.SET_CUSTOM_FIELDS_CONFIG_POPUP_VISIBLE:
      return { ...state, clientsCustomFieldsPopupConfigVisible: payload }

    default:
      return state
  }
}
