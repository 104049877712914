import { t } from 'i18n-js'
import { notification } from 'antd'

// ExcelErrors
const SPECIFIC_ERRORS = {
  'The following required columns cannot be found in the file : [1]!. Please check file.': 'ExcelErrors.validateColumns',
  'Unable to select course due to missing label \'1\' in particular of the product': 'ExcelErrors.optionIsRequired',
  'Incorrect discount type! - [1]!. Please check file.': 'ExcelErrors.typeError',
  'Incorrectly entered validity period - [1]!. Please check file.': 'ExcelErrors.dateError',
  'The specified INN already exists! Exception - [1] ': 'ExcelErrors.uniqueINN',
  'Course [1] not found. Please check file.': 'ExcelErrors.courseNotFound',
  ERROR_DURING_UPLOAD: 'ExcelErrors.errorDuringUpload',
  ERROR_ROW_COLUMN: 'ExcelErrors.errorRowColumn'
}

export const processExcelError = ({
  exceptionMassage,
  parameters
}) => {
  console.log({
    parameters,
    exceptionMassage
  })
  if (SPECIFIC_ERRORS[exceptionMassage] && (!parameters || Object.keys(parameters).length === 0)) {
    notification.error({
      message: t(SPECIFIC_ERRORS[exceptionMassage]),
      duration: 2
    })
    return false
  }

  if (Object.keys(parameters).length === 1) {
    const messageStr = parameters[1]
    const fields = messageStr?.split('field')
    let stop = false
    let i = 0
    while (!stop) {
      const matchesRow = fields[i].match(/\d+/g)?.[0]
      const matchesColumn = fields[i].match(/'(\\.|[^"\\])*'/)?.[0]
      if (matchesRow && matchesColumn) {
        notification.error({
          message: t(SPECIFIC_ERRORS.ERROR_ROW_COLUMN, {
            row: matchesRow,
            column: matchesColumn
          }),
          duration: 2
        })
        stop = true
      } else {
        console.log(`${t(SPECIFIC_ERRORS.ERROR_ROW_COLUMN)}, ${fields[i]}`)
        notification.error({
          message: t(SPECIFIC_ERRORS.ERROR_ROW_COLUMN, {
            row: '?',
            column: fields[i]
          }),
          duration: 15
        })
        stop = true
      }
      i++
    }
    return false
  }

  if (Object.keys(parameters).length > 1) {
    notification.error({
      message: t(SPECIFIC_ERRORS.ERROR_DURING_UPLOAD, {
        column: parameters[1],
        value: parameters[2]
      }),
      duration: 2
    })

    if (parameters[3]) {
      notification.error({
        message: t(SPECIFIC_ERRORS[parameters[3].exceptionMassage]),
        duration: 2
      })
    }
    return false
  }
}
