import axios from 'axios'
import {
  BASE_SCHOOL_SERVICE_URL,
  BASE_UAA_SERVICE_URL,
  ONE_FAMILY_API
} from '../constants/api'

export const getFamilyById = (id) => {
  return axios({
    url: `${ONE_FAMILY_API}/${id}`,
    method: 'get',
    headers: { 'content-type': 'application/json' },
    withCredentials: true
  })
}

export const updateFamilyById = (id, data) => {
  return axios({
    url: `${ONE_FAMILY_API}/${id}`,
    method: 'put',
    headers: { 'content-type': 'application/json' },
    withCredentials: true,
    data: JSON.stringify(data)
  })
}

export const createFamily = (data) => {
  return axios({
    url: `${ONE_FAMILY_API}`,
    method: 'post',
    headers: { 'content-type': 'application/json' },
    withCredentials: true,
    data: JSON.stringify(data)
  })
}

export const createParent = (familyId, data) => {
  return axios({
    url: `${ONE_FAMILY_API}/${familyId}/parent`,
    method: 'post',
    headers: { 'content-type': 'application/json' },
    withCredentials: true,
    data: JSON.stringify(data)
  })
}

export const addFamilyToBlackList = (familyId, data) => {
  return axios({
    url: `${ONE_FAMILY_API}/black-list/${familyId}`,
    method: 'put',
    headers: { 'content-type': 'application/json' },
    withCredentials: true,
    data
  })
}
export const removeFamilyFromBlackList = (familyId, data) => {
  return axios({
    url: `${ONE_FAMILY_API}/black-list/${familyId}`,
    method: 'put',
    headers: { 'content-type': 'application/json' },
    withCredentials: true,
    data
  })
}

export const changeMainParent = (familyId, entityId) => {
  return axios({
    url: `${ONE_FAMILY_API}/${familyId}/change-main-contact`,
    method: 'put',
    headers: { 'content-type': 'application/json' },
    withCredentials: true,
    data: { entityId }
  })
}

export const changeEmergencyParent = (familyId, entityId) => {
  return axios({
    url: `${ONE_FAMILY_API}/${familyId}/change-emergency-contact`,
    method: 'put',
    headers: { 'content-type': 'application/json' },
    withCredentials: true,
    data: { entityId }
  })
}

export const getSchoolsByFamilyId = async (familyId) => {
  const response = await axios.get(
    `${BASE_SCHOOL_SERVICE_URL}/school-group/schools-names-in-group-by-familyId/${familyId}`)
  return response?.data?.data || []
}

export const changeDefaultSchoolToCall = async (familyId, schoolId) => {
  const response = await axios.put(
    `${BASE_UAA_SERVICE_URL}/families/default-school?familyId=${familyId}&schoolId=${schoolId}`)
  return response?.data?.data || false
}
