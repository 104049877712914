import axios from 'axios';
import { PARENT_API, ONE_FAMILY_API, WISHES_API, ADVICES_TOPIC_API } from '../constants/api';

export const getParentById = id => {
  return axios({
    url: `${PARENT_API}/${id}`,
    method: 'get',
    headers: { 'content-type': 'application/json' },
    withCredentials: true,
  });
};

export const updateParentById = (id, data) => {
  return axios({
    url: `${PARENT_API}/${id}`,
    method: 'put',
    headers: { 'content-type': 'application/json' },
    withCredentials: true,
    data: JSON.stringify(data)
  });
};

export const deleteParentById = (familyId, parentId) => {
  const params = {
    familyId,
    parentId
  };
  return axios({
    url: `${ONE_FAMILY_API}/${familyId}/parent/${parentId}`,
    method: 'delete',
    headers: { 'content-type': 'application/json' },
    withCredentials: true,
    params: params
  });
};

export const createWish = (data) => {
  return axios({
    url: `${WISHES_API}`,
    method: 'post',
    headers: { 'content-type': 'application/json' },
    withCredentials: true,
    data: JSON.stringify(data)
  });
};

export const getWish = (adviceId) => {
  return axios({
    url: `${WISHES_API}/${adviceId}`,
    method: 'get',
    headers: { 'content-type': 'application/json' },
    withCredentials: true,
  });
};

export const updateWish = (adviceId, data) => {
  return axios({
    url: `${WISHES_API}/${adviceId}`,
    method: 'put',
    headers: { 'content-type': 'application/json' },
    withCredentials: true,
    data: JSON.stringify(data)
  });
};

export const createAdviceTopic = data => {
  return axios({
    url: `${ADVICES_TOPIC_API}`,
    method: 'post',
    headers: { 'content-type': 'application/json' },
    withCredentials: true,
    data: JSON.stringify(data)
  });
};

export const getAdviceTopics = () => {
  return axios({
    url: `${ADVICES_TOPIC_API}`,
    method: 'get',
    headers: { 'content-type': 'application/json' },
    withCredentials: true,
  });
};