import { CALLS_JOURNAL_ACTIONS } from '../actions/types'

const initialState = {
  calls: [],
  filters: {},
  pagination: {
    hideOnSinglePage: true,
    current: 1,
    total: 0,
    pageSizeOptions: [10, 15, 25, 50],
    defaultPageSize: 10,
    pageSize: 10
  },
  reasons: [],
  results: []
}

export const callsJournalReducer = (state = initialState, action) => {
  switch (action.type) {
    case CALLS_JOURNAL_ACTIONS.SET_CALLS_TABLE_DATA:
      return { ...state, calls: action.payload }

    case CALLS_JOURNAL_ACTIONS.SET_CALLS_TABLE_PAGINATION:
      return { ...state, pagination: { ...state.pagination, ...action.payload } }

    case CALLS_JOURNAL_ACTIONS.SET_CALLS_TABLE_FILTERS:
      return { ...state, filters: action.payload }

    case CALLS_JOURNAL_ACTIONS.SET_CALLS_RESULTS:
      return { ...state, results: action.payload }

    case CALLS_JOURNAL_ACTIONS.SET_CALLS_REASONS:
      return { ...state, reasons: action.payload }

    default:
      return state
  }
}
