import { DISCOUNTS_ACTIONS } from '../actions/types'
import { STATUSES } from '../../constants/text'

const initialState = {
  discounts: [],
  filters: {
    status: [STATUSES.ACTIVE]
  },
  currentDiscount: {},
  pagination: {
    hideOnSinglePage: true,
    current: 1,
    pageSize: 10
  },
  sorter: {}
}

export const discountsReducer = (state = initialState, action) => {
  switch (action.type) {
    case DISCOUNTS_ACTIONS.SET_DISCOUNTS:
      return { ...state, discounts: action.payload }

    case DISCOUNTS_ACTIONS.SET_CURRENT_DISCOUNT:
      return { ...state, currentPromoCode: action.payload }

    case DISCOUNTS_ACTIONS.SET_DISCOUNTS_FILTERS:
      return { ...state, filters: action.payload }

    case DISCOUNTS_ACTIONS.SET_DISCOUNTS_SORTER:
      return { ...state, sorter: action.payload }

    case DISCOUNTS_ACTIONS.SET_DISCOUNTS_PAGINATION:
      return { ...state, pagination: action.payload }

    default:
      return state
  }
}
