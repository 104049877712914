import { WIDGET_CALL_TYPES } from '../actions/widgetCall'

const initialState = {
  isAvailableAdmin: true,
  isOpenWidget: false,
  isOpenMappingPhone: false,
  schools: undefined,
  reasons: undefined,
  calls: [],
  children: [],
  activeCall: undefined,
  lostCallsCount: undefined,
  unproccesedCallsCount: undefined,
  phoneChannelDTO: undefined,
  mappingPhones: [],
  foundLeeds: [],
  mutedIncomingCalls: false,
  callsStatistics: {}
}

export const widgetCallReducer = (state = initialState, action) => {
  const {
    payload,
    type
  } = action

  switch (type) {
    case WIDGET_CALL_TYPES.GET_SCHOOLS:
      return {
        ...state,
        schools: payload
      }
    case WIDGET_CALL_TYPES.GET_REASONS:
      return {
        ...state,
        reasons: payload
      }
    case WIDGET_CALL_TYPES.GET_CALLS:
      return { ...state, ...payload }
    case WIDGET_CALL_TYPES.GET_CALL:
      return {
        ...state,
        activeCall: payload
      }
    case WIDGET_CALL_TYPES.CLOSE_CALL:
      return {
        ...state,
        activeCall: undefined
      }
    case WIDGET_CALL_TYPES.GET_ALL_PARENTS_BY_FILTERS:
      return {
        ...state,
        foundLeeds: payload
      }
    case WIDGET_CALL_TYPES.GET_MAPPING_PHONES:
      return {
        ...state,
        mappingPhones: payload
      }
    case WIDGET_CALL_TYPES.UPDATE_MAPPING_PHONE:
      return {
        ...state,
        phoneChannelDTO: payload
      }
    case WIDGET_CALL_TYPES.CHANGE_MAPPING_PHONE_VISIBILITY:
      return {
        ...state,
        isOpenMappingPhone: payload
      }
    case WIDGET_CALL_TYPES.CHANGE_MUTE_INCOMING_CALLS:
      return {
        ...state,
        mutedIncomingCalls: payload
      }
    case WIDGET_CALL_TYPES.OPEN_WIDGET:
      return {
        ...state,
        isOpenWidget: true
      }
    case WIDGET_CALL_TYPES.CLOSE_WIDGET:
      return {
        ...state,
        isOpenWidget: false
      }

    case WIDGET_CALL_TYPES.SET_CALLS_LIST:
      return {
        ...state,
        calls: payload
      }
    case WIDGET_CALL_TYPES.SET_CALLS_STATISTICS:
      return {
        ...state,
        callsStatistics: payload
      }

    default:
      return state
  }
}
