import { SPINNER_ACTIONS } from '../actions/types';

const initialState = {
  actionTabsSpinner: false,
  eventSpinner: false,
};

export const spinnersReducer = (state = initialState, action) => {
  switch (action.type) {
    case SPINNER_ACTIONS.SET_ACTION_TAB_SPINNER:
      return {...state, actionTabsSpinner: action.payload};
    case SPINNER_ACTIONS.SET_EVENT_SPINNER:
      return {...state, eventSpinner: action.payload};
    default:
      return state;
  }
};