import * as api from '../../utils/cart'
import { deleteOrder } from '../../utils/cart'
import * as productPricesApi from '../../utils/productPrices'
import i18n, { t } from 'i18n-js'
import { notification } from 'antd'

export const CART_TYPES = {
  CREATE_NEW_ORDER: 'CHANGE_ACTIVE_ORDER',
  GET_ORDER: 'GET_ORDER',
  SET_ORDER: 'SET_ORDER',
  GET_FAMILY_CHILDREN: 'GET_FAMILY_CHILDREN',
  ADD_PRODUCT_ROW: 'ADD_PRODUCT_ROW',
  ADD_SERVICE_ROW: 'ADD_SERVICE_ROW',
  CREATE_ORDER_PRODUCT: 'CREATE_ORDER_PRODUCT',
  CREATE_ORDER_SERVICE: 'CREATE_ORDER_SERVICE',
  UPDATE_PRODUCT_ROW: 'UPDATE_PRODUCT_ROW',
  UPDATE_SERVICE_ROW: 'UPDATE_SERVICE_ROW',
  UPDATE_EXISTS_PRODUCT_ROW: 'UPDATE_EXISTS_PRODUCT_ROW',
  UPDATE_EXISTS_SERVICE_ROW: 'UPDATE_EXISTS_SERVICE_ROW',
  SAVE_ORDER: 'SAVE_ORDER',
  DELETE_ORDER_DETAIL: 'DELETE_ORDER_DETAIL',
  DELETE_EXISTS_ORDER_DETAIL: 'DELETE_EXISTS_ORDER_DETAIL',
  GET_PIGGY_BANK: 'GET_PIGGY_BANK',
  GET_ORDER_STATUSES: 'GET_ORDER_STATUSES',
  ADD_DEPOSIT_ROW: 'ADD_DEPOSIT_ROW',
  SPENT_FROM_DEPOSIT: 'SPENT_FROM_DEPOSIT',
  GET_FAMILY_STEMS: 'GET_FAMILY_STEMS',
  GET_ORDER_RESULTS: 'GET_ORDER_RESULTS',
  CHANGE_PIGGY_BANK_AMOUNT: 'CHANGE_PIGGY_BANK_AMOUNT',
  UPDATE_STEMS: 'UPDATE_STEMS',
  SET_ORDER_FOP: 'SET_ORDER_FOP',
  SET_ORDER_LOADING: 'SET_ORDER_LOADING'
}

export const PIGGY_BANK_SERVICE_ID = -1
export const PIGGY_BANK_LESSONS_REMAIN = -2

export const setOrder = (payload) => {
  return {
    type: CART_TYPES.GET_ORDER,
    payload
  }
}

export const setOrderLoading = (isLoading) => ({
  type: CART_TYPES.SET_ORDER_LOADING,
  payload: isLoading
})

export const setOrderFOP = (payload) => {
  return {
    type: CART_TYPES.SET_ORDER_FOP,
    payload
  }
}

export const getOrderById = async (orderId) => {
  try {
    const {
      services,
      products,
      productSum,
      serviceSum,
      totalSum,
      order
    } = await api.getOrderById(orderId)
    return {
      products: toProductsFormat(products),
      services: toServicesFormat(services),
      servicesTotal: serviceSum,
      productsTotal: productSum,
      total: totalSum,
      order
    }
  } catch (err) {
    console.error(`catch an error ${err}`)
  }
}

export const recalcOrderSuppliers = (orderId, data) => async (
  dispatch, getState) => {
  try {
    const { cart: { results } } = getState()
    const orderFOP = await api.recalcOrderSuppliers(orderId, data)
    console.log({
      orderId,
      data
    })
    dispatch(setOrderFOP({
      ...results,
      orderSuppliers: orderFOP
    }))
  } catch (err) {
    console.error('catch an error', err)
  }
}

export const getFamilyChildren = (familyId) => async (dispatch) => {
  try {
    const children = await api.getFamilyChildren(familyId)

    dispatch({
      type: CART_TYPES.GET_FAMILY_CHILDREN,
      payload: children?.map(child => {
        return {
          ...child,
          name: child.lastName + ' ' + child.firstName
        }
      })
    })
  } catch (err) {
    console.error(`catch an error ${err}`)
  }
}

export const addProductRow = (data) => (dispatch) => {
  const id = `tmp-${Math.round(Math.random() * 1e6)}`
  const row = {
    ...data,
    id
  }
  dispatch({
    type: CART_TYPES.ADD_PRODUCT_ROW,
    payload: row
  })

  return id
}

export const addDepositRow = (orderId, amount = 1) => async (dispatch) => {
  try {
    const data = {
      itemId: PIGGY_BANK_SERVICE_ID,
      amount,
      count: 1,
      orderId
    }

    const updatedOrder = await api.addOrderService(data)
    console.log({ updatedOrder })

    dispatch({
      type: CART_TYPES.ADD_DEPOSIT_ROW,
      payload: {
        products: toProductsFormat(updatedOrder.products),
        services: toServicesFormat(updatedOrder.services),
        total: updatedOrder.totalSum,
        productsTotal: updatedOrder.productSum,
        servicesTotal: updatedOrder.serviceSum
      }
    })
  } catch (err) {
    console.error(`catch an error ${err}`)
  }
}

export const updateProductRow = (
  row, data, accessor, orderId) => async (dispatch, getState) => {
  const { cart: { products } } = getState()

  if (row.id.toString().startsWith('tmp-')) {
    if (accessor === 'product') {
      // create an order product
      console.log('creating ...')
      const product = await productPricesApi.getProductById(data.id)
      console.log({ product })

      const orderProduct = await api.addOrderProduct({
        orderId: row.orderId,
        itemId: product.id,
        studentId: row.student?.id,
        groupId: row.group?.id,
        discountId: row.discount?.id,
        count: row.count || 1
      })
      console.log({ orderProduct })
      dispatch({
        type: CART_TYPES.CREATE_ORDER_PRODUCT,
        payload: {
          total: orderProduct.totalSum,
          productsTotal: orderProduct.productSum,
          servicesTotal: orderProduct.serviceSum,
          services: toServicesFormat(orderProduct.services),
          products: toProductsFormat(orderProduct.products)
        }
      })
      // todo это не прваильное решение
      return orderProduct.products?.[0]?.id
    } else {
      // just update product row
      dispatch({
        type: CART_TYPES.UPDATE_PRODUCT_ROW,
        payload: products?.map(item => {
          if (row.id === item.id) {
            return {
              ...item,
              [accessor]: data,
              group: accessor === 'student' ? null : item.group,
              product: accessor === 'student' || accessor === 'group'
                ? null
                : item.product
            }
          }
          return item
        })
      })
    }
  } else {
    // server logic
    const updatedParams = {
      orderId: orderId,
      itemId: accessor === 'product' ? data.id : row.product.id,
      studentId: accessor === 'student' ? data.id : row.student?.id,
      groupId: accessor === 'group' ? data.id : row.group?.id,
      discountId: accessor === 'discount' ? data.id : row.discount?.id,
      count: accessor === 'count' ? data : row.count || 1,
      orderDetailId: row.id
    }

    const updatedOrder = await api.updateOrderProduct(updatedParams)

    dispatch({
      type: CART_TYPES.UPDATE_EXISTS_PRODUCT_ROW,
      payload: {
        products: toProductsFormat(updatedOrder.products),
        services: toServicesFormat(updatedOrder.services),
        total: updatedOrder.totalSum,
        productsTotal: updatedOrder.productSum,
        servicesTotal: updatedOrder.serviceSum
      }
    })
  }

  return row.id
}

export const addServiceRow = (data, serviceRowId) => (dispatch) => {
  const id = serviceRowId || `tmp-${Math.round(Math.random() * 1e6)}`
  const row = {
    ...data,
    id
  }
  dispatch({
    type: CART_TYPES.ADD_SERVICE_ROW,
    payload: row
  })

  return id
}

export const updateServiceRow = (
  row, data, accessor, orderId) => async (dispatch, getState) => {
  const { cart: { services } } = getState()

  const updatedData = {
    ...row,
    [accessor]: data
  }

  if (row.id.toString().startsWith('tmp-')) {
    dispatch({
      type: CART_TYPES.UPDATE_SERVICE_ROW,
      payload: services?.map(item => {
        if (item.id === row.id) {
          return updatedData
        }
        return item
      })
    })

    if (updatedData.student?.id && updatedData?.group?.id &&
      updatedData?.service?.id) {
      const orderService = await api.addOrderService({
        orderId: orderId,
        itemId: updatedData?.service?.id,
        studentId: updatedData?.student?.id,
        groupId: updatedData?.group?.id,
        discountId: updatedData?.discount?.id,
        count: updatedData?.count || 1
      })

      dispatch({
        type: CART_TYPES.CREATE_ORDER_SERVICE,
        payload: {
          total: orderService.totalSum,
          productsTotal: orderService.productSum,
          servicesTotal: orderService.serviceSum,
          services: toServicesFormat(orderService.services),
          products: toProductsFormat(orderService.products)
        }
      })

      return orderService.services?.[0]?.id
    }
  } else {
    // server logic
    const updatedParams = {
      orderId,
      itemId: accessor === 'service' ? data.id : row.service?.id,
      studentId: accessor === 'student' ? data.id : row.student?.id,
      groupId: accessor === 'group' ? data.id : row.group?.id,
      discountId: accessor === 'discount' ? data.id : row.discount?.id,
      count: accessor === 'count' ? data : row.count || 1,
      amount: accessor === 'amount' ? data : row.amount || 1,
      orderDetailId: row.id
    }

    if (accessor === 'group') {
      updatedParams.itemId = null
    } else if (accessor === 'student') {
      updatedParams.itemId = null
      updatedParams.groupId = null
    }
    const updatedOrder = await api.updateOrderService(updatedParams)
    dispatch({
      type: CART_TYPES.UPDATE_EXISTS_SERVICE_ROW,
      payload: {
        products: toProductsFormat(updatedOrder.products),
        services: toServicesFormat(updatedOrder.services),
        total: updatedOrder.totalSum,
        productsTotal: updatedOrder.productSum,
        servicesTotal: updatedOrder.serviceSum
      }
    })
  }

  return row.id
}

export const saveOrder = (orderId) => async (dispatch) => {
  try {
    const saved = await api.saveOrder(orderId)

    if (saved) {
      dispatch({
        type: CART_TYPES.SAVE_ORDER
      })
    }
  } catch (err) {
    console.error(`catch an error ${err}`)
  }
}

export const deleteOrderDetail = (orderDetailId, orderId) => async (
  dispatch, getState) => {
  try {
    if (orderDetailId.toString().startsWith('tmp-')) {
      dispatch({
        type: CART_TYPES.DELETE_ORDER_DETAIL,
        payload: orderDetailId
      })

      return true
    }

    const response = await api.deleteOrderDetail(orderDetailId)
    // todo response
    console.log({ response })
    const data = await getOrderById(orderId)
    dispatch(setOrder(data))
  } catch (err) {
    console.error(`catch an error ${err}`)
  }
}

export const filterProductsByNameOrBarcode = async (params) => {
  try {
    return await api.filterProductsByNameOrBarcode(params)
  } catch (err) {
    console.error(`catch an error ${err}`)
  }
}

export const getOrderStatuses = () => async (dispatch) => {
  try {
    const statuses = await api.getOrderStatuses()

    dispatch({
      type: CART_TYPES.GET_ORDER_STATUSES,
      payload: statuses
    })
  } catch (err) {
    console.error(`catch an error ${err}`)
  }
}

export const getOrderResults = (orderId) => async (dispatch) => {
  try {
    const results = await api.getOrderResults(orderId)
    dispatch({
      type: CART_TYPES.GET_ORDER_RESULTS,
      payload: results
    })
  } catch (err) {
    console.error(`catch an error ${err}`)
  }
}

export const spentFromDeposit = (orderId, amount) => async (dispatch) => {
  try {
    const payment = await api.spentFromDeposit(orderId, amount)

    dispatch({
      type: CART_TYPES.SPENT_FROM_DEPOSIT,
      payload: payment
    })
  } catch (err) {
    console.error(`catch an error ${err}`)
  }
}
export const undoSpentFromDeposit = (
  paymentId, orderId) => async (dispatch) => {
  try {
    const deposit = await api.undoSpentFromDeposit(paymentId, orderId)
    // getOrderResults();
    // todo актуальные результаты
    console.log({ deposit })
  } catch (err) {
    console.error(`catch an error ${err}`)
  }
}

export const writeOffFromCash = (orderId, amount) => async (dispatch) => {
  try {
    await api.writeOffFromCash(orderId, amount)
    await openBillPdf(orderId)
    const data = await getOrderById(orderId)
    dispatch(setOrder(data))
  } catch (err) {
    if (err.response?.data?.statusCode?.code === 2) {
      notification.error({
        message: i18n.t(
          'LeadTabCart.errors.lessons-count-is-grater-then-marked'),
        duration: 3
      })
    } else {
      notification.error(
        {
          message: i18n.t('LeadTabCart.errors.default'),
          duration: 3
        })
    }
  }
}

export const writeOffFromCard = (orderId) => async (dispatch) => {
  try {
    await api.writeOffFromCard(orderId)
    await openBillPdf(orderId)
    const data = await getOrderById(orderId)
    dispatch(setOrder(data))
  } catch (err) {
    if (err.response?.data?.statusCode?.code === 2) {
      notification.error({
        message: i18n.t(
          'LeadTabCart.errors.lessons-count-is-grater-then-marked'),
        duration: 3
      })
    } else {
      notification.error(
        {
          message: i18n.t('LeadTabCart.errors.default'),
          duration: 3
        })
    }
  }
}
export const writeOffFromCardAndCash = (
  orderId, card, cash) => async (dispatch) => {
  try {
    await api.writeOffFromCardAndCash(orderId, card, cash)
    await openBillPdf(orderId)
    const data = await getOrderById(orderId)
    dispatch(setOrder(data))
  } catch (err) {
    if (err.response?.data?.statusCode?.code === 2) {
      notification.error({
        message: i18n.t(
          'LeadTabCart.errors.lessons-count-is-grater-then-marked'),
        duration: 3
      })
    } else {
      notification.error(
        {
          message: i18n.t('LeadTabCart.errors.default'),
          duration: 3
        })
    }
  }
}
export const saveOnlinePay = (orderId, data) => async (dispatch) => {
  try {
    await api.saveOnlinePay(orderId, data)
    await openBillPdf(orderId)
    const order = await getOrderById(orderId)
    dispatch(setOrder(order))
  } catch (err) {
    console.error(`catch an error ${err}`)
  }
}
export const applyPromoCode = (orderId, promoCode) => async (dispatch) => {
  try {
    dispatch(setOrderLoading(true))
    const response = await api.applyPromoCode(orderId, promoCode)
    if (response.data.statusCode.code !== 0) {
      dispatch(setOrderLoading(false))
      const message = i18n.lookup(
        `LeadTabCart.promoCodeError.${response.data.statusCode.code}`) ? i18n.t(
        `LeadTabCart.promoCodeError.${response.data.statusCode.code}`) : i18n.t(
          'LeadTabCart.promoCodeError.default')
      notification.error({
        message: message,
        duration: 3
      })
      dispatch(setOrderLoading(false))
    } else {
      const order = await getOrderById(orderId)
      dispatch(setOrder(order))
      dispatch(setOrderLoading(false))
    }
  } catch (err) {
    dispatch(setOrderLoading(false))
    notification.error(
      {
        message: i18n.t('LeadTabCart.promoCodeError.default'),
        duration: 3
      })
  }
}
export const deletePromoCode = (orderId) => async (dispatch) => {
  try {
    dispatch(setOrderLoading(true))
    const response = await api.deletePromoCode(orderId)
    if (response.data.statusCode.code !== 0) {
      dispatch(setOrderLoading(false))
      const message = i18n.t('LeadTabCart.promoCodeError.delete')
      notification.error({
        message: message,
        duration: 3
      })
      dispatch(setOrderLoading(false))
    } else {
      const order = await getOrderById(orderId)
      dispatch(setOrder(order))
      dispatch(setOrderLoading(false))
    }
  } catch (err) {
    dispatch(setOrderLoading(false))
    notification.error(
      {
        message: i18n.t('LeadTabCart.promoCodeError.delete'),
        duration: 3
      })
  }
}
export const changePiggyBankAmount = (amount) => async (dispatch, getState) => {
  try {
    const { cart: { services } } = getState()
    const newServices = services?.map(service => {
      if (service?.service?.id === PIGGY_BANK_SERVICE_ID) {
        return {
          ...service,
          price: amount
        }
      }
      return service
    })
    console.log({
      services,
      newServices,
      amount
    })

    dispatch({
      type: CART_TYPES.CHANGE_PIGGY_BANK_AMOUNT,
      payload: newServices
    })
  } catch (err) {
    console.error(`catch an error ${err}`)
  }
}

export const putOddMoneyToPiggyBank = (orderId, oddMoney) => async (
  dispatch, getState) => {
  const { cart: { services } } = getState()

  const existsDepositRow = services?.find(
    s => s?.service?.id === PIGGY_BANK_SERVICE_ID)

  if (!existsDepositRow) {
    await addDepositRow(orderId, oddMoney)(dispatch)
  } else {
    const { price } = existsDepositRow
    const result = price + +oddMoney
    await updateServiceRow(existsDepositRow, result, 'amount', orderId)(
      dispatch, getState)
  }

  await getOrderResults(orderId)(dispatch)
}

export const getStems = (familyId) => async (dispatch) => {
  try {
    const stems = await api.getStems(familyId)
    dispatch({
      type: CART_TYPES.GET_FAMILY_STEMS,
      payload: stems
    })
  } catch (err) {
    console.error(`catch an error ${err}`)
  }
}
export const updateStems = (newRows) => (dispatch) => {
  dispatch({
    type: CART_TYPES.UPDATE_STEMS,
    payload: newRows
  })
}

export const getDepositPiggyBank = (schoolId, familyId) => async (dispach) => {
  try {
    const piggyBank = await api.getDepositPiggyBank(schoolId, familyId)

    dispach({
      type: CART_TYPES.GET_PIGGY_BANK,
      payload: piggyBank
    })
  } catch (err) {
    console.error(`catch an error ${err}`)
  }
}

export const applyStems = (orderId) => async (dispatch, getState) => {
  // todo refactor this method ...

  const { cart: { stems } } = getState()
  const promiseArr = stems?.reduce((acc, item) => {
    if (item.amount > 0) {
      const p = new Promise((resolve, reject) => {
        resolve(api.applyStems(orderId, item?.student?.id, item.toWriteOff))
      })
      return [...acc, p]
    }
    return acc
  }, [])

  const data = await Promise.allSettled(promiseArr)
  const order = await getOrderById(orderId)
  dispatch(setOrder(order))
}

export const openBillPdf = async (orderId) => {
  try {
    const content = await api.getOrderBill(orderId)
    // console.log({ content: JSON.parse(content, orderId) })
    const pdf = await api.createPdf(JSON.parse(content))

    const fileURL = URL.createObjectURL(pdf.data)
    const newWindow = window.open('https://google.com', '_blank')
    newWindow.location = fileURL
  } catch (err) {
    console.error(`catch an error ${err}`)
  }
}

export const openBillPdfFull = async (orderId) => {
  try {
    const content = await api.getOrderBill(orderId)
    // console.log({ content: JSON.parse(content, orderId) })
    const pdf = await api.createPdfFull(JSON.parse(content))

    const fileURL = URL.createObjectURL(pdf.data)
    const newWindow = window.open('https://google.com', '_blank')
    newWindow.location = fileURL
  } catch (err) {
    console.error(`catch an error ${err}`)
  }
}
export const getPaymentInfo = async (orderId) => {
  try {
    return await api.getPaymentInfo(orderId)
  } catch (err) {
    console.error(`catch an error ${err}`)
  }
}
export const sendEmailInvoice = (orderId, data) => async (dispatch) => {
  try {
    const response = await api.sendEmailInvoice(orderId, data)

    if (typeof response === 'string') {
      const wayForPayResponse = JSON.parse(response)

      if (wayForPayResponse.reasonCode === 1100) {
        return true
      } else if (wayForPayResponse.reasonCode === 1112) {
        return t('LeadTabCart.payErrors.duplicateOrderId')
      }
    }

    dispatch(getOrderById(orderId))
  } catch (err) {
    console.error(`catch an error ${err}`)
  }
}

export const deleteInactiveOrder = (orderId) => async (dispatch) => {
  try {
    const result = await deleteOrder(orderId)
    if (result) {
      if (result.data?.statusCode.code === 0) {
        notification.success({
          message: i18n.t('LeadTabCart.orderDeleted'),
          duration: 4
        })
        return true
      } else {
        // TODO: display error from error code
        return false
      }
    }
  } catch (e) {
    notification.error(
      {
        message: i18n.t('LeadTabCart.errors.errorDeleteOrder'),
        duration: 4
      })
    return false
  }
}

// ------------------------------------------------------------

// todo пока пусть будет тут

export const getDepositData = async (familyId) => {
  try {
    const response = await api.getDepositData(familyId)
    // console.log({ response })
    return response
  } catch (err) {
    console.error('catch an error', err)
  }
}
export const saveNextProcessing = (data) => async (dispatch) => {}
export const archiveOrder = (orderId) => async (dispatch) => {
  try {
    const paymentInfo = await api.archiveOrder(orderId)
    if (paymentInfo) {
      const order = await getOrderById(orderId)
      dispatch(setOrder(order))
    }
  } catch (err) {
    console.error('catch an error', err)
  }
}

function toProductsFormat (serverProducts) {
  return serverProducts?.map(({
    id: productId,
    name,
    price,
    option,
    productGroup,
    orderDetailItemDTO: {
      count,
      discountDTO,
      groupId,
      nameGroup,
      nameStudent,
      studentId,
      id,
      totalSum,
      discount,
      regularDiscount,
      moneyBox,
      discountStem,
      promoDiscount
    }
  }) => {
    return {
      id,
      product: {
        id: productId,
        name,
        option,
        productGroup
      },
      moneyBox,
      discountStem,
      promoDiscount,
      price,
      discount: {
        name: discountDTO?.name,
        id: discountDTO?.id
      },
      count,
      discountTotal: discount,
      regularDiscount,
      group: {
        id: groupId,
        name: nameGroup
      },
      student: {
        id: studentId,
        name: nameStudent
      },
      totalSum
    }
  })
}

function toServicesFormat (serverServices) {
  return serverServices?.map(({
    id: serviceId,
    name,
    price,
    orderDetailItemDTO: {
      count,
      discountDTO,
      groupId,
      nameGroup,
      nameStudent,
      studentId,
      id,
      totalSum,
      depositAmount,
      discount,
      regularDiscount,
      promoDiscount,
      moneyBox
    }
  }) => {
    return {
      id,
      totalSum,
      moneyBox,
      service: {
        id: serviceId,
        name
      },
      discount: {
        name: discountDTO?.name,
        id: discountDTO?.id
      },
      price: depositAmount || price,
      count,
      regularDiscount,
      promoDiscount,
      discountTotal: discount,
      group: {
        id: groupId,
        name: nameGroup
      },
      student: {
        id: studentId,
        name: nameStudent
      }
    }
  })
}
