import * as api from '../../utils/servicePrices';
import { uploadPrice } from '../../utils/servicePrices';
import { notification } from 'antd';
import i18n from 'i18n-js';
import { processExcelError } from '../../helpers/exellErrors';

export const SERVICE_PRICES_TYPES = {
  GET_SERVICE_PRICES: 'GET_SERVICE_PRICES',
  GET_SERVICES_LAST_UPDATED: 'GET_SERVICES_LAST_UPDATED',
};

export const getServicePrices = (schoolId, params = {}) => async (dispatch) => {
  try {
    const { list, total, current } = await api.getServices(schoolId, params);
    dispatch({
      type: SERVICE_PRICES_TYPES.GET_SERVICE_PRICES,
      payload: {
        list,
        total,
        current
      }
    });
  } catch (err) {
    console.error(`catch an error: ${err}`);
  }
};

export const updateServicePrice = (id, params) => async () => {
  try {
    await api.updateService(id, params);
  } catch (err) {
    console.error(`catch an error: ${err}`);
  }
};
export const createServicePrice = (schoolId, params) => async () => {
  try {
    const data = await api.createService(schoolId, params);

    return !!data;
  } catch (err) {
    console.error(`catch an error: ${err}`);
  }
};

export const getServicesLastUpdated = (schoolId) => async (dispatch) => {
  try {
    const data = await api.getServicesLastUpdated(schoolId);
    dispatch({
      type: SERVICE_PRICES_TYPES.GET_SERVICES_LAST_UPDATED,
      payload: data
    });
  } catch (err) {
    console.error(`catch an error: ${err}`);
  }
};
export const deleteService = (serviceId) => async () => {
  try {
    await api.deleteService(serviceId);
  } catch (err) {
    console.error(`catch an error: ${err}`);
  }
};

export const uploadPriceToServer = (file, schools, date) => async () => {
  try {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('schoolIds', schools);
    const data = await api.uploadPrice(formData, date);
    if (data?.data?.data?.parameters) {
      processExcelError(data.data.data);
      return false;
    }
     if (data.status === 200) {
      return true;
    }
  } catch (err) {
    console.error('catch an error', err);
  }
};