import { ACTION, ROLE_ACTION } from '../actions/types'

const initialState = {
  showSigninError: false,
  signinErrorText: '',
  showResetPswdAlert: false,
  resetPswdAlertText: '',
  currentUser: null,
  currentRole: null,
  currentSchoolId: null,
  currentLanguage: localStorage.getItem('language') || 'ua',
  schoolGroup: null,
  schoolsGroupsList: [],
  schoolsGroupsTree: null,
  schoolsWorksIn: null,
  parentTypes: [],
  communicationTypes: [],
  eventTypes: [],
  schoolGroupData: null,
  schoolsTree: null,
  currentSchoolGroupTree: []
}

export const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION.SHOW_SIGNIN_ERROR:
      return {
        ...state,
        showSigninError: action.showError
      }

    case ACTION.SET_SIGNIN_ERROR_TEXT:
      return {
        ...state,
        signinErrorText: action.text
      }

    case ACTION.SHOW_RESET_PSWD_ALERT:
      return {
        ...state,
        showResetPswdAlert: action.showAlert
      }

    case ACTION.SET_RESET_PSWD_ALERT_TEXT:
      return {
        ...state,
        resetPswdAlertText: action.text
      }

    case ACTION.SET_CURRENT_USER:
      return {
        ...state,
        currentUser: action.data
      }

    case ROLE_ACTION.SET_CURRENT_ROLE:
      return {
        ...state,
        currentRole: action.payload
      }

    case ACTION.SET_CURRENT_SCHOOL_ID:
      return {
        ...state,
        currentSchoolId: action.payload
      }

    case ACTION.SET_SCHOOL_GROUP:
      return {
        ...state,
        schoolGroup: action.payload
      }

    case ACTION.SET_SCHOOLS_WORKS_IN:
      return {
        ...state,
        schoolsWorksIn: action.payload
      }

    case ACTION.SET_PARENT_TYPES:
      return {
        ...state,
        parentTypes: action.parentTypes
      }

    case ACTION.SET_COMMUNICATION_TYPES:
      return {
        ...state,
        communicationTypes: action.communicationTypes
      }

    case ACTION.SET_EVENT_TYPES:
      return {
        ...state,
        eventTypes: action.payload
      }

    case ACTION.SET_CURRENT_LANGUAGE:
      return {
        ...state,
        currentLanguage: action.payload
      }

    case ACTION.SET_SCHOOLS_GROUP_LIST:
      return {
        ...state,
        schoolsGroupsList: action.payload
      }

    case ACTION.SET_SCHOOL_GROUPS_TREE:
      return {
        ...state,
        schoolsGroupsTree: action.payload
      }

    case ACTION.SET_CURRENT_SCHOOL_GROUP_TREE:
      return {
        ...state,
        currentSchoolGroupTree: action.payload
      }

    case ACTION.SET_CURRENT_SCHOOL_GROUP:
      return {
        ...state,
        schoolGroupData: action.payload
      }

    case ACTION.RESET_AUTH_INFO:
      return { ...state, ...initialState }

    case ACTION.SET_SCHOOLS_TREE:
      return {
        ...state,
        schoolsTree: action.payload
      }

    default:
      return state
  }
}
