import { SCHEDULE_DRAWER_ACTION } from '../actions/types';

const initialState = {
  drawerIsOpen: false,
  scheduleData: []
};

export const scheduleDrawerReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case SCHEDULE_DRAWER_ACTION.SET_SCHEDULE_DRAWER_IS_OPEN:
      return {
        ...state,
        drawerIsOpen: payload,
      };

    case SCHEDULE_DRAWER_ACTION.SET_SCHEDULE_DRAWER_DATA:
      return {
        ...state,
        scheduleData: payload,
      };

    default:
      return state;
  }
};