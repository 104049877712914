import { REMINDERS_ACTIONS } from '../actions/types'

const initialState = {
  remindersList: {},
  familyReminders: [],
  remindersLoading: false
}

export const remindersReducer = (state = initialState, action) => {
  const {
    payload,
    type
  } = action

  switch (type) {
    case REMINDERS_ACTIONS.SET_REMINDERS_LIST:
      return {
        ...state,
        remindersList: payload
      }
    case REMINDERS_ACTIONS.SET_REMINDERS_LOADING:
      return {
        ...state,
        remindersLoading: payload
      }
    case REMINDERS_ACTIONS.SET_FAMILY_REMINDERS_LIST:
      return {
        ...state,
        familyReminders: payload
      }
    default:
      return state
  }
}
