import * as api from '../../utils/finances';
import {t} from 'i18n-js';
import { notification } from 'antd';
export const FINANCES_TYPES = {
  GET_FAMILY_ORDERS: 'GET_FAMILY_ORDERS',
  GET_SCHOOL_ORDERS: 'GET_SCHOOL_ORDERS',
  CREATE_ORDER: 'CREATE_ORDER',
  SET_SCHOOL_ORDERS_FILTERS: 'SET_SCHOOL_ORDERS_FILTERS',
  GET_SCHOOL_GROUP_ID: 'GET_SCHOOL_GROUP_ID',
  GET_SCHOOL_GROUP_TEMPLATES: 'GET_SCHOOL_GROUP_TEMPLATES',
  CREATE_SCHOOL_GROUP_TEMPLATE: 'CREATE_SCHOOL_GROUP_TEMPLATE',
  UPDATE_SCHOOL_GROUP_TEMPLATE: 'UPDATE_SCHOOL_GROUP_TEMPLATE',
  DELETE_SCHOOL_GROUP_TEMPLATE: 'DELETE_SCHOOL_GROUP_TEMPLATE'
};

export const setSchoolOrdersFilters = (payload) => (dispatch) => {
  dispatch({type: FINANCES_TYPES.SET_SCHOOL_ORDERS_FILTERS, payload});
};

export const getOrdersByFamilyId = (params) => async (dispatch) => {
  try {
    const orders = await api.getOrdersByFamilyId(params);

    dispatch({
      type: FINANCES_TYPES.GET_FAMILY_ORDERS,
      payload: orders
    });
  } catch (err) {
    console.error(`catch an error ${err}`);
  }
};

export const getSchoolGroupBySchoolId = (schoolId) => async (dispatch) => {
  try {
    const group = await api.getSchoolGroupBySchoolId(schoolId);

    dispatch({
      type: FINANCES_TYPES.GET_SCHOOL_GROUP_ID,
      payload: group?.id
    });
  } catch (err) {
    console.error(`catch an error ${err}`);
  }
};

export const filterSchoolOrders = (params) => async (dispatch) => {
  try {
    const payload = await api.filterSchoolOrders(params);

    dispatch({
      type: FINANCES_TYPES.GET_SCHOOL_ORDERS,
      payload
    });
  } catch (err) {
    console.error(`catch an error ${err}`);
  }
};

export const createFamilyOrder = (schoolId, familyId, adminId) => async (dispatch) => {
  try {
    const createdOrder = await api.createFamilyOrder(schoolId, familyId, adminId);

    dispatch({
      type: FINANCES_TYPES.CREATE_ORDER,
      payload: createdOrder
    });
  } catch (err) {
    notification.error({
      message: err.message,
      duration: 3
    });
    console.error(`catch an error ${err}`);
  }
};

export const getSchoolGroupTemplates = (schoolGroupId) => async (dispatch) => {
  try {
    const list = await api.getSchoolGroupTemplates(schoolGroupId);

    dispatch({
      type: FINANCES_TYPES.GET_SCHOOL_GROUP_TEMPLATES,
      payload: list
    });
  } catch (err) {
    console.error(`catch an error ${err}`);
  }
};

export const createSchoolGroupTemplate = (schoolGroupId, data) => async (dispatch) => {
  try {
    const template = await api.createSchoolGroupTemplate(schoolGroupId, data);

    dispatch({
      type: FINANCES_TYPES.CREATE_SCHOOL_GROUP_TEMPLATE,
      payload: template
    });

    return template;
  } catch (err) {
    console.error(`catch an error ${err}`);
  }
};

export const updateSchoolGroupTemplate = (templateId, data) => async (dispatch) => {
  try {
    const template = await api.updateSchoolGroupTemplate(templateId, data);

    dispatch({
      type: FINANCES_TYPES.UPDATE_SCHOOL_GROUP_TEMPLATE,
      payload: {
        template
      }
    });

    return template;
  } catch (err) {
    console.error(`catch an error ${err}`);
  }
};

export const deleteSchoolGroupTemplate = (templateId) => async (dispatch) => {
  try {
    await api.deleteSchoolGroupTemplate(templateId);

    dispatch({
      type: FINANCES_TYPES.DELETE_SCHOOL_GROUP_TEMPLATE,
      payload: templateId
    });
  } catch (err) {
    console.error(`catch an error ${err}`);
  }
};