import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import { App } from './components/App';
import { store } from './store';
import { history } from './history';
import i18n from 'i18n-js';
import 'fullcalendar/main.css';
import './index.scss';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { fallbacks, translations, locale } from './assets/locale/locale';

i18n.fallbacks = fallbacks;
i18n.translations = translations;
i18n.locale = locale;

ReactDOM.render(
  <Provider store={store}>
    <Router history={history}>
      <App />
    </Router>
  </Provider>,
  document.getElementById('root'),
);
