import { getLeadById, updateLeadById, addLead, getLeadComments, createComment } from '../../utils/lead';
import { loadLeadsData, setSelectedLeedData, setSelectedLeedSpinner, setSpinner } from './index';
import { ACTION } from './types';
import { getFamily, getFamilySchoolGroup } from './family';
import { notification } from 'antd';
import i18n from 'i18n-js';

export const getSelectedLead = (id) => (dispatch) => {
  dispatch(setSpinner(true));
  dispatch(setSelectedLeedSpinner(true));
  getLeadById(id)
    .then((result) => {
      dispatch(setSpinner(false));
      dispatch(setSelectedLeedSpinner(false));
      if (result.data && result.data.statusCode && result.data.statusCode.code === 0) {
        dispatch(setSelectedLeedData(result.data.data));
        dispatch(setSpinner(false));
        dispatch(getFamily(result.data.data.familyId));
        dispatch(getFamilySchoolGroup(result.data.data.schoolNameTimezoneDTO.id));
      }
    })
    .catch((error) => {
      console.log('get selected lead error happened', error);
      dispatch(setSpinner(false));
      dispatch(setSelectedLeedSpinner(false));
    });
};

export const updateSelectedLead = (id, data) => (dispatch) => {
  dispatch(setSpinner(true));
  updateLeadById(id, data)
    .then((result) => {
      if (result.data && result.data.statusCode && result.data.statusCode.code === 0) {
        dispatch(setSelectedLeedData(result.data.data));
        dispatch(setSpinner(false));
      }
    })
    .catch((error) => {
      console.log('update selected lead error happened', error);
      dispatch(setSpinner(false));
    });
};

export const createLead = (data, callback = () => {}) => (dispatch, getState) => {
  dispatch(setSpinner(true));
  addLead(data)
    .then((result) => {
      if (result?.data?.statusCode?.code === 0) {
        const { optionsToLoadLeads } = getState().leeds;
        dispatch(loadLeadsData(optionsToLoadLeads));
        callback();
      }
      if (result?.data?.statusCode?.code === 3) {
        notification.error({ message: i18n.t('NewLeadForm.leadAlreadyExists'), duration: 4 });
      }
    })
    .catch((error) => {
      console.log('create new lead error happened', error);
      notification.error({ message: i18n.t('general.feedback.somethingWentWrong') });
    })
    .finally(() => dispatch(setSpinner(false)));
};

export const setTotalRecords = (records) => {
  return {
    type: ACTION.SET_TOTAL_LEED_RECORDS,
    payload: records
  };
};

export const setLeedsTableLoading = (visible) => {
  return {
    type: ACTION.SET_LEED_TABLE_LOADING,
    payload: visible
  };
};

export const setLeadComments = (comments) => ({
  type: ACTION.SET_LEAD_COMMENTS,
  payload: comments,
});

export const getSelectedLeadComments = (leadId) => (dispatch) => {
  getLeadComments(leadId)
    .then((result) => {
      if (result && result.data.statusCode && result.data.statusCode.code === 0) {
        dispatch(setLeadComments(result.data.data));
      }
    })
    .catch((error) => {
      console.log('get selected lead comments error happened', error);
    });
};

export const createLeadComment = (leadId, data) => (dispatch) => {
  createComment(data)
    .then((result) => {
      if (result && result.data.statusCode && result.data.statusCode.code === 0) {
        dispatch(getSelectedLeadComments(leadId));
      }
    })
    .catch((error) => {
      console.log('create lead comment error happened', error);
    });
};

export const setSelectedExistingParent = flag => ({
  type: ACTION.SET_SELECTED_EXISTING_PARENT,
  payload: flag
});

export const setSelectedExistingChild = flag => ({
  type: ACTION.SET_SELECTED_EXISTING_CHILD,
  payload: flag
});

export const setSelectedParent = data => ({
  type: ACTION.SET_SELECTED_PARENT,
  payload: data
});

export const setSelectedChild = data => ({
  type: ACTION.SET_SELECTED_CHILD,
  payload: data
});

export const leedParentEditModeAction = (value) => ({
  type: ACTION.LEED_PARENT_EDIT_MODE,
  payload: value
});