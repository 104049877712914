import { REPORTS_ACTIONS } from '../actions/types'

const initialState = {
  startDate: undefined,
  endDate: undefined,
  schoolIds: [],
  schoolId: undefined,
  schoolPeriodId: undefined
}

export const reportsReducer = (state = initialState, action) => {
  switch (action.type) {
    case REPORTS_ACTIONS.SET_START_DATE:
      return {
        ...state,
        startDate: action.payload
      }

    case REPORTS_ACTIONS.SET_END_DATE:
      return {
        ...state,
        endDate: action.payload
      }

    case REPORTS_ACTIONS.SET_SCHOOL_IDS:
      return {
        ...state,
        schoolIds: action.payload
      }

    case REPORTS_ACTIONS.SET_SCHOOL_ID:
      return {
        ...state,
        schoolId: action.payload
      }

    case REPORTS_ACTIONS.SET_PERIOD_ID:
      return {
        ...state,
        schoolPeriodId: action.payload
      }

    default:
      return state
  }
}
