import { FINANCES_TYPES } from '../actions/finances'
import { t } from 'i18n-js'
import { COLUMNS } from '../../components/FinancesTable/utils'
import moment from 'moment/moment'

export const defaultTemplate = {
  name: t('Finances.withoutTemplate'),
  id: '-1',
  columns: [
    COLUMNS.STATUS,
    COLUMNS.FAMILY_ID,
    COLUMNS.CREATED_TIME,
    COLUMNS.CREATED_DATE,
    COLUMNS.BILL_NUMBER,
    COLUMNS.CHILD_NAME,
    COLUMNS.GROUP_NAME,
    COLUMNS.FAMILY_FULL_NAME,
    COLUMNS.SERVICES_TOTAL_SUM,
    COLUMNS.SERVICES_DISCOUNT_SUM,
    COLUMNS.SERVICES_PIGGY_BANK,
    COLUMNS.SERVICES_CASH,
    COLUMNS.SERVICES_CARD,
    COLUMNS.SERVICES_ONLINE_PAY,
    COLUMNS.PRODUCTS_TOTAL_SUM,
    COLUMNS.PRODUCTS_DISCOUNT_SUM,
    COLUMNS.PRODUCTS_PIGGY_BANK,
    COLUMNS.PRODUCTS_CASH,
    COLUMNS.PRODUCTS_CARD,
    COLUMNS.PRODUCTS_ONLINE_PAY,
    COLUMNS.TOTAL,
    COLUMNS.STEMS,
    COLUMNS.ADMIN_FULL_NAME,
    COLUMNS.TO_PIGGY_BANK
  ]
}

const initialState = {
  familyOrders: [],
  schoolOrders: [],
  schoolPagination: {
    total: null,
    current: 1
  },
  schoolOrdersFilters: {
    page: 0,
    size: 10,
    createDateFrom: moment().format('YYYY-MM-DD'),
    createDateTo: moment().format('YYYY-MM-DD')
  },
  templates: [defaultTemplate],
  template: undefined,
  schoolGroupId: null
}

export const financesReducer = (state = initialState, action) => {
  const {
    payload,
    type
  } = action

  switch (type) {
    case FINANCES_TYPES.GET_FAMILY_ORDERS:
      return {
        ...state,
        familyOrders: payload
      }
    case FINANCES_TYPES.GET_SCHOOL_ORDERS:
      return {
        ...state,
        schoolOrders: payload.list,
        schoolPagination: {
          total: payload.total
        }
      }
    case FINANCES_TYPES.CREATE_ORDER:
      return {
        ...state,
        familyOrders: [payload, ...state.familyOrders]
      }
    case FINANCES_TYPES.SET_SCHOOL_ORDERS_FILTERS:
      return {
        ...state,
        schoolOrdersFilters: payload
      }
    case FINANCES_TYPES.GET_SCHOOL_GROUP_ID:
      return {
        ...state,
        schoolGroupId: payload
      }
    case FINANCES_TYPES.GET_SCHOOL_GROUP_TEMPLATES:
      return {
        ...state,
        templates: [defaultTemplate, ...payload]
      }
    case FINANCES_TYPES.CREATE_SCHOOL_GROUP_TEMPLATE:
      return {
        ...state,
        templates: [...state.templates, payload]
      }
    case FINANCES_TYPES.UPDATE_SCHOOL_GROUP_TEMPLATE:
      return {
        ...state,
        templates: state.templates?.map(template => {
          if (template.id === payload.template?.id) {
            return payload.template
          }

          return template
        })
      }
    case FINANCES_TYPES.DELETE_SCHOOL_GROUP_TEMPLATE:
      return {
        ...state,
        templates: state.templates?.filter(template => template.id !== payload)
      }

    default:
      return state
  }
}
