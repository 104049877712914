import { ACTION } from '../actions/types'
import { LS_VALUES } from '../../constants/text'

const initialState = {
  employeesPaginationConfig: {
    current: 1,
    pageSize: 10,
    total: 10
  },
  employeesFilters: {
    id: null,
    active: ['true']
  },
  phoneMask: null,
  employeeInfo: [],
  employeesData: []
}

export const employeesReducer = (state = initialState, action) => {
  const { payload, type } = action

  switch (type) {
    case ACTION.SET_EMPLOYEES:
      const employees = payload.map((item) => {
        return { ...item, fullName: item.firstName + ' ' + item.middleName + ' ' + item.lastName }
      })
      return {
        ...state,
        employeesData: employees
      }
    case ACTION.REMOVE_EMPLOYEE:
      const filteredEmployees = state.employeesData.filter(
        (item) => !payload.some((element) => item.id === element.id)
      )
      return {
        ...state,
        employeesData: filteredEmployees
      }
    case ACTION.SET_EMPLOYEES_PAGINATION_CONFIG_TOTAL:
      return {
        ...state,
        employeesPaginationConfig: {
          ...state.employeesPaginationConfig,
          total: payload
        }
      }
    case ACTION.SET_EMPLOYEES_PAGINATION_CONFIG:
      return {
        ...state,
        employeesPaginationConfig: payload
      }
    case ACTION.SET_EMPLOYEES_FILTERS:
      return {
        ...state,
        employeesFilters: payload
      }
    case ACTION.SET_EMPLOYEE_INFO:
      return {
        ...state,
        employeeInfo: payload
      }
    case ACTION.SET_PHONE_MASK_FOR_EMPLOYEE:
      return {
        ...state,
        phoneMask: payload
      }
    default:
      return state
  }
}
