import { ACTION } from '../actions/types';

const initialState = {
  isLoading: false,
  detailInfoIsOpen: false,
  teacherFilter: [],
  courseFilter: [],
  classroomFilter: [],
  schoolFilter: null,
  schoolsFilter: [],
  currentFilter: 'COURSE_FILTER',
  freeClassroomFilter: false,
  freeClassroomFilters: {
    time: null,
    classroomIds: []
  },
  freeClassrooms: [],
  teachers: [],
  courses: [],
  classrooms: [],
  schoolGroups: [],
  currentDate: new Date(Date.now()),
  calendarType: 'week',
  isShortCardForm: false,
  events: [],
  freeRoomsEvents: [],
  schoolClassrooms: [],
  groupLessons: [],
};

export const scheduleReducer = (state = initialState, action) => {
  const { payload, type } = action;

  switch (type) {
    case ACTION.SET_SCHEDULE_TEACHER_FILTER:
      return {
        ...state,
        teacherFilter: payload,
      };

    case ACTION.SET_SCHEDULE_DETAIL_INFO_IS_OPEN:
      return {
        ...state,
        detailInfoIsOpen: payload,
      };

    case ACTION.SET_SCHEDULE_COURSE_FILTER:
      return {
        ...state,
        courseFilter: payload,
      };

    case ACTION.SET_SCHEDULE_SCHOOL_GROUP_LIST:
      return {
        ...state,
        schoolGroups: payload,
      };

    case ACTION.SET_SCHEDULE_FREE_CLASSROOM_FILTER:
      return {
        ...state,
        freeClassroomFilter: payload,
      };

    case ACTION.SET_SCHEDULE_SCHOOLS_FILTER:
      return {
        ...state,
        schoolsFilter: payload,
      };

    case ACTION.SET_SCHEDULE_SCHOOL_FILTER:
      return {
        ...state,
        schoolFilter: payload,
      };

    case ACTION.SET_SCHEDULE_CLASSROOM_FILTER:
      return {
        ...state,
        classroomFilter: payload,
      };

    case ACTION.SET_SCHEDULE_CURRENT_FILTER:
      return {
        ...state,
        currentFilter: payload,
      };

    case ACTION.SET_SCHEDULE_TEACHER_LIST:
      return {
        ...state,
        teachers: payload,
      };

    case ACTION.SET_SCHEDULE_COURSES_LIST:
      return {
        ...state,
        courses: payload,
      };

    case ACTION.SET_SCHEDULE_CLASSROOM_LIST:
      return {
        ...state,
        classrooms: payload,
      };

    case ACTION.SET_SCHEDULE_FREE_CLASSROOM_FILTERS:
      return {
        ...state,
        freeClassroomFilters: {
          ...state.freeClassroomFilters,
          [payload.field]: payload.value,
        },
      };

    case ACTION.SET_SCHOOL_CLASSROOMS:
      return {
        ...state,
        freeClassrooms: payload,
      };

    case ACTION.SET_LOADING_SCHEDULE:
      return {
        ...state,
        isLoading: payload,
      };

    case ACTION.SET_SCHEDULE_EVENTS:
      return {
        ...state,
        events: payload,
      };

    case ACTION.REMOVE_SCHEDULE_EVENTS:
      return {
        ...state,
        events: state.events.filter(({ lessonId }) => lessonId !== payload),
      };

    case ACTION.SET_SCHEDULE_ROOM_EVENTS:
      return {
        ...state,
        freeRoomsEvents: payload,
      };

    case ACTION.SET_SCHEDULE_WEEK_CALENDAR_FORM:
      return {
        ...state,
        isShortCardForm: payload,
      };

    case ACTION.SET_SCHEDULE_DATE:
      return {
        ...state,
        currentDate: payload,
      };

    case ACTION.SET_SCHEDULE_FREE_CLASSROOMS:
      return {
        ...state,
        freeClassrooms: payload,
      };

    case ACTION.SET_SCHEDULE_CALENDAR_TYPE:
      return {
        ...state,
        calendarType: payload,
      };
    case ACTION.SET_CURRENT_SCHOOL_CLASSROOMS:
      return {
        ...state,
        schoolClassrooms: payload,
      };
    case ACTION.SET_GROUP_LESSONS:
      return {
        ...state,
        groupLessons: payload,
      };

    default:
      return state;
  }
};
