import * as api from '../../utils/productPrices';
import { processExcelError } from '../../helpers/exellErrors';
import moment from 'moment';

export const PRODUCT_PRICES_TYPES = {
  GET_PRODUCTS: 'GET_PRODUCTS',
  // UPDATE_PRODUCT: 'UPDATE_PRODUCT',
  // CREATE_PRODUCT: 'CREATE_PRODUCT',
  GET_PRICE_LINKS: 'GET_PRICE_LINKS',
  GET_ACTIVE_DISCOUNTS: 'GET_ACTIVE_DISCOUNTS',
  UPDATE_ACTIVE_DISCOUNT: 'UPDATE_ACTIVE_DISCOUNT',
  GET_PRODUCTS_LAST_UPDATED: 'GET_PRODUCTS_LAST_UPDATED',
  // DELETE_PRODUCT: 'DELETE_PRODUCT',
};

export const getProducts = (schoolId, params = {}) => async (dispatch) => {
  try {
    const { list, total, current } = await api.getProducts(schoolId, params);

    dispatch({
      type: PRODUCT_PRICES_TYPES.GET_PRODUCTS,
      payload: {
        list,
        total,
        current
      }
    });
  } catch (err) {
    console.error('catch en error: ', err);
  }
};

export const updateProduct = (id, params) => async (dispatch, getState) => {
  try {
    const newProduct = await api.updateProduct(id, params);
    // const { productPrices: { products } } = getState();

    // const payload = products?.map(p => {
    //   if (p.id === newProduct.id) {
    //     return newProduct;
    //   }
    //   return p;
    // });
    // dispatch({
    //   type: PRODUCT_PRICES_TYPES.UPDATE_PRODUCT,
    //   payload
    // });
  } catch (err) {
    console.error('catch en error: ', err);
  }
};

export const createProduct = (schoolId, params) => async (dispatch, getStore) => {
  try {
    const product = await api.createProduct(schoolId, params);
    if (product) {
      // const {productPrices: {products}} = getStore();
      // dispatch({
      //   type: PRODUCT_PRICES_TYPES.CREATE_PRODUCT,
      //   payload: [...products, product]
      // });

      return true;
    }
    return false;
  } catch (err) {
    console.error('catch en error: ', err);
  }
};

export const deleteProduct = (productId) => async (dispatch, getStore) => {
  try {
    const deleted = await api.deleteProduct(productId);
    // console.log({deleted});
    //
    // if (deleted) {
    //   const {productPrices: {products}} = getStore();
    //   dispatch({
    //     type: PRODUCT_PRICES_TYPES.DELETE_PRODUCT,
    //     payload: products?.filter(p => p.id !== productId)
    //   });
    // }
  } catch (err) {
    console.error('catch en error: ', err);
  }
};

export const getPriceLinks = () => async (dispatch) => {
  try {
    const payload = await api.getPriceLinks();

    if (payload) {
      dispatch({
        type: PRODUCT_PRICES_TYPES.GET_PRICE_LINKS,
        payload
      });

      return true;
    }
    return false;
  } catch (err) {
    console.error('catch en error: ', err);
  }
};

export const getActiveDiscounts = (schoolId) => async (dispatch) => {
  try {
    const activeDiscounts = await api.getActiveDiscounts(schoolId);
    console.log({activeDiscounts});

    dispatch({
      type: PRODUCT_PRICES_TYPES.GET_ACTIVE_DISCOUNTS,
      payload: activeDiscounts
    });
  } catch (err) {
    console.error('catch en error: ', err);
  }
};

export const updateActiveDiscount = (activeDiscount, value) => async (dispatch, getState) => {
  try {
    const {productPrices: {activeDiscounts}} = getState();
    const updatedActiveDiscount = await api.updateActiveDiscount(activeDiscount, value);

    const payload = activeDiscounts?.map(discount => {
      if (discount.id === updatedActiveDiscount.id) {
        return updatedActiveDiscount;
      }
      return discount;
    });

    dispatch({
      type: PRODUCT_PRICES_TYPES.UPDATE_ACTIVE_DISCOUNT,
      payload
    });
  } catch (err) {
    console.error('catch en error: ', err);
  }
};
export const activeDiscountAction = (schoolId, product, discount, value) => async (dispatch, getState) => {
  try {
    // check exists priceLink by productId and discountId
    const priceLinks = await api.getPriceLinks();
    const existsLink = priceLinks?.find(priceLink => {
      return priceLink?.discountId?.id === discount.id && priceLink?.productId?.id === product.id;
    });

    if (!value && existsLink) {
      const deleted = await api.deletePriceLink(existsLink.id);
    } else {
      const priceLink = {
        discountId: discount,
        productId: product,
        price: value
      };
      if (existsLink) {
        const updated = await api.updatePriceLink(existsLink.id, priceLink);
      } else {
        // create
        const created = await api.createPriceLink(priceLink);
      }
    }
    return false;
  } catch (err) {
    console.error('catch en error: ', err);
  }
};

export const getProductsLastUpdated = (schoolId) => async (dispatch) => {
  try {
    const data = await api.getProductsLastUpdated(schoolId);
    dispatch({
      type: PRODUCT_PRICES_TYPES.GET_PRODUCTS_LAST_UPDATED,
      payload: data
    });
  } catch (err) {
    console.error(`catch an error: ${err}`);
  }
};

export const uploadExcelFile = (file, schoolId, date) => async (dispatch) => {
  try {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('schoolIds', schoolId);
    const data = await api.uploadExcelFile(formData, moment(date).format('YYYY-MM-DD'));
    if (data?.data?.data?.parameters) {
      processExcelError(data.data.data);
      return false;
    }
    if (data.status === 200) {
      return true;
    }
  } catch (err) {
    console.error('catch an error', err);
  }
};
