import { combineReducers } from 'redux'
import { authReducer } from './authReducer'
import { layoutReducer } from './layoutReducer'
import { profileReducer } from './profileReducer'
import { leedsReducer } from './leedsReducer'
import { modalReducer } from './modalReducer'
import { scheduleReducer } from './scheduleReducer'
import { clientsReducer } from './clientsReducer'
import { familyReducer } from './familyReducer'
import { distributionReducer } from './distributionReducer'
import { scheduleDrawerReducer } from './scheduleDrawerReducer'
import { tasksReducer } from './tasksReducer'
import { STEMReducer } from './STEMReducer'
import { albumsReducer } from './albumsReducer'
import { formReducer } from './formReducer'
import { spinnersReducer } from './spinnersReducer'
import { selectedPersonReducer } from './selectedPersonReducer'
import { groupReducer } from './groupReducer'
import { customFieldsReducer } from './customFields'
import { teacherScheduleReducer } from './teacherCalendarReducer'
import { widgetCallReducer } from './widgetCall'
import { cartReducer } from './cart'
import { promoCodesReducer } from './promoCodesReducer'
import { discountsReducer } from './discountsReducer'
import { productPricesReducer } from './productPricesReducer'
import { suppliersReducer } from './suppliersReducer'
import { servicePricesReducer } from './ServicePricesReducer'
import { paymentReducer } from './paymentReducer'
import { billSettingsReducer } from './billSettingsReducer'
import { financesReducer } from './financesReducer'
import { callsJournalReducer } from './callsJournalReducer'
import { eventReducer } from './eventsReducer'
import { generalSettingsReducer } from './generalSettingsReducer'
import { employeesReducer } from './employeesReducer'
import { teacherReducer } from './teacherReducer'
import { remindersReducer } from './remindersReducer'
import { reportsReducer } from './reportsReducer'

export const rootReducer = combineReducers({
  auth: authReducer,
  layout: layoutReducer,
  profile: profileReducer,
  leeds: leedsReducer,
  modal: modalReducer,
  schedule: scheduleReducer,
  clients: clientsReducer,
  family: familyReducer,
  distribution: distributionReducer,
  scheduleDrawer: scheduleDrawerReducer,
  tasks: tasksReducer,
  STEM: STEMReducer,
  albums: albumsReducer,
  form: formReducer,
  spinners: spinnersReducer,
  selectedPerson: selectedPersonReducer,
  group: groupReducer,
  customFields: customFieldsReducer,
  teacherSchedule: teacherScheduleReducer,
  widgetCall: widgetCallReducer,
  cart: cartReducer,
  promoCodes: promoCodesReducer,
  discounts: discountsReducer,
  productPrices: productPricesReducer,
  servicePrices: servicePricesReducer,
  suppliers: suppliersReducer,
  payment: paymentReducer,
  billSettings: billSettingsReducer,
  finances: financesReducer,
  callsJournal: callsJournalReducer,
  events: eventReducer,
  generalSettings: generalSettingsReducer,
  employees: employeesReducer,
  teacher: teacherReducer,
  reminders: remindersReducer,
  reports: reportsReducer
})
