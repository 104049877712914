import { TASKS_ACTION } from '../actions/types';
import i18n from 'i18n-js';

const listTasksData = [
  {key: 1, name: 'name1', school: 'school1', deadline: new Date('2020-10-10T20:00:00'), producer: 'producer1', executor: 'executor1'},
  {key: 2, name: 'name2', school: 'school2', deadline: new Date('2020-10-19T20:00:00'), producer: 'producer2', executor: 'executor2'},
  {key: 3, name: 'name3', school: 'school3', deadline: new Date('2020-10-10T10:00:00'), producer: 'producer3', executor: 'executor3'},
  {key: 4, name: 'name4', school: 'school4', deadline: new Date('2020-12-10T10:00:00'), producer: 'producer3', executor: 'executor4'}
];

const tasksEvents = [
  {id: 0, name: 'name 1', description: 'Выглядит как таблица с 2 колонками и 13 строками. Первая колонка содержит показатель времени - часы и минуты. Во второй колонке название и описание задачи. Каждая строка - временной отрезок в 1 час. Первая строка соответствует времени  08:00 утра, последняя - 20:00 вечера.', start: '2020-10-24T10:00:00', end: '2020-10-24T14:00:00'},
  {id: 1, name: 'name 2', description: 'Выглядит как таблица с 2 колонками и 13 строками. Первая колонка содержит показатель времени - часы и минуты. Во второй колонке название и описание задачи. Каждая строка - временной отрезок в 1 час. Первая строка соответствует времени  08:00 утра, последняя - 20:00 вечера.', start: '2020-10-24T10:00:00', end: '2020-10-24T16:00:00'},
];

const initialState = {
  chartsData: {
    statusChart: { labels: [i18n.t('TasksCharts.new'), i18n.t('TasksCharts.inProgress'), i18n.t('TasksCharts.completed'), i18n.t('TasksCharts.expired')], data: [6, 1, 2, 1] },
    segmentChart: { labels: ['Сегмент 1', 'Сегмент 2', 'Сегмент 3', 'Сегмент 4', 'Сегмент 5'], data: [[50, 25, 12, 48, 90], [21, 84, 24, 75, 37], [41, 52, 24, 74, 23], [41, 52, 24, 74, 23]] },
    priorityChart: { labels: [i18n.t('TasksCharts.priorities.urgently'), i18n.t('TasksCharts.priorities.importantly'), i18n.t('TasksCharts.priorities.medium'), i18n.t('TasksCharts.priorities.low')], data: [[50, 25, 12, 48], [21, 84, 24, 75], [41, 52, 24, 74], [41, 52, 24, 74]] },
    participantChart: { labels: ['user1', 'user2', 'user3', 'user4', 'user5'], data: [[50, 25, 12, 48, 90], [21, 84, 24, 75, 37], [41, 52, 24, 74, 23], [41, 52, 24, 74, 23]] }
  },
  tasksEvents,
  calendarCurrentDate: new Date(Date.now()),
  taskCalendarType: 'month',
  listTasksData,
  selectedTasks: [],
  board: {
    columns: []
  },
  kanbanFilter: 'SEGMENT',
  task: {
    name: 'Remind of the first lesson',
    description: 'Description',
    authorId: null,
    regular: true,
    period: null,
    executionStatus: null,
    priority: 1,
    rowNumber: 1,
    segmentId: null,
    taskType: 'CUSTOM',
    autoTaskConfig: null,
    saved: false,
    schoolId: 1,
    reasonIrrelevance: null,
    clientIds: [],
    clientsTasks: [
      {
        name: 'Name',
        id: 1
      }
    ],
    executorsTasks: [
      {
        userId: null,
        reminderPeriod: null,
        firstReminder: null
      }
    ],
    checklists: [
      {
        id: 1,
        name: 'Name 1',
        list: [
          {
            name: 'Name 2',
            id: 2,
            checked: false
          },
          {
            name: 'Name 3',
            id: 3,
            checked: true
          }
        ]
      }
    ],
    startDate: null,
    deadline: null,
    links: [
      {
        url: 'http://google.com',
        name: 'google.com',
        id: 0
      }
    ]
  },
  priorityList: [
    {name: 'LOW', value: 3},
    {name: 'MEDIUM', value: 2},
    {name: 'HEIGHT', value: 1},
  ],
  kanbanFilterList: [
    {name: i18n.t('Tasks.segment'), value: 'SEGMENT'},
    {name: i18n.t('Tasks.status'), value: 'STATUS'},
    {name: i18n.t('Tasks.priority'), value: 'PRIORITY'},
    {name: i18n.t('Tasks.date'), value: 'DATE'},
    {name: i18n.t('Tasks.executive'), value: 'EXECUTIVE'},
  ]
};

export const tasksReducer = (state = initialState, action) => {
  const { payload, type } = action;

  switch (type) {
    case TASKS_ACTION.SET_TASKS_KANBAN_BOARD:
      return {
        ...state,
        board: payload,
      };

    case TASKS_ACTION.SET_TASK:
      return {
        ...state,
        task: payload,
      };

    case TASKS_ACTION.SET_TASK_KANBAN_FILTER:
      return {
        ...state,
        kanbanFilter: payload,
      };

    case TASKS_ACTION.SET_SELECTED_TASKS:
      return {
        ...state,
        selectedTasks: payload,
      };

    case TASKS_ACTION.UPDATE_TASKS_KANBAN_BOARD:
      return {
        ...state,
        board: {
          columns: payload
        },
      };

    case TASKS_ACTION.SET_TASKS_CALENDAR_TYPE:
      return {
        ...state,
        taskCalendarType: payload,
      };

    case TASKS_ACTION.SET_TASKS_CALENDAR_DATE:
      return {
        ...state,
        calendarCurrentDate: payload,
      };

    default:
      return state;
  }
};