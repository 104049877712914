import { DISTRIBUTION_ACTION } from '../actions/types';
import { DISTRIBUTION_CHANNEL } from '../../constants/text';

const SELECTED_TEMPLATE_INIT_VALUE = {
  [DISTRIBUTION_CHANNEL.SMS]: [],
  [DISTRIBUTION_CHANNEL.VIBER]: [],
  [DISTRIBUTION_CHANNEL.EMAIL]: [],
};

export const initialDistributionFilters = {
  promocode: null,
  clientsNewClients: [],
  schools: [],
  courses: [],
  groups: [],
  clientCreatedAt: {
    from: null,
    to: null,
  },
  newClientCreatedAt: {
    from: null,
    to: null,
  },
  ageFrom: null,
  ageTo: null,
  kidsCount: [],
  adults: [],
  adultsStatuses: [],
  leadsStatuses: [],
  inCourseStudentsStatuses: [],
  inGroupStudentsStatuses: [],
  inCourseStudentsStatusesUpdatedAt: {
    from: null,
    to: null,
  },
  inGroupStudentsStatusesUpdatedAt: {
    from: null,
    to: null,
  },
  inCourseStudentInStatusAt: {
    from: null,
    to: null,
  },
  inGroupStudentInStatusAt: {
    from: null,
    to: null,
  },
  parentInStatusAt: {
    from: null,
    to: null,
  },
  mainTablePagination: {
    current: 1,
  },
};

const initialState = {
  filters: initialDistributionFilters,
  channel: DISTRIBUTION_CHANNEL.EMAIL,
  selectedClients: [],
  isTemplateSelectorOpen: false,
  isEmailEditorOpen: false,
  selectedTemplate: SELECTED_TEMPLATE_INIT_VALUE,
  availableCourses: [],
  availableGroups: [],
  isClientsSpinnerVisible: false,
  clients: [],
  isSmsEditorOpen: false,
  isViberEditorOpen: false,
  isNewTemplatePopupOpen: false,
  isRewriteTemplatePopupOpen: false,
  isConfirmDistributionPopupOpen: false,
  isCreateDistributionPopupOpen: false,
  isScheduled: false,
  scheduleDateTime: {
    date: null,
    time: null,
  },
  isChannelSelectorOpen: false,
  channelsWithPriority: [],
  sourceChannels: [DISTRIBUTION_CHANNEL.EMAIL, DISTRIBUTION_CHANNEL.VIBER, DISTRIBUTION_CHANNEL.SMS],
  isReportsSpinnerVisible: false,
  reportsPeriod: {
    from: null,
    to: null,
  },
  showAllReports: false,
  isReportDetailsOpen: false,
  availableTemplates: {
    [DISTRIBUTION_CHANNEL.SMS]: [],
    [DISTRIBUTION_CHANNEL.VIBER]: [],
    [DISTRIBUTION_CHANNEL.EMAIL]: [],
  },
  isTemplatesLoading: false,
  appliedTemplates: {
    [DISTRIBUTION_CHANNEL.SMS]: null,
    [DISTRIBUTION_CHANNEL.VIBER]: null,
    [DISTRIBUTION_CHANNEL.EMAIL]: null,
  },
  isEditTemplateMode: false,
  isEditTemplatePopupOpen: false,
  templateToEdit: null,
  mainTablePagination: {
    current: 1,
    pageSize: 10,
    total: 10,
    hideOnSinglePage: true,
    showSizeChanger: false,
  },
  mainTableFilters: {
    name: null,
    status: null,
    author: null,
  },
  mainTableSorters: {},
  selectedDistributions: [],
  handSelectedCustomers: [],
  selectedHandSelectedCustomers: [],
  distributionDetails: null,
  allDistributions: [],
  distributionSettingsWereEdited: false,
  distributionsPeriod: {
    from: null,
    to: null,
  },
  addRecipientsToDistribution: {
    isOpen: false,
    recipientsIds: [],
  },
  reportMailingId: null,
  report: null,
  filteredClients: [],
  manualFilteredClients: [],
};

export const distributionReducer = (state = initialState, action) => {
  switch (action.type) {
    case DISTRIBUTION_ACTION.SET_CLIENTS_NEW_CLIENTS_FILTER:
      return {
        ...state,
        filters: {
          ...state.filters,
          clientsNewClients: action.clientsNewClients,
        },
      };
    case DISTRIBUTION_ACTION.CHANGE_PROMOCODE:
      return {
        ...state,
        filters: {
          ...state.filters,
          promocode: action.payload || null
        }
      };

    case DISTRIBUTION_ACTION.SET_SCHOOLS_FILTER:
      return {
        ...state,
        filters: {
          ...state.filters,
          schools: action.schools,
        },
      };

    case DISTRIBUTION_ACTION.SET_COURSES_FILTER:
      return {
        ...state,
        filters: {
          ...state.filters,
          courses: action.courses,
        },
      };

    case DISTRIBUTION_ACTION.SET_GROUPS_FILTER:
      return {
        ...state,
        filters: {
          ...state.filters,
          groups: action.groups,
        },
      };

    case DISTRIBUTION_ACTION.SET_CLIENT_CREATED_AT_FILTER:
      return {
        ...state,
        filters: {
          ...state.filters,
          clientCreatedAt: {
            ...action.createdAt,
          },
        },
      };

    case DISTRIBUTION_ACTION.SET_NEW_CLIENT_CREATED_AT_FILTER:
      return {
        ...state,
        filters: {
          ...state.filters,
          newClientCreatedAt: {
            ...action.createdAt,
          },
        },
      };

    case DISTRIBUTION_ACTION.SET_AGE_FROM_FILTER:
      return {
        ...state,
        filters: {
          ...state.filters,
          ageFrom: action.ageFrom,
        },
      };

    case DISTRIBUTION_ACTION.SET_AGE_TO_FILTER:
      return {
        ...state,
        filters: {
          ...state.filters,
          ageTo: action.ageTo,
        },
      };

    case DISTRIBUTION_ACTION.SET_KIDS_COUNT_FILTER:
      return {
        ...state,
        filters: {
          ...state.filters,
          kidsCount: action.kidsCount,
        },
      };

    case DISTRIBUTION_ACTION.SET_ADULTS_FILTER:
      return {
        ...state,
        filters: {
          ...state.filters,
          adults: action.adults,
        },
      };

    case DISTRIBUTION_ACTION.SET_ADULTS_STATUSES_FILTER:
      return {
        ...state,
        filters: {
          ...state.filters,
          adultsStatuses: action.statuses,
        },
      };

    case DISTRIBUTION_ACTION.SET_LEADS_STATUSES_FILTER:
      return {
        ...state,
        filters: {
          ...state.filters,
          leadsStatuses: action.statuses,
        },
      };

    case DISTRIBUTION_ACTION.SET_IN_COURSE_STUDENTS_STATUSES_FILTER:
      return {
        ...state,
        filters: {
          ...state.filters,
          inCourseStudentsStatuses: action.statuses,
        },
      };

    case DISTRIBUTION_ACTION.SET_IN_GROUP_STUDENTS_STATUSES_FILTER:
      return {
        ...state,
        filters: {
          ...state.filters,
          inGroupStudentsStatuses: action.statuses,
        },
      };

    case DISTRIBUTION_ACTION.SET_IN_COURSE_STUDENTS_STATUSES_UPDATED_AT_FILTER:
      return {
        ...state,
        filters: {
          ...state.filters,
          inCourseStudentsStatusesUpdatedAt: {
            ...action.updatedAt,
          },
        },
      };

    case DISTRIBUTION_ACTION.SET_IN_GROUP_STUDENTS_STATUSES_UPDATED_AT_FILTER:
      return {
        ...state,
        filters: {
          ...state.filters,
          inGroupStudentsStatusesUpdatedAt: {
            ...action.updatedAt,
          },
        },
      };

    case DISTRIBUTION_ACTION.SET_IN_COURSE_STUDENT_IN_STATUS_AT_FILTER:
      return {
        ...state,
        filters: {
          ...state.filters,
          inCourseStudentInStatusAt: {
            ...action.inStatusAt,
          },
        },
      };

    case DISTRIBUTION_ACTION.SET_IN_GROUP_STUDENT_IN_STATUS_AT_FILTER:
      return {
        ...state,
        filters: {
          ...state.filters,
          inGroupStudentInStatusAt: {
            ...action.inStatusAt,
          },
        },
      };

    case DISTRIBUTION_ACTION.SET_PARENT_IN_STATUS_AT_FILTER:
      return {
        ...state,
        filters: {
          ...state.filters,
          parentInStatusAt: {
            ...action.inStatusAt,
          },
        },
      };
    case DISTRIBUTION_ACTION.SET_DISTRIBUTION_FILTERS:
      return {
        ...state,
        filters: action.payload
      };

    case DISTRIBUTION_ACTION.SET_CHANNEL:
      return {
        ...state,
        channel: action.channel,
      };

    case DISTRIBUTION_ACTION.SET_SELECTED_CLIENTS:
      return {
        ...state,
        selectedClients: action.selectedClients,
      };

    case DISTRIBUTION_ACTION.SET_HAND_SELECTED_CUSTOMERS:
      return {
        ...state,
        handSelectedCustomers: action.customers,
      };

    case DISTRIBUTION_ACTION.ADD_HAND_SELECTED_CUSTOMERS:
      return {
        ...state,
        handSelectedCustomers: [...state.handSelectedCustomers, ...action.customers],
        // selectedHandSelectedCustomers: [
        //   ...state.selectedHandSelectedCustomers,
        //   ...action.customers.map((customer) => customer.id.toString()),
        // ],
      };

    case DISTRIBUTION_ACTION.SET_SELECTED_HAND_SELECTED_CUSTOMERS:
      return {
        ...state,
        selectedHandSelectedCustomers: action.selectedCustomers,
      };

    case DISTRIBUTION_ACTION.SET_IS_TEMPLATE_SELECTOR_OPEN:
      return {
        ...state,
        isTemplateSelectorOpen: action.isOpen,
      };

    case DISTRIBUTION_ACTION.SET_IS_EMAIL_EDITOR_OPEN:
      return {
        ...state,
        isEmailEditorOpen: action.isOpen,
      };

    case DISTRIBUTION_ACTION.SET_SELECTED_TEMPLATE:
      return {
        ...state,
        selectedTemplate: {
          ...state.selectedTemplate,
          [action.channel]: action.templateId,
        },
      };

    case DISTRIBUTION_ACTION.RESET_SELECTED_TEMPLATES:
      return {
        ...state,
        selectedTemplate: SELECTED_TEMPLATE_INIT_VALUE,
      };

    case DISTRIBUTION_ACTION.SET_AVAILABLE_COURSES:
      return {
        ...state,
        availableCourses: action.courses,
      };

    case DISTRIBUTION_ACTION.SET_AVAILABLE_GROUPS:
      return {
        ...state,
        availableGroups: action.groups,
      };

    case DISTRIBUTION_ACTION.SET_IS_CLIENTS_SPINNER_VISIBLE:
      return {
        ...state,
        isClientsSpinnerVisible: action.isVisible,
      };

    case DISTRIBUTION_ACTION.SET_CLIENTS:
      return {
        ...state,
        // clients: getUniqueObjects([...state.clients, ...action.clients], 'id'),
        clients: action.clients,
      };

    case DISTRIBUTION_ACTION.SET_IS_SMS_EDITOR_OPEN:
      return {
        ...state,
        isSmsEditorOpen: action.isOpen,
      };

    case DISTRIBUTION_ACTION.SET_IS_VIBER_EDITOR_OPEN:
      return {
        ...state,
        isViberEditorOpen: action.isOpen,
      };

    case DISTRIBUTION_ACTION.SET_IS_NEW_TEMPLATE_POPUP_OPEN:
      return {
        ...state,
        isNewTemplatePopupOpen: action.isOpen,
      };

    case DISTRIBUTION_ACTION.SET_IS_REWRITE_TEMPLATE_POPUP_OPEN:
      return {
        ...state,
        isRewriteTemplatePopupOpen: action.isOpen,
      };

    case DISTRIBUTION_ACTION.SET_IS_CONFIRM_DISTRIBUTION_POPUP_OPEN:
      return {
        ...state,
        isConfirmDistributionPopupOpen: action.isOpen,
      };

    case DISTRIBUTION_ACTION.SET_IS_CREATE_DISTRIBUTION_POPUP_OPEN:
      return {
        ...state,
        isCreateDistributionPopupOpen: action.isOpen,
      };

    case DISTRIBUTION_ACTION.SET_SCHEDULE:
      return {
        ...state,
        isScheduled: action.isScheduled,
      };

    case DISTRIBUTION_ACTION.SET_DISTRIBUTION_SCHEDULE_DATE:
      return {
        ...state,
        scheduleDateTime: {
          ...state.scheduleDateTime,
          date: action.date,
        },
      };

    case DISTRIBUTION_ACTION.SET_SCHEDULE_TIME:
      return {
        ...state,
        scheduleDateTime: {
          ...state.scheduleDateTime,
          time: action.time,
        },
      };

    case DISTRIBUTION_ACTION.SET_IS_CHANNEL_SELECTOR_OPEN:
      return {
        ...state,
        isChannelSelectorOpen: action.isOpen,
      };

    case DISTRIBUTION_ACTION.SET_CHANNELS_WITH_PRIORITY:
      return {
        ...state,
        channelsWithPriority: action.channels,
      };

    case DISTRIBUTION_ACTION.SET_SOURCE_CHANNELS:
      return {
        ...state,
        sourceChannels: action.channels,
      };

    case DISTRIBUTION_ACTION.SET_IS_REPORTS_SPINNER_VISIBLE:
      return {
        ...state,
        isReportsSpinnerVisible: action.isVisible,
      };

    case DISTRIBUTION_ACTION.SET_REPORTS_PERIOD_FILTER:
      return {
        ...state,
        reportsPeriod: action.reportsPeriod,
      };

    case DISTRIBUTION_ACTION.SET_SHOW_ALL_REPORTS:
      return {
        ...state,
        showAllReports: action.show,
      };

    case DISTRIBUTION_ACTION.SET_IS_REPORT_DETAILS_OPEN:
      return {
        ...state,
        isReportDetailsOpen: action.isOpen,
      };

    case DISTRIBUTION_ACTION.SET_AVAILABLE_TEMPLATES:
      return {
        ...state,
        availableTemplates: action.templates,
      };

    case DISTRIBUTION_ACTION.SET_IS_TEMPLATES_LOADING:
      return {
        ...state,
        isTemplatesLoading: action.isLoading,
      };

    case DISTRIBUTION_ACTION.SET_APPLIED_TEMPLATE:
      return {
        ...state,
        appliedTemplates: {
          ...state.appliedTemplates,
          [action.template.type]: action.template,
        },
      };

    case DISTRIBUTION_ACTION.DISCARD_APPLIED_TEMPLATE:
      return {
        ...state,
        appliedTemplates: {
          ...state.appliedTemplates,
          [action.channel]: null,
        },
      };

    case DISTRIBUTION_ACTION.DELETE_FROM_APPLIED_TEMPLATES:
      return {
        ...state,
        appliedTemplates: {
          ...state.appliedTemplates,
          [action.channel]: null,
        },
      };

    case DISTRIBUTION_ACTION.SET_IS_EDIT_TEMPLATE_MODE:
      return {
        ...state,
        isEditTemplateMode: action.isEditMode,
      };

    case DISTRIBUTION_ACTION.SET_IS_EDIT_TEMPLATE_POPUP_OPEN:
      return {
        ...state,
        isEditTemplatePopupOpen: action.isOpen,
      };

    case DISTRIBUTION_ACTION.SET_TEMPLATE_TO_EDIT:
      return {
        ...state,
        templateToEdit: action.template,
      };

    case DISTRIBUTION_ACTION.SET_MAIN_TABLE_PAGINATION_CONFIG:
      return {
        ...state,
        mainTablePagination: action.config,
      };

    case DISTRIBUTION_ACTION.SET_MAIN_TABLE_FILTERS:
      return {
        ...state,
        mainTableFilters: action.filters,
      };

    case DISTRIBUTION_ACTION.SET_MAIN_TABLE_SORTERS:
      return {
        ...state,
        mainTableSorters: action.sorters,
      };

    case DISTRIBUTION_ACTION.SET_SELECTED_DISTRIBUTIONS:
      return {
        ...state,
        selectedDistributions: action.distributions,
      };

    case DISTRIBUTION_ACTION.SET_DISTRIBUTION_DETAILS:
      return {
        ...state,
        distributionDetails: action.distributionDetails,
      };

    case DISTRIBUTION_ACTION.SET_ALL_DISTRIBUTIONS:
      return {
        ...state,
        allDistributions: action.allDistributions,
      };

    case DISTRIBUTION_ACTION.SET_DISTRIBUTION_SETTINGS_WERE_EDITED:
      return {
        ...state,
        distributionSettingsWereEdited: action.distributionSettingsWereEdited,
      };

    case DISTRIBUTION_ACTION.SET_DISTRIBUTIONS_PERIOD_FILTER:
      return {
        ...state,
        distributionsPeriod: action.distributionsPeriod,
      };

    case DISTRIBUTION_ACTION.SET_MANUAL_ADDED_RECIPIENTS_IDS:
      return {
        ...state,
        addRecipientsToDistribution: {
          ...state.addRecipientsToDistribution,
          recipientsIds: action.ids,
        },
      };

    case DISTRIBUTION_ACTION.RESET_APPLIED_TEMPLATES:
      return {
        ...state,
        appliedTemplates: {
          [DISTRIBUTION_CHANNEL.SMS]: null,
          [DISTRIBUTION_CHANNEL.VIBER]: null,
          [DISTRIBUTION_CHANNEL.EMAIL]: null,
        },
      };

    case DISTRIBUTION_ACTION.RESET_DISTRIBUTION_FILTERS:
      return {
        ...state,
        filters: initialDistributionFilters,
      };

    case DISTRIBUTION_ACTION.SET_REPORT_MAILING_ID:
      return {
        ...state,
        reportMailingId: action.payload
      };

    case DISTRIBUTION_ACTION.SET_MAIN_TABLE_PAGINATION:
      return {
        ...state,
        mainTablePagination: action.payload
      };

    case DISTRIBUTION_ACTION.SET_DISTRIBUTION_REPORT:
      return {
        ...state,
        report: action.payload
      };

    case DISTRIBUTION_ACTION.SET_FILTERED_CLIENTS:
      return {
        ...state,
        filteredClients: action.clients
      };

    case DISTRIBUTION_ACTION.SET_MANUAL_FILTERED_CLIENTS:
      return {
        ...state,
        manualFilteredClients: action.clients
      };

    default:
      return state;
  }
};
