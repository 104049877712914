import { GROUP_ACTIONS } from '../actions/types'

const initialState = {
  datesRange: [],
  group: {},
  lesson: null,
  replaceMarkId: null,
  lessonsCounter: {
    pastLessons: 0,
    futureLessons: 0
  },
  amendTeachers: {},
  draggingStudent: null,
  droppedStatus: null,
  currentStudentId: null,
  currentStudent: null,
  chosenLessonDate: null,
  childCardActiveKey: '1',
  futureLessonsDates: [],
  courses: [],
  groupsList: [],
  schoolsList: [],
  freeClassrooms: [],
  freeTime: [],
  teachers: [],
  teachersPagination: {
    current: 1,
    total: null
  },
  teacherTableFilters: {},
  spinners: {
    modal: false,
    drawer: false,
    table: false,
    header: false
  },
  tableScroll: {
    x: 0,
    y: 0
  },
  highlightedStudentsIds: [],
  isMarkDrawerOpen: false,
  isSignsDrawerOpen: false,
  isPresenceDrawerOpen: false,
  isDiplomaDrawerOpen: false,
  isShopCartOpen: false,
  isExerciseBookOpen: false,
  isChildDetailsOpen: false,
  isConfirmOpen: false,
  isStemDrawerOpen: false,
  isAddChildModalOpen: false,
  isCancelReworkModalOpen: false,
  isEmergencyContactsDrawerOpen: false,
  addChildDrawer: {
    isOpen: false,
    forNewChild: false,
    pagination: {
      current: 1,
      pageSize: 10,
      hideOnSinglePage: true,
      showSizeChanger: false
    },
    filters: {},
    courses: []
  },
  isSmsDrawerOpen: false,
  isGroupSettingsOpen: false,
  isOneTimeLessonOpen: false,
  isAssignWorkOffOpen: false,
  isBlockVisible: false, // Сворачивание блока "Отключенные студенты"
  isReplacePaymentOpen: false,
  isCloseLessonModalVisible: false,
  isWorkingOffDrawerOpen: false,
  activeFilter: null,
  studentsWithoutAbsenceMarks: [],
  studentsWithoutCompetencesMarks: [],
  newBadgeData: {},
  isConfirmCancelBadgeOpen: false,
  isConfirmCancelDiplomaOpen: false,
  studentsLeads: [],
  studentsClients: [],
  directions: [],
  freeSeats: null,
  missedLessons: null,
  missedTopics: null,
  productsList: [],
  servicesList: [],
  tariffsList: [],
  cancelReworkLessonData: {},
  dateToStartPermanent: null,
  shortView: false,
  emergencyContacts: [],
  schoolDiscounts: []
}

export const groupReducer = (state = initialState, action) => {
  switch (action.type) {
    case GROUP_ACTIONS.SET_GROUP:
      return {
        ...state,
        group: action.payload
      }

    case GROUP_ACTIONS.SET_DATES_RANGE:
      return {
        ...state,
        datesRange: action.payload
      }

    case GROUP_ACTIONS.SET_CURRENT_LESSON:
      return {
        ...state,
        lesson: action.payload
      }

    case GROUP_ACTIONS.SET_DRAGGING_STUDENT:
      return {
        ...state,
        draggingStudent: action.payload
      }

    case GROUP_ACTIONS.SET_REPLACE_MARK_ID:
      return {
        ...state,
        replaceMarkId: action.payload
      }

      // case GROUP_ACTIONS.SET_DRAGGING_STUDENT_STATUS:
      //   return { ...state, draggingStudentStatus: action.payload };

    case GROUP_ACTIONS.SET_CURRENT_STUDENT:
      return {
        ...state,
        currentStudent: action.payload
      }

    case GROUP_ACTIONS.SET_MARK_DRAWER_OPEN:
      return {
        ...state,
        isMarkDrawerOpen: action.payload
      }

    case GROUP_ACTIONS.SET_SIGNS_DRAWER_OPEN:
      return {
        ...state,
        isSignsDrawerOpen: action.payload
      }

    case GROUP_ACTIONS.SET_PRESENCE_DRAWER_OPEN:
      return {
        ...state,
        isPresenceDrawerOpen: action.payload
      }

    case GROUP_ACTIONS.SET_DIPLOMA_DRAWER_OPEN:
      return {
        ...state,
        isDiplomaDrawerOpen: action.payload
      }

    case GROUP_ACTIONS.SET_SHOP_CART_OPEN:
      return {
        ...state,
        isShopCartOpen: action.payload
      }

    case GROUP_ACTIONS.SET_EXERCISE_BOOK_OPEN:
      return {
        ...state,
        isExerciseBookOpen: action.payload
      }

    case GROUP_ACTIONS.SET_CHILD_DETAILS_OPEN:
      return {
        ...state,
        isChildDetailsOpen: action.payload
      }

    case GROUP_ACTIONS.SET_CONFIRM_OPEN:
      return {
        ...state,
        isConfirmOpen: action.payload
      }

    case GROUP_ACTIONS.SET_ADD_CHILD_MODAL_OPEN:
      return {
        ...state,
        isAddChildModalOpen: action.payload
      }

    case GROUP_ACTIONS.SET_CANCEL_REWORK_MODAL_OPEN:
      return {
        ...state,
        isCancelReworkModalOpen: action.payload
      }

    case GROUP_ACTIONS.SET_DROPPED_STATUS:
      return {
        ...state,
        droppedStatus: action.payload
      }

    case GROUP_ACTIONS.SET_ADD_CHILD_DRAWER_OPEN:
      return {
        ...state,
        addChildDrawer: {
          ...state.addChildDrawer,
          isOpen: action.isOpen,
          forNewChild: action.forNewChild,
          pagination: initialState.addChildDrawer.pagination,
          filters: initialState.addChildDrawer.filters
        }
      }

    case GROUP_ACTIONS.SET_STEM_DRAWER_OPEN:
      return {
        ...state,
        isStemDrawerOpen: action.payload
      }

    case GROUP_ACTIONS.SET_SMS_DRAWER_OPEN:
      return {
        ...state,
        isSmsDrawerOpen: action.payload
      }

    case GROUP_ACTIONS.SET_ASSIGN_WORK_OFF_OPEN:
      return {
        ...state,
        isAssignWorkOffOpen: action.payload
      }

    case GROUP_ACTIONS.SET_GROUP_SETTINGS_OPEN:
      return {
        ...state,
        isGroupSettingsOpen: action.payload
      }

    case GROUP_ACTIONS.SET_ONE_TIME_LESSON_OPEN:
      return {
        ...state,
        isOneTimeLessonOpen: action.payload
      }

    case GROUP_ACTIONS.SET_CHOSEN_LESSON_DATE:
      return {
        ...state,
        chosenLessonDate: action.payload
      }

    case GROUP_ACTIONS.SET_MODAL_SPINNER:
      return {
        ...state,
        spinners: {
          ...state.spinners,
          modal: action.payload
        }
      }

    case GROUP_ACTIONS.SET_DRAWER_SPINNER:
      return {
        ...state,
        spinners: {
          ...state.spinners,
          drawer: action.payload
        }
      }

    case GROUP_ACTIONS.SET_TABLE_SPINNER:
      return {
        ...state,
        spinners: {
          ...state.spinners,
          table: action.payload
        }
      }

    case GROUP_ACTIONS.SET_HEADER_SPINNER:
      return {
        ...state,
        spinners: {
          ...state.spinners,
          header: action.payload
        }
      }

    case GROUP_ACTIONS.SET_CHILD_CARD_ACTIVE_KEY:
      return {
        ...state,
        childCardActiveKey: action.payload
      }

    case GROUP_ACTIONS.SET_GROUPS_COURSES:
      return {
        ...state,
        courses: action.payload
      }

    case GROUP_ACTIONS.SET_GROUPS_LIST:
      return {
        ...state,
        groupsList: action.payload
      }

    case GROUP_ACTIONS.SET_SCHOOLS_LIST:
      return {
        ...state,
        schoolsList: action.payload
      }

    case GROUP_ACTIONS.SET_FREE_CLASSROOMS:
      return {
        ...state,
        freeClassrooms: action.payload
      }

    case GROUP_ACTIONS.SET_FREE_TIME:
      return {
        ...state,
        freeTime: action.payload
      }

    case GROUP_ACTIONS.SET_TEACHERS:
      return {
        ...state,
        teachers: action.payload.list,
        teachersPagination: action.payload.pagination
      }

    case GROUP_ACTIONS.SET_LESSONS_COUNTER:
      return {
        ...state,
        lessonsCounter:
          {
            pastLessons: action.payload.pastLessons,
            futureLessons: action.payload.futureLessons
          }
      }

    case GROUP_ACTIONS.SET_BlOCK_VISIBLE:
      return {
        ...state,
        isBlockVisible: action.payload
      }

    // case GROUP_ACTIONS.SET_SCROLL_TO_STUDENT_ID:
    //   return {...state, scrollToStudentId: action.payload};
    case GROUP_ACTIONS.SET_TABLE_SCROLL:
      return {
        ...state,
        tableScroll: {
          x: action.payload.x !== undefined
            ? action.payload.x
            : state.tableScroll.x,
          y: action.payload.y !== undefined
            ? action.payload.y
            : state.tableScroll.y
        }
      }
    case GROUP_ACTIONS.SET_REPLACE_PAYMENT_OPEN:
      return {
        ...state,
        isReplacePaymentOpen: action.payload
      }

    case GROUP_ACTIONS.SET_FUTURE_LESSONS_DATES:
      return {
        ...state,
        futureLessonsDates: action.payload
      }

    case GROUP_ACTIONS.SET_HIGHLIGHTED_STUDENTS_IDS:
      return {
        ...state,
        highlightedStudentsIds: action.payload
      }

    case GROUP_ACTIONS.SET_CLOSE_LESSON_MODAL_VISIBLE:
      return {
        ...state,
        isCloseLessonModalVisible: action.payload
      }

    case GROUP_ACTIONS.SET_ACTIVE_FILTER:
      return {
        ...state,
        activeFilter: action.payload
      }

    case GROUP_ACTIONS.SET_WORKING_OFF_DRAWER_OPEN:
      return {
        ...state,
        isWorkingOffDrawerOpen: action.payload
      }

    case GROUP_ACTIONS.SET_STUDENTS_WITHOUT_ABSENCE_MARKS:
      return {
        ...state,
        studentsWithoutAbsenceMarks: action.payload
      }
    case GROUP_ACTIONS.SET_TEACHERS_FILTERS:
      return {
        ...state,
        teacherTableFilters: action.payload
      }

    case GROUP_ACTIONS.SET_STUDENTS_WITHOUT_COMPETENCES_MARKS:
      return {
        ...state,
        studentsWithoutCompetencesMarks: action.payload
      }

    case GROUP_ACTIONS.SET_LOCAL_BADGES_DATA:
      return {
        ...state,
        newBadgeData: action.payload
      }

    case GROUP_ACTIONS.SET_STUDENT_PRESENCE:
      console.log('Payload', action.payload)
      return {
        ...state,
        group: {
          ...state.group,
          students: state.group?.students?.map(student => {
            if (student?.id === action.payload?.studentId) {
              return {
                ...student,
                lessons: student.lessons?.map(lesson => {
                  if (lesson.lessonId === action.payload?.lessonId) {
                    return action.payload
                  }
                  return lesson
                })
              }
            }
            return student
          })
        }
      }

    case GROUP_ACTIONS.SET_UPDATED_BADGE_DATA:
      return {
        ...state,
        group: {
          ...state.group,
          students: state.group?.students?.map(student => {
            if (state.currentStudent?.id === student.id) {
              return {
                ...student,
                stemBalance: {
                  ...student.stemBalance,
                  badges: student.stemBalance?.badges?.map(badge => {
                    if (badge.id === action.badgeId) return action.data
                    return badge
                  })
                }
              }
            }
            return student
          })
        }
      }

    case GROUP_ACTIONS.SET_UPDATED_DIPLOMA_DATA:
      return {
        ...state,
        group: {
          ...state.group,
          students: state.group?.students?.map(student => {
            if (state.currentStudent?.id === student.id) {
              return {
                ...student,
                stemBalance: {
                  ...student.stemBalance,
                  diplomas: student.stemBalance?.diplomas?.map(diploma => {
                    if (diploma.id === action.diplomaId) return action.data
                    return diploma
                  })
                }
              }
            }
            return student
          })
        }
      }

    case GROUP_ACTIONS.SET_CONFIRM_CANCEL_BADGE_OPEN:
      return {
        ...state,
        isConfirmCancelBadgeOpen: action.payload
      }

    case GROUP_ACTIONS.SET_CONFIRM_CANCEL_DIPLOMA_OPEN:
      return {
        ...state,
        isConfirmCancelDiplomaOpen: action.payload
      }

    case GROUP_ACTIONS.SET_STUDENTS_LEADS:
      return {
        ...state,
        studentsLeads: action.payload
      }

    case GROUP_ACTIONS.SET_STUDENTS_CLIENTS:
      return {
        ...state,
        studentsClients: action.payload
      }

    case GROUP_ACTIONS.SET_ADD_CHILD_TABLE_FILTERS:
      return {
        ...state,
        addChildDrawer: {
          ...state.addChildDrawer,
          filters: action.payload
        }
      }

    case GROUP_ACTIONS.SET_ADD_CHILD_TABLE_PAGINATION:
      return {
        ...state,
        addChildDrawer: {
          ...state.addChildDrawer,
          pagination: action.payload
        }
      }

    case GROUP_ACTIONS.SET_COURSE_DIRECTIONS:
      return {
        ...state,
        directions: action.payload
      }

    case GROUP_ACTIONS.SET_ADD_CHILD_COURSES:
      return {
        ...state,
        addChildDrawer: {
          ...state.addChildDrawer,
          courses: action.payload
        }
      }

    case GROUP_ACTIONS.SET_FREE_SEATS:
      return {
        ...state,
        freeSeats: action.payload
      }

    case GROUP_ACTIONS.SET_MISSED_LESSONS:
      return {
        ...state,
        missedLessons: action.payload
      }

    case GROUP_ACTIONS.SET_MISSED_TOPICS: {
      return {
        ...state,
        missedTopics: action.payload
      }
    }

    case GROUP_ACTIONS.SET_SERVICES_LIST:
      return {
        ...state,
        servicesList: action.payload
      }
    case GROUP_ACTIONS.SET_TARIFFS_LIST:
      return {
        ...state,
        tariffsList: action.payload
      }

    case GROUP_ACTIONS.SET_PRODUCTS_LIST:
      return {
        ...state,
        productsList: action.payload
      }
    case GROUP_ACTIONS.CHANGE_REPLACEMENT_TEACHERS:

      return {
        ...state,
        group: {
          ...state.group,
          amendTeachers: action.payload
        }
      }

    case GROUP_ACTIONS.SET_CANCEL_REWORK_DATA: {
      return {
        ...state,
        cancelReworkLessonData: action.payload
      }
    }

    case GROUP_ACTIONS.SET_DATE_TO_START_PERMANENT: {
      return {
        ...state,
        dateToStartPermanent: action.payload
      }
    }
    case GROUP_ACTIONS.SET_SHORT_VIEW: {
      return {
        ...state,
        shortView: action.payload
      }
    }
    case GROUP_ACTIONS.SET_EMERGENCY_CONTACTS_OPEN: {
      return {
        ...state,
        isEmergencyContactsDrawerOpen: action.payload
      }
    }
    case GROUP_ACTIONS.SET_EMERGENCY_CONTACTS: {
      return {
        ...state,
        emergencyContacts: action.payload
      }
    }

    case GROUP_ACTIONS.SET_DISCOUNT_LIST_FOR_SCHOOL: {
      return {
        ...state,
        schoolDiscounts: action.payload
      }
    }

    default:
      return state
  }
}
