import { ACTION } from '../actions/types';
import moment from 'moment';
import { eventTypes, LS_VALUES } from '../../constants/text';

// const mockPlanned = [
//   {
//     date: moment().add(1, 'days').format('DD.MM.YYYY  H:mm'),
//     text: 'todo something',
//     type: eventTypes.waiting,
//     id: 1,
//     creatingDate: moment().format('DD.MM.YYYY H:mm:ss A'),
//   },
//   {
//     date: moment().add(2, 'days').format('DD.MM.YYYY H:mm'),
//     text: 'todo something',
//     type: eventTypes.waiting,
//     id: 2,
//     creatingDate: moment().format('DD.MM.YYYY H:mm:ss'),
//   },
// ];
//
// const mockCompleted = [
//   {
//     date: moment().format('DD.MM.YYYY  H:mm:ss'),
//     text: 'todo something',
//     type: eventTypes.waiting,
//     id: 3,
//   },
//   {
//     date: moment().subtract(1, 'days').format('DD.MM.YYYY  H:mm:ss'),
//     text: 'було зроблено',
//     type: eventTypes.call,
//     id: 4,
//   },
// ];

const initialState = {
  leedsStatusFilter: 'all',
  inProgressLeedsSubStatusFilter: 'all',
  completedLeedsSubStatusFilter: 'all',
  leedsPaginationValue: {
    current: 1,
    pageSize: 10,
  },
  inProgressPopupVisible: false,
  inProgressLeedSubStatusValue: 'disabled',
  completedPopupVisible: false,
  completedLeedSubStatusValue: 'disabled',
  selectedLeedsIds: [],
  selectedLeeds: [],
  tableFilterParams: {
    phone: null,
    customerType: null,
    regDate: {
      from: JSON.parse(localStorage.getItem(LS_VALUES.LEADS_TABLE_REG_DATE_FROM)),
      to: JSON.parse(localStorage.getItem(LS_VALUES.LEADS_TABLE_REG_DATE_TO)),
    },
    schools: null,
    inBlackList: null,
  },
  leedTableLoading: false,
  tableTotalRecords: 10,
  lastActivitySortValue: JSON.parse(localStorage.getItem(LS_VALUES.LEADS_TABLE_LAST_ACTIVITY_SORT)) || false,
  leedsGroupActionStatus: 'disabled',
  leedsGroupActionInProgressSubStatus: 'disabled',
  leedsGroupActionCompletedSubStatus: 'disabled',
  leedsDataIsLoading: false,
  leedsData: [],
  loadLeedDataError: false,
  newLeedDataIsSubmitting: false,
  newLeedDataSubmitError: false,
  selectedLeed: {},
  selectedLeadEvents: {
    planned: [],
    completed: [],
  },
  leadComments: {},
  newLeedPopupVisible: false,
  newLeedFieldDrawerIsVisible: false,
  newLeedFieldCreateError: false,
  newLeedFieldIsSubmitting: false,
  leedsTableSettingsDrawerIsVisible: false,
  leedAdditionalFields: [],
  leedAdditionalFieldsDownloaded: false,
  leedsCount: {
    newLeadsCount: 0,
    leadsInWorkCount: 0,
    finishedLeadsCount: 0,
  },
  leedToUpdateStatusSubStatus: null,
  leedNewStatusSubStatusIsSubmitting: false,
  leedPossibleSchools: [],
  deleteLeedsIsSubmitting: false,
  selectedLeedsStatusSubStatusIsSubmitting: false,
  confirmDeleteLeedsPopupIsVisible: false,
  selectedLeedSpinnerVisible: false,
  newLeedPopupDefaultValues: null,
  similarLeadsAndClients: [],
  optionsToLoadLeads: {
    page: 0,
    size: 10,
    sortOrder: null,
    fromCreatedDate: null,
    toCreatedDate: null,
    schoolIdList: [],
    status: null,
    substatus: null,
    blackList: null,
  },
  selectedExistingParent: false,
  selectedExistingChild: false,
  selectedParent: null,
  selectedChild: null,
  leedParentEditMode: false
};

export const leedsReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION.SET_LEEDS_STATUS_FILTER:
      return {
        ...state,
        leedsStatusFilter: action.value,
      };

    case ACTION.SET_IN_PROGRESS_LEEDS_SUBSTATUS_FILTER:
      return {
        ...state,
        inProgressLeedsSubStatusFilter: action.value,
      };

    case ACTION.SET_COMPLETED_LEEDS_SUBSTATUS_FILTER:
      return {
        ...state,
        completedLeedsSubStatusFilter: action.value,
      };

    case ACTION.SET_LEEDS_PAGINATION_VALUE:
      return {
        ...state,
        leedsPaginationValue: {
          ...action.value,
        },
      };
    case ACTION.SET_LEED_TABLE_LOADING:
      return {
        ...state,
        leedTableLoading: action.payload
      };
    case ACTION.SET_TOTAL_LEED_RECORDS:
      return {
        ...state,
        tableTotalRecords: action.payload
      };

    case ACTION.SET_IN_PROGRESS_LEEDS_POPUP_VISIBLE:
      return {
        ...state,
        inProgressPopupVisible: action.value,
      };

    case ACTION.SET_IN_PROGRESS_LEED_SUBSTATUS_VALUE:
      return {
        ...state,
        inProgressLeedSubStatusValue: action.value,
      };

    case ACTION.SET_COMPLETED_LEEDS_POPUP_VISIBLE:
      return {
        ...state,
        completedPopupVisible: action.value,
      };

    case ACTION.SET_COMPLETED_LEED_SUBSTATUS_VALUE:
      return {
        ...state,
        completedLeedSubStatusValue: action.value,
      };

    case ACTION.SET_SELECTED_LEEDS_IDS:
      return {
        ...state,
        selectedLeedsIds: action.ids,
      };

    case ACTION.SET_LEEDS_TABLE_PHONE_FILTER:
      return {
        ...state,
        tableFilterParams: {
          ...state.tableFilterParams,
          phone: action.phone,
        },
      };

    case ACTION.SET_LEEDS_TABLE_CUSTOMER_TYPE_FILTER:
      return {
        ...state,
        tableFilterParams: {
          ...state.tableFilterParams,
          customerType: action.customerType,
        },
      };

    case ACTION.SET_LEEDS_TABLE_REG_RANGE_DATE_FILTER:
      return {
        ...state,
        tableFilterParams: {
          ...state.tableFilterParams,
          regDate: { ...action.payload },
        },
      };

    case ACTION.SET_LEEDS_TABLE_SCHOOLS_FILTER:
      return {
        ...state,
        tableFilterParams: {
          ...state.tableFilterParams,
          schools: action.schools,
        },
      };

    case ACTION.SET_LEEDS_TABLE_IN_BLACK_LIST_FILTER:
      return {
        ...state,
        tableFilterParams: {
          ...state.tableFilterParams,
          inBlackList: action.inBlackList,
        },
      };

    case ACTION.SET_LEEDS_TABLE_ADDITIONAL_FILTER:
      return {
        ...state,
        tableFilterParams: {
          ...state.tableFilterParams,
          ...action.filters,
        },
      };

    case ACTION.SET_LEEDS_TABLE_LAST_ACTIVITY_SORT:
      return {
        ...state,
        lastActivitySortValue: action.value,
      };

    case ACTION.SET_LEEDS_GROUP_ACTION_STATUS:
      return {
        ...state,
        leedsGroupActionStatus: action.value,
      };

    case ACTION.SET_LEEDS_GROUP_ACTION_IN_PROGRESS_SUBSTATUS:
      return {
        ...state,
        leedsGroupActionInProgressSubStatus: action.value,
      };

    case ACTION.SET_LEEDS_GROUP_ACTION_COMPLETED_SUBSTATUS:
      return {
        ...state,
        leedsGroupActionCompletedSubStatus: action.value,
      };

    case ACTION.SET_LEEDS_DATA_IS_LOADING:
      return {
        ...state,
        leedsDataIsLoading: action.isLoading,
      };

    case ACTION.SET_LEEDS_DATA:
      return {
        ...state,
        leedsData: action.leedsData,
      };

    case ACTION.SET_LOAD_LEEDS_DATA_ERROR:
      return {
        ...state,
        loadLeedDataError: action.isError,
      };

    case ACTION.SET_NEW_LEED_DATA_IS_SUBMITTING:
      return {
        ...state,
        newLeedDataIsSubmitting: action.isSubmitting,
      };

    case ACTION.SET_NEW_LEED_DATA_SUBMIT_ERROR:
      return {
        ...state,
        newLeedDataSubmitError: action.isError,
      };

    case ACTION.SET_SELECTED_LEED_DATA:
      return {
        ...state,
        selectedLeed: action.data,
      };

    case ACTION.SET_LEED_COMPLETED_EVENTS:
      return {
        ...state,
        selectedLeadEvents: {
          ...state.selectedLeadEvents,
          completed: action.data,
        },
      };

    case ACTION.SET_LEED_PLANNED_EVENTS:
      return {
        ...state,
        selectedLeadEvents: {
          ...state.selectedLeadEvents,
          planned: action.data,
        },
      };

    case ACTION.SET_NEW_LEED_POPUP_VISIBLE:
      return {
        ...state,
        newLeedPopupVisible: action.isVisible,
      };

    case ACTION.SET_NEW_LEED_FIELD_DRAWER_IS_VISIBLE:
      return {
        ...state,
        newLeedFieldDrawerIsVisible: action.isVisible,
      };

    case ACTION.SET_NEW_LEED_FIELD_CREATE_ERROR:
      return {
        ...state,
        newLeedFieldCreateError: action.isError,
      };

    case ACTION.SET_LEEDS_TABLE_SETTINGS_DRAWER_IS_VISIBLE:
      return {
        ...state,
        leedsTableSettingsDrawerIsVisible: action.isVisible,
      };

    case ACTION.SET_NEW_LEED_FIELD_IS_SUBMITTING:
      return {
        ...state,
        newLeedFieldIsSubmitting: action.isSubmitting,
      };

    case ACTION.SET_LEED_ADDITIONAL_FIELDS:
      return {
        ...state,
        leedAdditionalFields: action.additionalFields,
      };

    case ACTION.SET_LEED_ADDITIONAL_FIELDS_DOWNLOADED:
      return {
        ...state,
        leedAdditionalFieldsDownloaded: action.isDownloaded,
      };

    case ACTION.SET_SELECTED_LEED_PARENTS:
      return {
        ...state,
        selectedLeed: {
          ...state.selectedLeed,
          family: {
            ...state.selectedLeed.family,
            parents: action.data,
          },
        },
      };

    case ACTION.SET_SELECTED_LEED_CHILDREN:
      return {
        ...state,
        selectedLeed: {
          ...state.selectedLeed,
          family: {
            ...state.selectedLeed.family,
            children: action.data,
          },
        },
      };

    case ACTION.SET_LEEDS_COUNT:
      return {
        ...state,
        leedsCount: {
          ...action.leedsCount,
        },
      };

    case ACTION.SET_LEED_TO_UPDATE_STATUS_SUBSTATUS:
      return {
        ...state,
        leedToUpdateStatusSubStatus: action.leed,
      };

    case ACTION.SET_LEED_NEW_STATUS_SUBSTATUS_IS_SUBMITTING:
      return {
        ...state,
        leedNewStatusSubStatusIsSubmitting: action.isSubmitting,
      };

    case ACTION.SET_LEED_POSSIBLE_SCHOOLS:
      return {
        ...state,
        leedPossibleSchools: action.schools,
      };
    case ACTION.SET_LEAD_COMMENTS:
      return {
        ...state,
        leadComments: action.payload,
      };

    case ACTION.SET_DELETE_LEEDS_IS_SUBMITTING:
      return {
        ...state,
        deleteLeedsIsSubmitting: action.isSubmitting,
      };

    case ACTION.SET_SELECTED_LEEDS:
      return {
        ...state,
        selectedLeeds: action.leeds,
      };

    case ACTION.SET_SELECTED_LEEDS_NEW_STATUS_SUBSTATUS_IS_SUBMITTING:
      return {
        ...state,
        selectedLeedsStatusSubStatusIsSubmitting: action.isSubmitting,
      };

    case ACTION.SET_CONFIRM_DELETE_LEEDS_POPUP_IS_VISIBLE:
      return {
        ...state,
        confirmDeleteLeedsPopupIsVisible: action.isVisible,
      };

    case ACTION.SET_SELECTED_LEED_SPINNER:
      return {
        ...state,
        selectedLeedSpinnerVisible: action.isVisible,
      };

    case ACTION.SET_SIMILAR_LEADS_AND_CLIENTS:
      return {
        ...state,
        similarLeadsAndClients: action.leadsAndClients,
      };
    case ACTION.SET_NEW_LEED_POPUP_DEFAULT_VALUES:
      return {
        ...state,
        newLeedPopupDefaultValues: action.payload,
      };

    case ACTION.SET_OPTIONS_TO_LOAD_LEADS:
      return {
        ...state,
        optionsToLoadLeads: action.options,
      };

    case ACTION.SET_SELECTED_EXISTING_PARENT:
      return {
        ...state,
        selectedExistingParent: action.payload
      };

    case ACTION.SET_SELECTED_EXISTING_CHILD:
      return {
        ...state,
        selectedExistingChild: action.payload
      };

    case ACTION.SET_SELECTED_CHILD:
      return {
        ...state,
        selectedChild: action.payload
      };

    case ACTION.SET_SELECTED_PARENT:
      return {
        ...state,
        selectedParent: action.payload
      };
    case ACTION.LEED_PARENT_EDIT_MODE:
      return {
        ...state,
        leedParentEditMode: action.payload,
      };

    default:
      return state;
  }
};
