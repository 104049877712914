import {
  createEvent,
  createReminderQuery,
  getClientEvents,
  updateComment
} from '../../utils/events'
import { uploadFileToServer } from './index'
import { setActionTabsSpinner, setEventSpinner } from './spinners'
import { SELECTED_PERSON_ACTIONS } from './types'
import { deleteFile } from '../../utils/albums'
import {
  getEventsForRender
} from '../../components/Leeds/TabPanes/LeedTabPaneGeneral/LeedActions/leedActionsHelper'

export const setPersonEvents = events => ({
  type: SELECTED_PERSON_ACTIONS.SET_EVENTS,
  payload: events
})

export const getPersonEvents = (id) => (dispatch, getState) => {
  const {
    current,
    pageSize,
    filters
  } = getState().selectedPerson.events
  const { currentRole } = getState().auth
  getClientEvents(id, {
    ...filters,
    page: current - 1,
    size: pageSize
  },
  currentRole).then(result => {
    if (result) {
      dispatch(setPersonEvents(getEventsForRender(result.list)))
      dispatch(setEventsTotal(result.total))
    }
  }).catch(err => {
    console.log('get events error', err)
  })
}

export const createNewEvent = (data, files = []) => async dispatch => {
  dispatch(setActionTabsSpinner(true))
  try {
    const result = await createEvent(data)
    dispatch(setActionTabsSpinner(false))
    if (result.data?.statusCode?.code === 0 && result.data.data) {
      const newEvent = result.data.data

      function addFile (file) {
        if (file) {
          if (!newEvent.files) newEvent.files = []
          newEvent.files.push(file)
        }
      }

      if (!files.length) { return newEvent || {} }
      if (files.length) {
        const promises = []
        files.forEach(file => {
          promises.push(dispatch(
            uploadFileToServer(file.originFileObj, 'LD_COM',
              result.data.data.id, addFile)))
        })
        await Promise.all(promises)
        return newEvent
      }
    }
  } catch (err) {
    console.error('catch an err', err)
  } finally {
    dispatch(setActionTabsSpinner(false))
  }
}

export const createReminder = (data) => async (dispatch, getState) => {
  const schoolId = getState().family.student?.schoolId
  const familyId = getState().family.family?.id
  try {
    const result = await createReminderQuery({
      familyId: familyId,
      remindAt: data.date,
      schoolId: schoolId,
      text: data.text
    })
    return result?.data?.statusCode?.code === 0
  } catch (e) {
    return false
  }
}

export const updateCommentById = (
  id, data, callback = () => {}) => dispatch => {
  dispatch(setEventSpinner(true))
  updateComment(id, data).then(result => {
    callback()
    dispatch(setEventSpinner(false))
    if (result.data && result.data.statusCode && result.data.statusCode.code ===
      0 && result.data.data) {
      dispatch(getPersonEvents(data.clientId))
    }
  }).catch(err => {
    dispatch(setEventSpinner(false))
    console.log('update comment error', err)
  })
}

export const deleteFileById = id => () => {
  deleteFile([id]).then((result) => {
    console.log(result)
  }).catch(err => {
    console.log('remove file from comment error', err)
  })
}

export const setEventsTotal = total => ({
  type: SELECTED_PERSON_ACTIONS.SET_EVENTS_TOTAL_AMOUNT,
  payload: total
})

export const setEventsCurrentPage = current => ({
  type: SELECTED_PERSON_ACTIONS.SET_EVENTS_CURRENT_PAGE,
  payload: current
})

export const setEventsPageSize = size => ({
  type: SELECTED_PERSON_ACTIONS.SET_EVENTS_PAGE_SIZE,
  payload: size
})

export const setSelectedTypes = types => ({
  type: SELECTED_PERSON_ACTIONS.SET_SELECTED_TYPES,
  payload: types
})

export const setEventsFilters = types => ({
  type: SELECTED_PERSON_ACTIONS.SET_EVENTS_FILTERS,
  payload: types
})

export const setSelectedDatesRange = range => ({
  type: SELECTED_PERSON_ACTIONS.SET_DATE_RANGE,
  payload: range
})
