import axios from 'axios';
import { ALBUMS_API, BASE_FILE_SERVICE_URL, BASE_SCHOOL_SERVICE_URL, FILES_API } from '../constants/api';

export const getAlbums = (page, size, sort, data) => {
  return axios({
    url: `${ALBUMS_API}/filter?page=${page}&size=${size}&sort=createdAt,${sort}`,
    method: 'post',
    withCredentials: true,
    headers: { 'content-type': 'application/json' },
    data: JSON.stringify(data)
  });
};

export const createAlbum = (data) => {
  return axios({
    url: ALBUMS_API,
    method: 'post',
    withCredentials: true,
    headers: { 'content-type': 'application/json' },
    data: JSON.stringify(data)
  });
};

export const deleteAlbums = (albumsIds) => {
  return axios({
    url: ALBUMS_API,
    method: 'delete',
    withCredentials: true,
    headers: { 'content-type': 'application/json' },
    data: albumsIds
  });
};

export const getAlbum = id => {
  return axios({
    url: `${ALBUMS_API}/${id}`,
    method: 'get',
    withCredentials: true,
    headers: { 'content-type': 'application/json' },
  });
};

export const uploadFile = data => {
  return axios({
    url: `${FILES_API}/upload`,
    method: 'post',
    withCredentials: true,
    headers: { 'content-type': 'multipart/form-data'},
    data: data,
  });
};

export const updateFile = formData => {
  return axios({
    url: FILES_API,
    method: 'put',
    withCredentials: true,
    headers: { 'content-type': 'multipart/form-data' },
    data: formData,
  });
};

export const deleteFile = idList => {
  return axios({
    url: FILES_API,
    method: 'delete',
    withCredentials: true,
    headers: { 'content-type': 'application/json' },
    data: idList,
  });
};

export const changeAlbumTitle = data => {
  return axios({
    url: ALBUMS_API,
    method: 'put',
    withCredentials: true,
    headers: { 'content-type': 'application/json' },
    data: JSON.stringify(data)
  });
};

export const addTag = data => {
  return axios({
    url: `${BASE_FILE_SERVICE_URL}/api/tags`,
    method: 'post',
    withCredentials: true,
    headers: { 'content-type': 'application/json' },
    data
  });
};

export const deleteStudentFromPhoto = async (photoId) => {
  const url = `${BASE_FILE_SERVICE_URL}/api/name-tags/photo?id=${photoId}`;
  const response = await axios.delete(url);
  return response?.data?.data || false;
};

export const getStudentNamesByGroupId = async (groupId) => {
  const url = `${BASE_SCHOOL_SERVICE_URL}/education-group/${groupId}/students/names`;
  const response = await axios.get(url);
  return response?.data?.data || [];
};

export const addStudentsToPhoto = async (photoId, students = []) => {
  const url = `${BASE_FILE_SERVICE_URL}/api/name-tags/photo?photoId=${photoId}`;
  const response = await axios.post(url, students);
  return response?.data?.data || [];
};

export const getPhotoTags = async (photoId) => {
  const url = `${BASE_FILE_SERVICE_URL}/api/name-tags/photo?photoId=${photoId}`;
  const response = await axios.get(url);
  return response?.data?.data || [];
};
