import { SUPPLIERS_ACTIONS } from '../actions/types';

const initialState = {
  suppliers: [],
  suppliersBySchool: [],
  filters: {},
  pagination: {
    hideOnSinglePage: true,
    defaultCurrent: 0,
    defaultPageSize: 10,
    current: 1,
    total: 0,
  },
  sorter: {},
  schoolGroupId: null,
};

export const suppliersReducer = (state = initialState, action) => {
  switch (action.type) {
    case SUPPLIERS_ACTIONS.SET_SUPPLIERS:
      return {...state, suppliers: action.payload};

    case SUPPLIERS_ACTIONS.SET_FILTERS:
      return {...state, filters: action.payload};

    case SUPPLIERS_ACTIONS.SET_SORTER:
      return {...state, sorter: action.payload};

    case SUPPLIERS_ACTIONS.SET_PAGINATION:
      return {...state, pagination: action.payload};

    case SUPPLIERS_ACTIONS.SET_SCHOOL_GROUP_ID:
      return {...state, schoolGroupId: action.payload};
      
    case SUPPLIERS_ACTIONS.SET_SUPPLIERS_BY_SCHOOL:
      return {...state, suppliersBySchool: action.payload};

    default:
      return state;
  }
};