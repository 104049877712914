export const COLUMNS = {
  STATUS: 'STATUS',
  FAMILY_ID: 'FAMILY_ID',
  CREATED_TIME: 'CREATED_TIME',
  CREATED_DATE: 'CREATED_DATE',
  BILL_NUMBER: 'BILL_NUMBER',
  CHILD_NAME: 'CHILD_NAME',
  GROUP_NAME: 'GROUP_NAME',
  FAMILY_FULL_NAME: 'FAMILY_FULL_NAME',
  SERVICES_TOTAL_SUM: 'SERVICES_TOTAL_SUM',
  SERVICES_DISCOUNT_SUM: 'SERVICES_DISCOUNT_SUM',
  SERVICES_PIGGY_BANK: 'SERVICES_PIGGY_BANK',
  TO_PIGGY_BANK: 'TO_PIGGY_BANK',
  SERVICES_CASH: 'SERVICES_CASH',
  SERVICES_CARD: 'SERVICES_CARD',
  SERVICES_ONLINE_PAY: 'SERVICES_ONLINE_PAY',
  PRODUCTS_TOTAL_SUM: 'PRODUCTS_TOTAL_SUM',
  PRODUCTS_DISCOUNT_SUM: 'PRODUCTS_DISCOUNT_SUM',
  PRODUCTS_PIGGY_BANK: 'PRODUCTS_PIGGY_BANK',
  PRODUCTS_CASH: 'PRODUCTS_CASH',
  PRODUCTS_CARD: 'PRODUCTS_CARD',
  PRODUCTS_ONLINE_PAY: 'PRODUCTS_ONLINE_PAY',
  TOTAL: 'TOTAL',
  STEMS: 'STEMS',
  ADMIN_FULL_NAME: 'ADMIN_FULL_NAME'
}

// export const COLUMN_NAMES = {
//   [COLUMNS.STATUS]: t('Finances.status'),
//   // [COLUMNS.ORDER_NUMBER]: t('Finances.orderNumber'),
//   [COLUMNS.FAMILY_ID]: t('Finances.familyId'),
//   [COLUMNS.CREATED_TIME]: t('Finances.createdTime'),
//   [COLUMNS.CREATED_DATE]: t('Finances.createdDate'),
//   [COLUMNS.BILL_NUMBER]: t('Finances.billNumber'),
//   [COLUMNS.FAMILY_FULL_NAME]: t('Finances.familyFullName'),
//   [COLUMNS.SERVICES_TOTAL_SUM]: t('Finances.servicesTotalSum'),
//   [COLUMNS.SERVICES_DISCOUNT_SUM]: t('Finances.servicesDiscountSum'),
//   [COLUMNS.SERVICES_PIGGY_BANK]: t('Finances.servicesPiggyBank'),
//   [COLUMNS.SERVICES_CASH]: t('Finances.servicesCash'),
//   [COLUMNS.SERVICES_CARD]: t('Finances.servicesCard'),
//   [COLUMNS.SERVICES_ONLINE_PAY]: t('Finances.servicesOnlinePay'),
//   [COLUMNS.PRODUCTS_TOTAL_SUM]: t('Finances.productsTotalSum'),
//   [COLUMNS.PRODUCTS_DISCOUNT_SUM]: t('Finances.productsDiscountSum'),
//   [COLUMNS.PRODUCTS_PIGGY_BANK]: t('Finances.productsPiggyBank'),
//   [COLUMNS.PRODUCTS_CASH]: t('Finances.productsCash'),
//   [COLUMNS.PRODUCTS_CARD]: t('Finances.productsCard'),
//   [COLUMNS.PRODUCTS_ONLINE_PAY]: t('Finances.productsOnlinePay'),
//   [COLUMNS.TOTAL]: t('Finances.total'),
//   [COLUMNS.STEMS]: t('Finances.stems'),
//   [COLUMNS.ADMIN_FULL_NAME]: t('Finances.adminFullName'),
// };
