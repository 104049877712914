import { TEACHERS_ACTIONS } from '../actions/types'
import { mockFinSettings } from '../../pages/TeacherProfile/mockTeacherData'

const initialState = {
  lessonsPayments: [],
  lessonsPaymentsTotal: {},
  lessonsPaymentsFilters: { dateAccrual: [] },
  lessonsPaymentsPagination: {
    hideOnSinglePage: true,
    current: 1,
    pageSize: 5
  },
  lessonsPaymentsSorter: {},
  lessonsPaymentsLoading: false,
  additionalPayments: [],
  additionalPaymentsTotal: {},
  additionalPaymentsFilters: {},
  additionalPaymentsPagination: {
    hideOnSinglePage: true,
    current: 1,
    pageSize: 5
  },
  additionalPaymentsSorter: {},
  additionalPaymentsLoading: false,
  finSettings: mockFinSettings,
  certification: [],
  teachersCourses: [],
  qualificationLevels: [],
  currentProfileSchool: null,
  addPaymentModalOpen: false,
  schoolAdditionalPrice: [],
  additionalPaymentsSettings: [],
  additionalPaymentsSettingsLoading: false
}

export const teacherReducer = (state = initialState, action) => {
  switch (action.type) {
    case TEACHERS_ACTIONS.SET_LESSONS_PAYMENTS_LOADING:
      return {
        ...state,
        lessonsPaymentsLoading: action.payload
      }

    case TEACHERS_ACTIONS.SET_LESSONS_PAYMENTS:
      return {
        ...state,
        lessonsPayments: action.payload
      }

    case TEACHERS_ACTIONS.SET_LESSONS_PAYMENTS_TOTAL:
      return {
        ...state,
        lessonsPaymentsTotal: action.payload
      }

    case TEACHERS_ACTIONS.SET_LESSONS_PAYMENTS_FILTERS:
      return {
        ...state,
        lessonsPaymentsFilters: action.payload
      }

    case TEACHERS_ACTIONS.SET_LESSONS_PAYMENTS_PAGINATION:
      return {
        ...state,
        lessonsPaymentsPagination: action.payload
      }

    case TEACHERS_ACTIONS.SET_LESSONS_PAYMENTS_SORTER:
      return {
        ...state,
        lessonsPaymentsSorter: action.payload
      }

    case TEACHERS_ACTIONS.SET_ADDITIONAL_PAYMENTS_LOADING:
      return {
        ...state,
        additionalPaymentsLoading: action.payload
      }

    case TEACHERS_ACTIONS.SET_ADDITIONAL_PAYMENTS:
      return {
        ...state,
        additionalPayments: action.payload
      }

    case TEACHERS_ACTIONS.SET_ADDITIONAL_PAYMENTS_TOTAL:
      return {
        ...state,
        additionalPaymentsTotal: action.payload
      }

    case TEACHERS_ACTIONS.SET_ADDITIONAL_PAYMENTS_FILTERS:
      return {
        ...state,
        additionalPaymentsFilters: action.payload
      }

    case TEACHERS_ACTIONS.SET_ADDITIONAL_PAYMENTS_PAGINATION:
      return {
        ...state,
        additionalPaymentsPagination: action.payload
      }

    case TEACHERS_ACTIONS.SET_ADDITIONAL_PAYMENTS_SORTER:
      return {
        ...state,
        additionalPaymentsSorter: action.payload
      }

    case TEACHERS_ACTIONS.SET_CERTIFICATION:
      return {
        ...state,
        certification: action.data
      }
    case TEACHERS_ACTIONS.SET_QUALIFICATION_LEVELS:
      return {
        ...state,
        qualificationLevels: action.payload
      }

    case TEACHERS_ACTIONS.SET_COURSES_LIST:
      return {
        ...state,
        teachersCourses: action.payload
      }

    case TEACHERS_ACTIONS.SET_CURRENT_PROFILE_SCHOOL:
      return {
        ...state,
        currentProfileSchool: action.payload
      }

    case TEACHERS_ACTIONS.SET_ADDITIONAL_PAYMENT_MODAL_OPEN:
      return {
        ...state,
        addPaymentModalOpen: action.payload
      }

    case TEACHERS_ACTIONS.SET_SCHOOL_ADDITIONAL_PRICE:
      return {
        ...state,
        schoolAdditionalPrice: action.payload
      }

    case TEACHERS_ACTIONS.SET_ADDITIONAL_PAYMENTS_SETTINGS:
      return {
        ...state,
        additionalPaymentsSettings: action.payload
      }
    case TEACHERS_ACTIONS.SET_ADDITIONAL_PAYMENTS_SETTINGS_LOADING:
      return {
        ...state,
        additionalPaymentsSettingsLoading: action.payload
      }

    default:
      return state
  }
}
