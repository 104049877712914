import React from 'react';
import { withRouter } from 'react-router-dom';
import { notification } from 'antd';
import i18n from 'i18n-js';

class ErrorBoundary extends React.Component {
  state = {
    hasError: false
  };

  componentDidCatch (error, info) {
    if (error) {
      this.setState({ hasError: true });
    }
  }

  componentDidUpdate (prevProps, prevState) {
    const { location } = this.props;

    if (location !== prevProps.location) {
      this.setState({ hasError: false });
    }
  }

  render () {
    const { hasError } = this.state;
    const { children } = this.props;

    if (hasError) {
      notification.error({message: i18n.t('general.notification.error')});
    }

    return children;
  }
}

export default withRouter(ErrorBoundary);