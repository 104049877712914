import i18n, { t } from 'i18n-js'
import { fallbacks, locale, translations } from '../assets/locale/locale'
import ua from 'antd/lib/locale/uk_UA'
import en from 'antd/lib/locale/en_US'
import ru from 'antd/lib/locale/ru_RU'
import rom from 'antd/lib/locale/ro_RO'

i18n.fallbacks = fallbacks
i18n.translations = translations
i18n.locale = locale
i18n.defaultLocale = locale

export const eventTypes = {
  SMS: i18n.t('eventTypes.SMS'),
  EMAIL: i18n.t('eventTypes.email'),
  VIBER: i18n.t('eventTypes.Viber'),
  VISIT: i18n.t('eventTypes.visit'),
  CALL: i18n.t('eventTypes.call'),
  COMMENT: i18n.t('eventTypes.comment'),
  UPDATE_COMMENT: i18n.t('eventTypes.updateComment'),
  CHANGE_STATUS: i18n.t('eventTypes.changeStatus'),
  CREATE_LEAD: i18n.t('eventTypes.createLead'),
  BLACK_LIST: i18n.t('eventTypes.blackList'),
  CLIENT_LEAD_CHANGE_SCHOOL: i18n.t('eventTypes.changeSchool'),
  PAYMENT: i18n.t('eventTypes.payment'),
  REMINDER: i18n.t('eventTypes.reminder')
}

export const STATUS = {
  ALL: 'all',
  NEW: 'new',
  IN_WORK: 'in_work',
  FINISHED: 'finished'
}

export const ADULT_STATUS = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
  SCHOOL_LEAVER: 'SCHOOL_LEAVER'
}

export const IN_COURSE_STUDENT_STATUS = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'IN_ACTIVE'
}

export const IN_GROUP_STUDENT_STATUS = {
  GUEST: 'GUEST',
  WORK_OFF: 'WORKING_OFF',
  PERMANENT: 'PERMANENT',
  WAITING_AREA: 'WAITING_ZONE',
  WORK_OFF_QUEUE: 'WORK_OFF_QUEUE',
  DISCONNECTED: 'TURNED_OFF',
  SINGLE: 'ONE_TIME_ATTENDING'
}

export const SUBSTATUS = {
  DISABLED: 'disabled',
  ALL: 'all',
  CREATED: 'created',
  CONTACT_1: 'contact_1',
  CONTACT_2: 'contact_2',
  CONTACT_3: 'contact_3',
  GUEST_STUDENT: 'guest_student',
  PENDING: 'pending',
  PAID_TRIAL_LESSON: 'paid_trial_lesson',
  ATTENDED_TRIAL_LESSON: 'attended_trial_lesson',
  NON_ACTUAL: 'non_actual',
  NON_TARGETED: 'non_targeted',
  TECHNICAL: 'technical',
  PAID_SUBSCRIPTION: 'paid_subscription'
}

export const ROLES = {
  ROLE_TEACHER: 'ROLE_TEACHER',
  ROLE_MANAGER: 'ROLE_MANAGER',
  SCHOOL_GROUP_ADMIN: 'SCHOOL_GROUP_ADMIN',
  ROLE_DIRECTOR: 'ROLE_DIRECTOR',
  ROLE_ADMIN: 'ROLE_ADMIN',
  ROLE_USER: 'ROLE_USER',
  ROLE_CEO: 'ROLE_CEO',
  ROLE_SCHOOL_GROUP_DIRECTOR: 'ROLE_SCHOOL_GROUP_DIRECTOR',
  SCHOOL_GROUP_DIRECTOR: 'ROLE_SCHOOL_GROUP_DIRECTOR',
  ROLE_FRANCHISE_MANAGER: 'ROLE_FRANCHISE_MANAGER',
  ROLE_CFO: 'ROLE_CFO', // Financial director
  ROLE_METHODIST: 'ROLE_METHODIST'
}

export const DISTRIBUTION_CHANNEL = {
  SMS: 'SMS',
  VIBER: 'VIBER',
  EMAIL: 'EMAIL'
}

export const DISTRIBUTION_SCHEDULE_OPTION = {
  IMMEDIATELY: 'immediately',
  FOR_SPECIFIED_TIME: 'forSpecifiedTime'
}

export const DISTRIBUTION_STATUS = {
  PLANNED: 'PLANNED',
  NOT_PLANNED: 'NOT_PLANNED',
  SENT: 'SENT',
  IN_PROCESS: 'IN_PROCESS'
}

export const CUSTOMER = {
  CLIENT: 'client',
  LEAD: 'lead',
  NEW_CLIENT: 'lead'
}

export const CUSTOMER_RELATIONSHIP = {
  NEW: 'new',
  ACTIVE: 'active'
}

export const PARENT_TYPES = {
  FATHER: 'father',
  MOTHER: 'mother',
  OTHER: 'other'
}

// local storage value
export const LS_VALUES = {
  LEADS_TABLE_LAST_ACTIVITY_SORT: 'leads_table_last_activity_sort',
  LEADS_TABLE_REG_DATE_FROM: 'leads_table_reg_date_from',
  LEADS_TABLE_REG_DATE_TO: 'leads_table_reg_date_to',
  CLIENTS_TABLE_FILTERS_LIST: 'clients_table_filters_list',
  LEFT_MENU_HOVERED: 'left_menu_hovered'
}

export const minEventsAmount = 30

export const minPlannedEvents = 5

export const FIELD_TYPES = {
  TEXT: 'text',
  TEXTAREA: 'textArea',
  NUMBER: 'number',
  SELECT: 'select',
  MULTIPLE_SELECT: 'multipleSelect',
  BOOLEAN: 'boolean',
  DATE: 'date',
  DATE_TIME: 'dateTime',
  LINK: 'link'
}

export const CUSTOM_FIELD_ENTITIES = {
  employeeGeneralInfoTeacher: 'employeeGeneralInfoTeacher',
  employeeGeneralInfoManager: 'employeeGeneralInfoManager',
  employeeDetailedInfoTeacher: 'employeeDetailedInfoTeacher',
  employeeDetailedInfoManager: 'employeeDetailedInfoManager',
  student: 'student',
  parent: 'parent'
}

export const CUSTOM_FIELD_VALUES = {
  text: 'textValue',
  textArea: 'textAreaValue',
  number: 'numberValue',
  select: 'selectionOptions',
  multipleSelect: 'selectionOptions',
  boolean: 'booleanValue',
  date: 'dateValue',
  dateTime: 'dateTimeValue',
  link: 'linkValue'
}

export const LOCALE_TO_MOMENT = {
  ua: 'uk_UA',
  ru: 'ru',
  en: 'en',
  rom: 'ro'
}

export const ANT_LOCALES = {
  ua,
  ru,
  rom,
  en
}

export const ABSENCE_MARKS = {
  ABSENT_WITHOUT_CAUSE: 'ABSENT',
  ABSENT_BY_CAUSE: 'ABSENT_IMPORTANT',
  PRESENT: 'PRESENT',
  NOT_STARTED: 'NOT_STARTED'
}

export const STATUSES_ON_LESSON = {
  REWORK: 'REWORK',
  PERMANENT: 'PERMANENT',
  GUEST: 'GUEST',
  ONE_TIME_ATTENDING: 'ONE_TIME_ATTENDING'
}

export const GROUP_STATUS = {
  PERMANENT: 'PERMANENT',
  WORKING_OFF: 'WORKING_OFF',
  ONE_TIME_ATTENDING: 'ONE_TIME_ATTENDING',
  GUEST: 'GUEST',
  WAITING_ZONE: 'WAITING_ZONE',
  WORK_OFF_QUEUE: 'WORK_OFF_QUEUE',
  TURNED_OFF: 'TURNED_OFF',
  TRANSITION: 'TRANSITION'
}

export const PAYMENT_STATUS = {
  PAYED: 'PAYED',
  NOT_PAYED: 'NOT_PAYED',
  PREPAYED: 'PREPAYED',
  DEPOSIT: 'DEPOSIT'
}

export const TRANSFER_ACTIONS = {
  GUESTS: 'GUESTS',
  TRANSFER: 'TRANSFER',
  WAITING_ZONE: 'WAITING_ZONE',
  WORK_OFF_QUEUE: 'WORK_OFF_QUEUE'
}

export const GROUP_FILTERS = {
  ALL_STUDENTS: 'ALL_STUDENTS',
  PERMANENT: 'PERMANENT',
  WORKING_OFF: 'WORKING_OFF',
  ONE_TIME_ATTENDING: 'ONE_TIME_ATTENDING',
  GUEST: 'GUEST',
  WAITING_ZONE: 'WAITING_ZONE',
  WORK_OFF_QUEUE: 'WORK_OFF_QUEUE',
  TURNED_OFF: 'TURNED_OFF'
}

export const GROUP_STATUS_NAMES = {
  [GROUP_STATUS.PERMANENT]: i18n.t('GroupFilters.PERMANENT'),
  [GROUP_STATUS.WORKING_OFF]: i18n.t('GroupFilters.WORKING_OFF'),
  [GROUP_STATUS.ONE_TIME_ATTENDING]: i18n.t('GroupFilters.ONE_TIME_ATTENDING'),
  [GROUP_STATUS.GUEST]: i18n.t('GroupFilters.GUEST'),
  [GROUP_STATUS.WAITING_ZONE]: i18n.t('GroupFilters.WAITING_ZONE'),
  [GROUP_STATUS.WORK_OFF_QUEUE]: i18n.t('GroupFilters.WORK_OFF_QUEUE'),
  [GROUP_STATUS.TURNED_OFF]: i18n.t('GroupFilters.TURNED_OFF'),
  [GROUP_FILTERS.ALL_STUDENTS]: i18n.t('GroupFilters.ALL_STUDENTS')
}

export const LEVELS = {
  JUNIOR: 'junior',
  SENIOR: 'senior',
  MIDDLE: 'middle'
}

export const WEEKDAYS = {
  Mo: 0,
  Tu: 1,
  We: 2,
  Th: 3,
  Fr: 4,
  Sa: 5,
  Su: 6
}

export const LESSON_CLOSE_ERRORS = {
  ALREADY_CLOSED: 'ALREADY_CLOSED',
  NOT_STARTED: 'NOT_STARTED',
  TOO_LATE: 'TOO_LATE',
  ABSENT_MARK_EMPTY: 'ABSENT_MARK_EMPTY',
  STEM_MARK_EMPTY: 'STEM_MARK_EMPTY',
  PERMISSION_DENIED: 'PERMISSION_DENIED'
}

export const PROMO_CODE_TYPES = {
  ABSOLUTE: 'ABSOLUTE',
  PERCENT: 'PERCENT'
}

export const PRODUCT_TYPES = {
  PRODUCT: 'PRODUCT',
  SERVICE: 'SERVICE',
  NONE: 'NONE'
}

export const CODE_ACTIONS = {
  GENERATE: 'GENERATE',
  ENTER: 'ENTER'
}

export const STATUSES = {
  ACTIVE: 'ACTIVE',
  NON_ACTIVE: 'NON_ACTIVE'
}

export const PROMO_CODE_LINKS = {
  USER: 'USER',
  SCHOOL: 'SCHOOL',
  SCHOOL_GROUP: 'SCHOOL_GROUP',
  NOTIFICATION: 'NOTIFICATION',
  FAMILY_GROUP: 'FAMILY_GROUP'
}

export const DATE_FORMATS = {
  WESTERN: 'YYYY-MM-DD',
  EASTERN: 'DD.MM.YYYY'
}

export const SORT_ORDERS = {
  DESCEND: 'descend',
  ASCEND: 'ascend'
}

export const weekdaysForMoment = {
  mo: 1,
  tu: 2,
  we: 3,
  th: 4,
  fr: 5,
  sa: 6,
  su: 0
}

export const CALL_STATUSES = {
  UNCLOSED: 'UNCLOSED',
  MISS_CALL: 'LOST',
  CALLING: 'WAITING',
  IN_PROCESS: 'ACTIVE',
  MISS_CALL_VOICE_MAIL: 'VOICE_MAIL',
  CANCELED: 'NEW'
}

export const CALL_STYLES = {
  [CALL_STATUSES.UNCLOSED]: { background: '#838383' },
  [CALL_STATUSES.MISS_CALL]: { background: '#F54819' },
  [CALL_STATUSES.CALLING]: { background: '#FFA500' },
  [CALL_STATUSES.IN_PROCESS]: { background: '#008000' },
  [CALL_STATUSES.MISS_CALL_VOICE_MAIL]: {
    background: '#F54819',
    border: '2px solid black'
  },
  [CALL_STATUSES.CANCELED]: { background: '#0070B9' }
}

export const CALL_TYPES = {
  INCOMING: 'INCOMING',
  OUTGOING: 'OUTGOING'
}

export const CLIENT_TYPES = {
  EXISTING: 'EXISTING',
  NEW: 'NEW'
}

export const ORDER_STATUSES = {
  ACTIVE: 'ACTIVE',
  IN_PROCESS: 'IN_PROCESS',
  PAID: 'PAID',
  ARCHIVED: 'ARCHIVED',
  EDITED: 'EDITED',
  ONLINE: 'ONLINE'
}

export const ORDER_STATUSES_NAMES = {
  [ORDER_STATUSES.ACTIVE]: t('Finances.statusActive'),
  [ORDER_STATUSES.IN_PROCESS]: t('Finances.statusInProgress'),
  [ORDER_STATUSES.PAID]: t('Finances.statusPaid'),
  [ORDER_STATUSES.ARCHIVED]: t('Finances.statusArchive'),
  [ORDER_STATUSES.EDITED]: t('Finances.statusEdited')
}

export const TEMPLATE_FILE_NAMES = {
  DISCOUNTS: 'Template_Discounts.xlsx',
  PROMO_CODES: 'Template_PromoCodes.xlsx',
  SUPPLIERS: 'Template_List_of_FOPs.xlsx',
  PRODUCTS: 'Template_Price_Goods.xlsx',
  SERVICES: 'Template_Price_Services.xlsx',
  GENERAL_PRICE: 'Template_EmployeeTariffMain.xlsx',
  ADDITIONAL_PRICE: 'Template_EmployeeTariffAdditional.xlsx'
}
export const certificationEventTypes = {
  PROFILE_CREATED: 'profileCreation',
  EDUCATION_STARTED: 'educationStart',
  EDUCATION_REFUSED: 'educationRefusal',
  SUCCESSFUL_TEST: 'successfulTest',
  UNSUCCESSFUL_TEST: 'unsuccessfulTest',
  CERT_FIRST_LEVEL: 'certificationFirstLevel',
  CERT_SECOND_LEVEL: 'certificationSecondLevel',
  CERT_THIRD_LEVEL: 'certificationThirdLevel',
  CERT_FOURTH_LEVEL: 'certificationFourthLevel',
  CERT_CANCELLED: 'certificationCancellation',
  FIRED: 'quit'
}

export const SOURCES = [
  {
    type: 'PHONE_CALL_REASON_RESULT',
    key: 'calls_and_results',
    name: 'callsAndResults',
    hasSubItems: true
  },
  {
    type: 'STUDIO_SOURCE',
    key: 'studio_knowledge',
    name: 'studioKnowledge'
  },
  {
    type: 'DEACTIVATION_REASON',
    key: 'reason_for_deactivation',
    name: 'reasonForDeactivation'
  },
  {
    type: 'STUDENT_TURN_OFF_REASON',
    key: 'reason_for_student_suspension',
    name: 'reasonForStudentSuspension'
  }
]

export const ACCOUNT_TYPES = {
  TURBO_SMS_VIBER: 'turbo_sms_sms',
  TURBO_SMS_SMS: 'turbo_sms_viber',
  SPUTNIK_EMAIL: 'esputnik_email'
}
