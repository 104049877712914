import React from 'react';
import moment from 'moment';
import i18n from 'i18n-js';
import {
  MailOutlined, CommentOutlined, MessageOutlined, ClockCircleOutlined,
  PhoneOutlined, CheckCircleOutlined, LikeOutlined, UserSwitchOutlined,
  DollarOutlined, UnorderedListOutlined
} from '@ant-design/icons';
import { ReactComponent as Lead } from '../../../../PagesMenuSortable/PagesMenu/PageMenuItem/PageMenuItemIcon/icons/leeds.svg';
import { eventTypes } from '../../../../../constants/text';
import { COMMUNICATION_CHANNELS_NAMES } from './utils';

export const setEventIcon = (type) => {
  switch (type) {
    case eventTypes.COMMENT :
    case eventTypes.UPDATE_COMMENT :
      return <MessageOutlined/>;
    case eventTypes.waiting:
      return <ClockCircleOutlined/>;
    case eventTypes.CALL:
      return <PhoneOutlined/>;
    case eventTypes.EMAIL:
      return <MailOutlined/>;
    case eventTypes.task:
      return <CheckCircleOutlined/>;
    case eventTypes.SMS:
      return <CommentOutlined/>;
    case eventTypes.VIBER:
      return <CommentOutlined/>;
    case eventTypes.VISIT:
      return <LikeOutlined/>;
    case eventTypes.CHANGE_STATUS:
      return <UserSwitchOutlined/>;
    case eventTypes.BLACK_LIST:
      return <UnorderedListOutlined />;
    case eventTypes.CREATE_LEAD:
      return <Lead/>;
    case eventTypes.PAYMENT:
      return <DollarOutlined />;
    default:
      break;
  }
};

export const getEventsByDates = (events) => {
  const eventsByDates = [];

  if (events && events.length) {
    let startDate;

    events.forEach(item => {
      startDate = moment(item.date, 'DD.MM.YYYY H:mm A').format('DD.MM.YYYY');

      if (!eventsByDates.find(event => moment(event.date, 'DD.MM.YYYY H:mm').format('DD.MM.YYYY') === startDate)) {
        const filteredEvents = events.filter(event => moment(event.date, 'DD.MM.YYYY H:mm').format('DD.MM.YYYY') === startDate);
        const sortedEvents = filteredEvents.sort((a, b) => moment(b.date, 'DD.MM.YYYY H:mm:ss').toDate() - moment(a.date, 'DD.MM.YYYY H:mm:ss').toDate());
        const eventsForOneDate = {
          date: startDate,
          events: sortedEvents
        };
        eventsByDates.push(eventsForOneDate);
      }
    });
  }
  return eventsByDates;
};

export const getDateView = (date) => {
  const today = moment().format('DD.MM.YYYY');
  const yesterday = moment().subtract(1, 'days').format('DD.MM.YYYY');

  if (date === today) return i18n.t('LeedActions.today');
  if (date === yesterday) return i18n.t('LeedActions.yesterday');
  return date;
};

export const getEventsForRender = events => {
  let updatedEvents = [];

  if (events && events.length) {
    updatedEvents = events.map(item => {
      return {
        id: item.id,
        type: eventTypes[item.type],
        date: moment(item.createdAt).utc().format('DD.MM.YYYY H:mm:ss'),
        createdAt: item.createdAt,
        text: item.content,
        author: item.author,
        topic: item.conversationTopic,
        files: item.files || [],
        modifiedAt: item.modifiedAt,
        oldStatus: item.oldStatus,
        currentStatus: item.currentStatus,
        communicationChannel: COMMUNICATION_CHANNELS_NAMES[item.communicationChannel],
        phone: item.phone,
        email: item.email,
        orderBriefInfoVM: item.orderBriefInfoVM,
        callType: item.callType
      };
    }).sort((a, b) => moment(b.createdAt).diff(a.createdAt));
  }
  return updatedEvents;
};