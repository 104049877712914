import { BASE_PAYMENT_SERVICE_URL } from '../constants/api';
import axios from 'axios';

export const getServices = async (schoolId, { size, page, sort, isGuest, ...rest}) => {
  let url = `${BASE_PAYMENT_SERVICE_URL}/api/servicePrice/filter?`;

  if (page !== undefined) {
    url += `&page=${page}`;
  }
  if (size !== undefined) {
    url += `&size=${size}`;
  }
  const response = await axios.post(url, { schoolId, type: isGuest ? 'TRIAL' : undefined, ...rest });

  return response && response.data ? {
    list: response?.data?.data?.content || [],
    total: response?.data?.data?.totalElements || 0,
    current: (response?.data?.data?.number || 0) + 1
  } : {list: [], total: 0};
};

export const updateService = async (id, params) => {
  const url = `${BASE_PAYMENT_SERVICE_URL}/api/servicePrice/${id}`;
  const response = await axios.put(url, params);
  return response && response.data && response.data.data ? response.data.data : null;
};

export const createService = async (schoolId, params) => {
  const url = `${BASE_PAYMENT_SERVICE_URL}/api/servicePrice`;
  const response = await axios.post(url, { schoolId, ...params });
  return response && response.data && response.data.data ? response.data.data : null;
};

export const deleteService = async (serviceId) => {
  const url = `${BASE_PAYMENT_SERVICE_URL}/api/servicePrice/${serviceId}`;
  const response = await axios.delete(url);
  return response && response.data && response.data.data ? response.data.data : null;
};

export const getServicePricesByFilters = async filters => {
  const url = `${BASE_PAYMENT_SERVICE_URL}/api/servicePrice/filter`;
  const response = await axios.post(url, filters);
  return response && response.data && response.data.data ? response.data.data : null;
};

export const getServicesLastUpdated = async schoolId => {
  const url = `${BASE_PAYMENT_SERVICE_URL}/api/servicePrice/lastUpdatedDate?schoolId=${schoolId}`;
  const response = await axios.get(url);
  return response && response.data && response.data.data ? response.data.data : null;
};

export const uploadPrice = async (data, startDate) => {
  const url = `${BASE_PAYMENT_SERVICE_URL}/api/servicePrice/upload?startDate=${startDate}`;
  return axios.post(url, data);
};
