import { ACTION } from '../actions/types'

const initialState = {
  comments: [],
  motivation: {},
  spinnerVisible: false,
  allPointsAmount: 0,
  availablePointsAmount: 0
}

export const STEMReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION.SET_STUDENT_STEM_COMMENTS:
      return {
        ...state,
        comments: action.payload
      }

    case ACTION.SET_STEM_MOTIVATION_DATA:
      return {
        ...state,
        motivation: action.payload?.achievements,
        allPointsAmount: action.payload?.allPointsAmount,
        availablePointsAmount: action.payload?.availablePointsAmount
      }

    case ACTION.SET_STEM_SPINNER:
      return {
        ...state,
        spinnerVisible: action.payload
      }
    default:
      return state
  }
}
