import { ACTION } from '../actions/types';
import { LS_VALUES } from '../../constants/text';

const initialState = {
  leftMenuHovered: JSON.parse(localStorage.getItem(LS_VALUES.LEFT_MENU_HOVERED)) || false,
  pagesMenuExpanded: false,
  pagesMenu: {},
  pagesMenuDownloaded: null,
  spinnerVisible: false,
};

export const layoutReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION.SET_LEFT_MENU_HOVERED:
      return {
        ...state,
        leftMenuHovered: action.hovered,
      };

    case ACTION.TOGGLE_PAGES_MENU_EXPANDED:
      return {
        ...state,
        pagesMenuExpanded: !state.pagesMenuExpanded,
      };

    case ACTION.SET_PAGES_MENU:
      return {
        ...state,
        pagesMenu: action.pagesMenu,
      };

    case ACTION.SET_PAGES_MENU_DOWNLOADED:
      return {
        ...state,
        pagesMenuDownloaded: action.downloaded,
      };

    case ACTION.SET_SPINNER_VISIBLE:
      return {
        ...state,
        spinnerVisible: action.payload,
      };

    default:
      return state;
  }
};
