export const ACTION = {
  SET_CURRENT_USER: 'SET_CURRENT_USER',
  SET_CURRENT_LANGUAGE: 'SET_CURRENT_LANGUAGE',
  SET_SCHOOLS_GROUP_LIST: 'SET_SCHOOLS_GROUP_LIST',
  /* --- sign in actions START --- */
  SHOW_SIGNIN_ERROR: 'SHOW_SIGNIN_ERROR',
  SET_SIGNIN_ERROR_TEXT: 'SET_SIGNIN_ERROR_TEXT',
  SHOW_RESET_PSWD_ALERT: 'SHOW_RESET_PSWD_ALERT',
  SET_RESET_PSWD_ALERT_TEXT: 'SET_RESET_PSWD_ALERT_TEXT',
  /* --- sign in actions END --- */
  /* --- menu actions START --- */
  SET_LEFT_MENU_HOVERED: 'SET_LEFT_MENU_HOVERED',
  TOGGLE_PAGES_MENU_EXPANDED: 'TOGGLE_PAGES_MENU_EXPANDED',
  SET_PAGES_MENU: 'SET_PAGES_MENU',
  SET_PAGES_MENU_DOWNLOADED: 'SET_PAGES_MENU_DOWNLOADED',
  /* --- menu actions END --- */
  /* --- leads actions START --- */
  LOAD_LEEDS_DATA: 'LOAD_LEEDS_DATA',
  SET_LEEDS_STATUS_FILTER: 'SET_LEEDS_STATUS_FILTER',
  SET_IN_PROGRESS_LEEDS_SUBSTATUS_FILTER: 'SET_IN_PROGRESS_LEEDS_SUBSTATUS_FILTER',
  SET_COMPLETED_LEEDS_SUBSTATUS_FILTER: 'SET_COMPLETED_LEEDS_SUBSTATUS_FILTER',
  SET_LEEDS_PAGINATION_VALUE: 'SET_LEEDS_PAGINATION_VALUE',
  SET_IN_PROGRESS_LEEDS_POPUP_VISIBLE: 'SET_IN_PROGRESS_LEEDS_POPUP_VISIBLE',
  SET_IN_PROGRESS_LEED_SUBSTATUS_VALUE: 'SET_IN_PROGRESS_LEED_SUBSTATUS_VALUE',
  SET_COMPLETED_LEEDS_POPUP_VISIBLE: 'SET_COMPLETED_LEEDS_POPUP_VISIBLE',
  SET_COMPLETED_LEED_SUBSTATUS_VALUE: 'SET_COMPLETED_LEED_SUBSTATUS_VALUE',
  SET_SELECTED_LEEDS_IDS: 'SET_SELECTED_LEEDS_IDS',
  SET_LEEDS_DATA_IS_LOADING: 'SET_LEEDS_DATA_IS_LOADING',
  SET_LEEDS_DATA: 'SET_LEEDS_DATA',
  SET_OPTIONS_TO_LOAD_LEADS: 'SET_OPTIONS_TO_LOAD_LEADS',
  SET_LOAD_LEEDS_DATA_ERROR: 'SET_LOAD_LEEDS_DATA_ERROR',
  SET_LEEDS_TABLE_REG_RANGE_DATE_FILTER: 'SET_LEEDS_TABLE_REG_RANGE_DATE__FILTER',
  SET_LEEDS_TABLE_ADDITIONAL_FILTER: 'SET_LEEDS_TABLE_ADDITIONAL_FILTER',
  SET_LEEDS_TABLE_LAST_ACTIVITY_SORT: 'SET_LEEDS_TABLE_LAST_ACTIVITY_SORT',
  SET_LEEDS_TABLE_PHONE_FILTER: 'SET_LEEDS_TABLE_PHONE_FILTER',
  SET_LEEDS_TABLE_CUSTOMER_TYPE_FILTER: 'SET_LEEDS_TABLE_CUSTOMER_TYPE_FILTER',
  SET_LEEDS_TABLE_SCHOOLS_FILTER: 'SET_LEEDS_TABLE_SCHOOLS_FILTER',
  SET_LEEDS_TABLE_IN_BLACK_LIST_FILTER: 'SET_LEEDS_TABLE_IN_BLACK_LIST_FILTER',
  SET_LEEDS_GROUP_ACTION_STATUS: 'SET_LEEDS_GROUP_ACTION_STATUS',
  SET_LEEDS_GROUP_ACTION_IN_PROGRESS_SUBSTATUS: 'SET_LEEDS_GROUP_ACTION_IN_PROGRESS_SUBSTATUS',
  SET_LEEDS_GROUP_ACTION_COMPLETED_SUBSTATUS: 'SET_LEEDS_GROUP_ACTION_COMPLETED_SUBSTATUS',
  SET_LEEDS_TABLE_SETTINGS_DRAWER_IS_VISIBLE: 'SET_LEEDS_TABLE_SETTINGS_DRAWER_IS_VISIBLE',
  SET_LEEDS_COUNT: 'SET_LEEDS_COUNT',
  SET_LEED_TO_UPDATE_STATUS_SUBSTATUS: 'SET_LEED_TO_UPDATE_STATUS_SUBSTATUS',
  SET_LEED_NEW_STATUS_SUBSTATUS: 'SET_LEED_NEW_STATUS_SUBSTATUS',
  SET_LEED_NEW_STATUS_SUBSTATUS_IS_SUBMITTING: 'SET_LEED_NEW_STATUS_SUBSTATUS_IS_SUBMITTING',
  SET_LEED_POSSIBLE_SCHOOLS: 'SET_LEED_POSSIBLE_SCHOOLS',
  DELETE_LEEDS: 'DELETE_LEEDS',
  SET_DELETE_LEEDS_IS_SUBMITTING: 'SET_DELETE_LEEDS_IS_SUBMITTING',
  SET_SELECTED_LEEDS: 'SET_SELECTED_LEEDS',
  SET_SELECTED_LEEDS_STATUS_SUBSTATUS: 'SET_SELECTED_LEEDS_STATUS_SUBSTATUS',
  SET_SELECTED_LEEDS_NEW_STATUS_SUBSTATUS_IS_SUBMITTING: 'SET_SELECTED_LEEDS_NEW_STATUS_SUBSTATUS_IS_SUBMITTING',
  SET_CONFIRM_DELETE_LEEDS_POPUP_IS_VISIBLE: 'SET_CONFIRM_DELETE_LEEDS_POPUP_IS_VISIBLE',
  SET_SELECTED_LEED_SPINNER: 'SET_SELECTED_LEED_SPINNER',
  SET_EVENT_TYPES: 'SET_EVENT_TYPES',
  SET_TOTAL_LEED_RECORDS: 'SET_TOTAL_LEED_RECORDS',
  SET_LEED_TABLE_LOADING: 'SET_LEED_TABLE_LOADING',
  LEED_PARENT_EDIT_MODE: 'SET_LEED_PARENT_EDIT_MODE',
  /* --- leads actions END --- */
  /* --- new leads actions START --- */
  CREATE_NEW_LEED: 'CREATE_NEW_LEED',
  SET_NEW_LEED_DATA_IS_SUBMITTING: 'SET_NEW_LEED_DATA_IS_SUBMITTING',
  SET_NEW_LEED_DATA_SUBMIT_ERROR: 'SET_NEW_LEED_DATA_SUBMIT_ERROR',
  SET_NEW_LEED_POPUP_VISIBLE: 'SET_NEW_LEED_POPUP_VISIBLE',
  SET_NEW_LEED_FIELD_DRAWER_IS_VISIBLE: 'SET_NEW_LEED_FIELD_DRAWER_IS_VISIBLE',
  CREATE_NEW_LEED_FIELD: 'CREATE_NEW_LEED_FIELD',
  SET_NEW_LEED_FIELD_CREATE_ERROR: 'SET_NEW_LEED_FIELD_CREATE_ERROR',
  SET_NEW_LEED_FIELD_IS_SUBMITTING: 'SET_NEW_LEED_FIELD_IS_SUBMITTING',
  SET_SIMILAR_LEADS_AND_CLIENTS: 'SET_SIMILAR_LEADS_AND_CLIENTS',
  SET_NEW_LEED_POPUP_DEFAULT_VALUES: 'SET_NEW_LEED_POPUP_DEFAULT_VALUES',
  /* --- new leads actions END--- */
  /* --- teacher profile actions START --- */
  SET_AVATAR: 'SET_AVATAR',
  SET_LOADING_SCHEDULE: 'SET_LOADING_SCHEDULE',
  SET_PROFILE_CURRENT_ROLE: 'SET_PROFILE_CURRENT_ROLE',
  SET_PROFILE_MAIN_DATA: 'SET_PROFILE_MAIN_DATA',
  SET_PROFILE_DETAILED_DATA: 'SET_PROFILE_DETAILED_DATA',
  SET_EMPLOYMENT_DATA: 'SET_EMPLOYMENT_DATA',
  SET_MODAL_OPEN: 'SET_MODAL_OPEN',
  SET_FINANCIAL_SETTINGS: 'SET_FINANCIAL_SETTINGS',
  SET_MONITORING: 'SET_MONITORING',
  SET_MENTOR_DATA: 'SET_MENTOR_DATA',
  SET_CHOSEN_COURSE: 'SET_CHOSEN_COURSE',
  SET_MOTIVATION: 'SET_MOTIVATION',
  SET_SUMMARY_TABLE_COURSE: 'SET_SUMMARY_TABLE_COURSE',
  SET_CURRENT_EMPLOYEE: 'SET_CURRENT_EMPLOYEE',
  SET_CURRENT_SCHOOL_ID: 'SET_CURRENT_SCHOOL_ID',
  SET_SCHOOLS_WORKS_IN: 'SET_SCHOOLS_WORKS_IN',
  SET_PROFILE_NOTIFICATION: 'SET_PROFILE_NOTIFICATION',
  SET_TEACHERS_FOR_REPLACE: 'SET_TEACHERS_FOR_REPLACE',
  /* --- teacher profile actions END --- */
  /* --- selected lead actions START --- */
  SET_SELECTED_LEED_DATA: 'SET_SELECTED_LEED_DATA',
  SET_LEED_COMPLETED_EVENTS: 'SET_LEED_COMPLETED_EVENTS',
  SET_LEED_PLANNED_EVENTS: 'SET_LEED_PLANNED_EVENTS',
  SET_SELECTED_LEED_PARENTS: 'SET_SELECTED_LEED_PARENTS',
  SET_SELECTED_LEED_CHILDREN: 'SET_SELECTED_LEED_CHILDREN',
  GET_LEED_ADDITIONAL_FIELDS: 'GET_LEED_ADDITIONAL_FIELDS',
  SET_LEED_ADDITIONAL_FIELDS: 'SET_LEED_ADDITIONAL_FIELDS',
  SET_LEED_ADDITIONAL_FIELDS_DOWNLOADED: 'SET_LEED_ADDITIONAL_FIELDS_DOWNLOADED',
  SET_LEAD_COMMENTS: 'SET_LEAD_COMMENTS',
  /* --- selected lead actions END --- */
  // Clients types start
  SET_PAGINATION_CONFIG: 'SET_PAGINATION_CONFIG',
  SET_KANBAN_PAGINATION_CONFIG: 'SET_KANBAN_PAGINATION_CONFIG',
  SET_KANBAN_STATUS_BADGES: 'SET_KANBAN_STATUS_BADGES',
  SET_IS_LAST_KANBAN_PAGE: 'SET_IS_LAST_KANBAN_PAGE',
  SET_PAGINATION_CONFIG_TOTAL: 'SET_PAGINATION_CONFIG_TOTAL',
  SET_CHILD_PAGINATION_CONFIG: 'SET_CHILD_PAGINATION_CONFIG',
  SET_IS_KANBAN_TYPE: 'SET_IS_KANBAN_TYPE',
  SET_SCHOOL_FILTERS: 'SET_SCHOOL_FILTERS',
  SET_GROUP_FILTERS: 'SET_GROUP_FILTERS',
  SET_CHILD_CARD_IS_OPEN: 'SET_CHILD_CARD_IS_OPEN',
  SET_CHILD_CHARACTERISTIC: 'SET_CHILD_CHARACTERISTIC',
  ADD_NEW_CHILD_CHARACTERISTIC: 'ADD_NEW_CHILD_CHARACTERISTIC',
  SET_CLIENTS_DATA: 'SET_CLIENTS_DATA',
  ADD_CLIENTS_KANBAN_DATA: 'ADD_CLIENTS_KANBAN_DATA',
  SET_CLIENTS_KANBAN_DATA: 'SET_CLIENTS_KANBAN_DATA',
  SET_CLIENTS_FILTERS: 'SET_CLIENTS_FILTERS',
  SET_CLIENTS_COMMUNICATION_DISTRIBUTIONS: 'SET_CLIENTS_COMMUNICATION_DISTRIBUTIONS',
  SET_CLIENTS_COMMUNICATION_MEETINGS: 'SET_CLIENTS_COMMUNICATION_MEETINGS',
  SET_CLIENTS_COMMUNICATION_INFO: 'SET_CLIENTS_COMMUNICATION_INFO',
  SET_CLIENTS_COMMUNICATION_TYPES: 'SET_CLIENTS_COMMUNICATION_TYPES',
  SET_LEARN_PROCESS_DATA: 'SET_LEARN_PROCESS_DATA',
  SET_OTHER_PRODUCTS_DATA: 'SET_OTHER_PRODUCTS_DATA',
  SET_CLIENTS_SCHOOL: 'SET_CLIENTS_SCHOOL',
  SET_CURRENT_CHILD_INFO: 'SET_CURRENT_CHILD_INFO',
  SET_SELECTED_CLIENT: 'SET_SELECTED_CLIENT',
  SET_COMMUNICATION_FILTERS: 'SET_COMMUNICATION_FILTERS',
  SET_CHILD_ID: 'SET_CHILD_ID',
  SET_FAMILY_ID: 'SET_FAMILY_ID',
  SET_STUDENT_STEM_COMMENTS: 'SET_STUDENT_STEM_COMMENTS',
  SET_STEM_MOTIVATION_DATA: 'SET_STEM_MOTIVATION_DATA',
  SET_STEM_SPINNER: 'SET_STEM_SPINNER',
  SET_SELECTED_CLIENTS_KEYS: 'SET_SELECTED_CLIENTS_KEYS',
  SET_GROUP_FILTER_OPTIONS: 'SET_GROUP_FILTER_OPTIONS',
  SET_STEM_HISTORY: 'SET_STEM_HISTORY',
  SET_CUSTOM_FIELDS_CONFIG_POPUP_VISIBLE: 'SET_CUSTOM_FIELDS_CONFIG_POPUP_VISIBLE',
  // Clients types end
  // Schedule types start
  SET_SCHEDULE_FILTER: 'SET_SCHEDULE_FILTER',
  CLEAR_SCHEDULE_FILTER: 'CLEAR_SCHEDULE_FILTER',
  SET_SCHEDULE_DATE: 'SET_SCHEDULE_DATE',
  SET_SCHEDULE_FREE_CLASSROOMS: 'SET_SCHEDULE_FREE_CLASSROOMS',
  SET_SCHEDULE_SCHOOL_ID: 'SET_SCHEDULE_SCHOOL_ID',
  SET_SCHEDULE_EVENTS: 'SET_SCHEDULE_EVENTS',
  SET_SCHEDULE_LESSON: 'SET_SCHEDULE_LESSON',
  REMOVE_SCHEDULE_EVENTS: 'REMOVE_SCHEDULE_EVENTS',
  SET_SCHEDULE_ROOM_EVENTS: 'SET_SCHEDULE_ROOM_EVENTS',
  SET_SCHEDULE_WEEK_CALENDAR_FORM: 'SET_SCHEDULE_WEEK_CALENDAR_FORM',
  SET_SCHEDULE_CALENDAR_TYPE: 'SET_SCHEDULE_CALENDAR_TYPE',
  SET_SCHEDULE_FREE_CLASSROOM_FILTERS: 'SET_SCHEDULE_FREE_CLASSROOM_FILTERS',
  SET_SCHEDULE_FILTER_CLASSROOMS_IDS: 'SET_SCHEDULE_FILTER_CLASSROOMS_IDS',
  SET_SCHEDULE_FILTER_TEACHER_IDS: 'SET_SCHEDULE_FILTER_TEACHER_IDS',
  SET_SCHEDULE_FILTER_COURSES_IDS: 'SET_SCHEDULE_FILTER_COURSES_IDS',
  SET_SCHEDULE_FILTER_SCHOOLS_IDS: 'SET_SCHEDULE_FILTER_SCHOOLS_IDS',
  SET_SCHEDULE_TEACHER_FILTER: 'SET_SCHEDULE_TEACHER_FILTER',
  SET_SCHEDULE_DETAIL_INFO_IS_OPEN: 'SET_SCHEDULE_DETAIL_INFO_IS_OPEN',
  SET_SCHEDULE_SCHOOLS_FILTER: 'SET_SCHEDULE_SCHOOLS_FILTER',
  SET_SCHEDULE_SCHOOL_FILTER: 'SET_SCHEDULE_SCHOOL_FILTER',
  SET_SCHEDULE_CURRENT_FILTER: 'SET_SCHEDULE_CURRENT_FILTER',
  SET_SCHEDULE_COURSE_FILTER: 'SET_SCHEDULE_COURSE_FILTER',
  SET_SCHEDULE_CLASSROOM_FILTER: 'SET_SCHEDULE_CLASSROOM_FILTER',
  SET_SCHEDULE_FREE_CLASSROOM_FILTER: 'SET_SCHEDULE_FREE_CLASSROOM_FILTER',
  SET_SCHEDULE_CLASSROOM_LIST: 'SET_SCHEDULE_CLASSROOM_LIST',
  SET_SCHEDULE_TEACHER_LIST: 'SET_SCHEDULE_TEACHER_LIST',
  SET_SCHEDULE_SCHOOL_GROUP_LIST: 'SET_SCHEDULE_SCHOOL_GROUP_LIST',
  SET_SCHEDULE_COURSES_LIST: 'SET_SCHEDULE_COURSES_LIST',
  // Schedule types end
  // Spinner types start
  SET_SPINNER_VISIBLE: 'SET_SPINNER_VISIBLE',
  // Spinner types end
  // schools actions start
  SET_SCHOOL_GROUP: 'SET_SCHOOL_GROUP',
  SET_SCHOOL_CLASSROOMS: 'SET_SCHOOL_CLASSROOMS',
  SET_CURRENT_SCHOOL_CLASSROOMS: 'SET_CURRENT_SCHOOL_CLASSROOMS',
  SET_GROUP_LESSONS: 'SET_GROUP_LESSONS',
  SET_SCHOOL_FREE_CLASSROOMS_SCHOOL_ID: 'SET_SCHOOL_FREE_CLASSROOMS_SCHOOL_ID',
  SET_ACTIVE_SCHOOL_GROUP: 'SET_ACTIVE_SCHOOL_GROUP',
  SET_SCHOOLS_TREE: 'SET_SCHOOLS_TREE',
  SET_SCHOOL_GROUPS_TREE: 'SET_SCHOOL_GROUPS_TREE',
  SET_CURRENT_SCHOOL_GROUP: 'SET_CURRENT_SCHOOL_GROUP',
  SET_CURRENT_SCHOOL_GROUP_TREE: 'SET_CURRENT_SCHOOL_GROUP_TREE',
  // schools actions end
  SET_NOTIFICATION: 'SET_NOTIFICATION',
  SET_PARENT_TYPES: 'SET_PARENT_TYPES',
  SET_COMMUNICATION_TYPES: 'SET_COMMUNICATION_TYPES',
  SET_SELECTED_EXISTING_PARENT: 'SET_SELECTED_EXISTING_PARENT',
  SET_SELECTED_EXISTING_CHILD: 'SET_SELECTED_EXISTING_CHILD',
  SET_SELECTED_PARENT: 'SET_SELECTED_PARENT',
  SET_SELECTED_CHILD: 'SET_SELECTED_CHILD',
  // employees
  REMOVE_EMPLOYEE: 'REMOVE_EMPLOYEE',
  SET_EMPLOYEES: 'SET_EMPLOYEES',
  SET_EMPLOYEES_PAGINATION_CONFIG: 'SET_EMPLOYEES_PAGINATION_CONFIG',
  SET_EMPLOYEES_PAGINATION_CONFIG_TOTAL: 'SET_EMPLOYEES_PAGINATION_CONFIG_TOTAL',
  SET_EMPLOYEES_FILTERS: 'SET_EMPLOYEES_FILTERS',
  CREATE_EMPLOYEE: 'CREATE_EMPLOYEE',
  SET_EMPLOYEE_INFO: 'SET_EMPLOYEE_INFO',
  SET_PHONE_MASK_FOR_EMPLOYEE: 'SET_PHONE_MASK_FOR_EMPLOYEE',
  RESET_AUTH_INFO: 'RESET_AUTH_INFO'
}

export const ROLE_ACTION = {
  SET_CURRENT_ROLE: 'SET_CURRENT_ROLE',
  CHANGE_USER_ROLE: 'CHANGE_USER_ROLE'
}

export const FAMILY_ACTION = {
  SET_FAMILY: 'SET_FAMILY',
  SET_FAMILY_SCHOOL_GROUP: 'SET_FAMILY_SCHOOL_GROUP',
  SET_CONFIRM_FAMILY_ACTION: 'SET_CONFIRM_FAMILY_ACTION',
  SET_RESPONSIBLE_SCHOOL: 'SET_RESPONSIBLE_SCHOOL',
  SET_MEMBERS_STATE: 'SET_MEMBERS_STATE',
  CLEAR_MEMBERS_STATE: 'CLEAR_MEMBERS_STATE',
  SET_ADVICE_TOPICS: 'SET_ADVICE_TOPICS',
  CHANGE_VISIBLE_ADULT_POPUP: 'CHANGE_VISIBLE_ADULT_POPUP',
  SET_DEFAULT_PROPS_ADULT_POPUP: 'SET_DEFAULT_PROPS_ADULT_POPUP',
  SET_APPLICATION_STUDENT: 'SET_APPLICATION_STUDENT'
}

export const DISTRIBUTION_ACTION = {
  CHANGE_PROMOCODE: 'CHANGE_PROMOCODE',
  SET_CLIENTS_NEW_CLIENTS_FILTER: 'SET_CLIENTS_NEW_CLIENTS_FILTER',
  SET_SCHOOLS_FILTER: 'SET_SCHOOLS_FILTER',
  SET_COURSES_FILTER: 'SET_COURSES_FILTER',
  SET_GROUPS_FILTER: 'SET_GROUPS_FILTER',
  SET_CLIENT_CREATED_AT_FILTER: 'SET_CLIENT_CREATED_AT_FILTER',
  SET_NEW_CLIENT_CREATED_AT_FILTER: 'SET_NEW_CLIENT_CREATED_AT_FILTER',
  SET_AGE_FROM_FILTER: 'SET_AGE_FROM_FILTER',
  SET_AGE_TO_FILTER: 'SET_AGE_TO_FILTER',
  SET_KIDS_COUNT_FILTER: 'SET_KIDS_COUNT_FILTER',
  SET_ADULTS_FILTER: 'SET_ADULTS_FILTER',
  SET_ADULTS_STATUSES_FILTER: 'SET_ADULTS_STATUSES_FILTER',
  SET_LEADS_STATUSES_FILTER: 'SET_LEADS_STATUSES_FILTER',
  SET_IN_COURSE_STUDENTS_STATUSES_FILTER: 'SET_IN_COURSE_STUDENTS_STATUSES_FILTER',
  SET_IN_GROUP_STUDENTS_STATUSES_FILTER: 'SET_IN_GROUP_STUDENTS_STATUSES_FILTER',
  SET_IN_COURSE_STUDENTS_STATUSES_UPDATED_AT_FILTER: 'SET_IN_COURSE_STUDENTS_STATUSES_UPDATED_AT_FILTER',
  SET_IN_GROUP_STUDENTS_STATUSES_UPDATED_AT_FILTER: 'SET_IN_GROUP_STUDENTS_STATUSES_UPDATED_AT_FILTER',
  SET_IN_COURSE_STUDENT_IN_STATUS_AT_FILTER: 'SET_IN_COURSE_STUDENT_IN_STATUS_AT_FILTER',
  SET_IN_GROUP_STUDENT_IN_STATUS_AT_FILTER: 'SET_IN_GROUP_STUDENT_IN_STATUS_AT_FILTER',
  SET_PARENT_IN_STATUS_AT_FILTER: 'SET_PARENT_IN_STATUS_AT_FILTER',
  SET_CHANNEL: 'SET_CHANNEL',
  SET_SELECTED_CLIENTS: 'SET_SELECTED_CLIENTS',
  SET_HAND_SELECTED_CUSTOMERS: 'SET_HAND_SELECTED_CUSTOMERS',
  ADD_HAND_SELECTED_CUSTOMERS: 'ADD_HAND_SELECTED_CUSTOMERS',
  SET_SELECTED_HAND_SELECTED_CUSTOMERS: 'SET_SELECTED_HAND_SELECTED_CUSTOMERS',
  SET_IS_TEMPLATE_SELECTOR_OPEN: 'SET_IS_TEMPLATE_SELECTOR_OPEN',
  SET_IS_EMAIL_EDITOR_OPEN: 'SET_IS_EMAIL_EDITOR_OPEN',
  SET_SELECTED_TEMPLATE: 'SET_SELECTED_TEMPLATE',
  RESET_SELECTED_TEMPLATES: 'RESET_SELECTED_TEMPLATES',
  SET_AVAILABLE_COURSES: 'SET_AVAILABLE_COURSES',
  SET_AVAILABLE_GROUPS: 'SET_AVAILABLE_GROUPS',
  SET_IS_CLIENTS_SPINNER_VISIBLE: 'SET_CLIENTS_IS_SPINNER_VISIBLE',
  SET_CLIENTS: 'SET_CLIENTS',
  SET_IS_SMS_EDITOR_OPEN: 'SET_IS_SMS_EDITOR_OPEN',
  SET_IS_VIBER_EDITOR_OPEN: 'SET_IS_VIBER_EDITOR_OPEN',
  SET_IS_NEW_TEMPLATE_POPUP_OPEN: 'SET_IS_NEW_TEMPLATE_POPUP_OPEN',
  SET_IS_REWRITE_TEMPLATE_POPUP_OPEN: 'SET_IS_REWRITE_TEMPLATE_POPUP_OPEN',
  SET_IS_CONFIRM_DISTRIBUTION_POPUP_OPEN: 'SET_IS_CONFIRM_DISTRIBUTION_POPUP_OPEN',
  SET_IS_CREATE_DISTRIBUTION_POPUP_OPEN: 'SET_IS_CREATE_DISTRIBUTION_POPUP_OPEN',
  SET_SCHEDULE: 'SET_SCHEDULE',
  SET_DISTRIBUTION_SCHEDULE_DATE: 'SET_DISTRIBUTION_SCHEDULE_DATE',
  SET_SCHEDULE_TIME: 'SET_SCHEDULE_TIME',
  SET_IS_CHANNEL_SELECTOR_OPEN: 'SET_IS_CHANNEL_SELECTOR_OPEN',
  SET_SOURCE_CHANNELS: 'SET_SOURCE_CHANNELS',
  SET_CHANNELS_WITH_PRIORITY: 'SET_CHANNELS_WITH_PRIORITY',
  SET_IS_REPORTS_SPINNER_VISIBLE: 'SET_IS_REPORTS_SPINNER_VISIBLE',
  SET_REPORTS_PERIOD_FILTER: 'SET_REPORTS_PERIOD_FILTER',
  SET_SHOW_ALL_REPORTS: 'SET_SHOW_ALL_REPORTS',
  SET_IS_REPORT_DETAILS_OPEN: 'SET_IS_REPORT_DETAILS_OPEN',
  SET_DISTRIBUTION_DETAILS: 'SET_DISTRIBUTION_DETAILS',
  SET_AVAILABLE_TEMPLATES: 'SET_AVAILABLE_TEMPLATES',
  SET_ALL_DISTRIBUTIONS: 'SET_ALL_DISTRIBUTIONS',
  SET_IS_TEMPLATES_LOADING: 'SET_IS_TEMPLATES_LOADING',
  SET_APPLIED_TEMPLATE: 'SET_APPLIED_TEMPLATE',
  DISCARD_APPLIED_TEMPLATE: 'DISCARD_APPLIED_TEMPLATE',
  DELETE_FROM_APPLIED_TEMPLATES: 'DELETE_FROM_APPLIED_TEMPLATES',
  SET_IS_EDIT_TEMPLATE_MODE: 'SET_IS_EDIT_TEMPLATE_MODE',
  SET_IS_EDIT_TEMPLATE_POPUP_OPEN: 'SET_IS_EDIT_TEMPLATE_POPUP_OPEN',
  SET_TEMPLATE_TO_EDIT: 'SET_TEMPLATE_TO_EDIT',
  SET_MAIN_TABLE_PAGINATION: 'SET_MAIN_TABLE_PAGINATION',
  SET_MAIN_TABLE_FILTERS: 'SET_MAIN_TABLE_FILTERS',
  SET_MAIN_TABLE_SORTERS: 'SET_MAIN_TABLE_SORTERS',
  SET_SELECTED_DISTRIBUTIONS: 'SET_SELECTED_DISTRIBUTIONS',
  SET_DISTRIBUTION_SETTINGS_WERE_EDITED: 'SET_DISTRIBUTION_SETTINGS_WERE_EDITED',
  SET_DISTRIBUTIONS_PERIOD_FILTER: 'SET_DISTRIBUTIONS_PERIOD_FILTER',
  SET_MANUAL_ADDED_RECIPIENTS_IDS: 'SET_MANUAL_ADDED_RECIPIENTS_IDS',
  RESET_APPLIED_TEMPLATES: 'RESET_APPLIED_TEMPLATES',
  RESET_DISTRIBUTION_FILTERS: 'RESET_DISTRIBUTION_FILTERS',
  SET_REPORT_MAILING_ID: 'SET_REPORT_MAILING_ID',
  SET_MAIN_TABLE_PAGINATION_CONFIG: 'SET_MAIN_TABLE_PAGINATION_CONFIG',
  SET_DISTRIBUTION_REPORT: 'SET_DISTRIBUTION_REPORT',
  SET_FILTERED_CLIENTS: 'SET_FILTERED_CLIENTS',
  SET_MANUAL_FILTERED_CLIENTS: 'SET_MANUAL_FILTERED_CLIENTS',
  SET_DISTRIBUTION_FILTERS: 'SET_DISTRIBUTION_FILTERS'
}

export const SCHEDULE_DRAWER_ACTION = {
  SET_SCHEDULE_DRAWER_IS_OPEN: 'SET_SCHEDULE_DRAWER_IS_OPEN',
  SET_SCHEDULE_DRAWER_DATA: 'SET_SCHEDULE_DRAWER_DATA'
}

export const TASKS_ACTION = {
  SET_TASKS_KANBAN_BOARD: 'SET_TASKS_KANBAN_BOARD',
  SET_TASK: 'SET_TASK',
  SET_TASK_KANBAN_FILTER: 'SET_TASK_KANBAN_FILTER',
  SET_SELECTED_TASKS: 'SET_SELECTED_TASKS',
  UPDATE_TASKS_KANBAN_BOARD: 'UPDATE_TASKS_KANBAN_BOARD',
  SET_TASKS_CALENDAR_TYPE: 'SET_TASKS_CALENDAR_TYPE',
  SET_TASKS_CALENDAR_DATE: 'SET_TASKS_CALENDAR_DATE'
}

export const ALBUMS_ACTION = {
  SET_ALBUMS: 'SET_ALBUMS',
  SET_ALBUMS_START_DATE: 'SET_ALBUMS_START_DATE',
  SET_ALBUMS_END_DATE: 'SET_ALBUMS_END_DATE',
  SET_SELECTED_ALBUMS: 'SET_SELECTED_ALBUMS',
  SET_ALBUMS_COURSES: 'SET_ALBUMS_COURSES',
  SET_CURRENT_IMAGE: 'SET_CURRENT_IMAGE',
  SET_CROPPER_VISIBLE: 'SET_CROPPER_VISIBLE',
  SET_GALLERY_VISIBLE: 'SET_GALLERY_VISIBLE',
  SET_ALBUMS_TEACHERS: 'SET_ALBUMS_TEACHERS',
  SET_ALBUMS_PAGINATION: 'SET_ALBUMS_PAGINATION',
  SET_ALBUMS_FILTERS: 'SET_ALBUMS_FILTERS',
  SET_ALBUMS_GROUPS: 'SET_ALBUMS_GROUPS',
  SET_CURRENT_ALBUM: 'SET_CURRENT_ALBUM',
  SET_TOTAL_ALBUMS_COUNT: 'SET_TOTAL_ALBUMS_COUNT',
  SET_SELECTED_PHOTOS: 'SET_SELECTED_PHOTOS',
  SET_IS_IMAGE_UPDATED: 'SET_IS_IMAGE_UPDATED',
  SET_ACTIVE_GALLERY_INDEX: 'SET_ACTIVE_GALLERY_INDEX',
  SET_FILES: 'SET_FILES',
  SET_TOTAL_FILES: 'SET_TOTAL_FILES',
  SET_DELETE_CONFIRM_VISIBLE: 'SET_DELETE_CONFIRM_VISIBLE',
  SET_ALBUM_SPINNER: 'SET_ALBUM_SPINNER'
}

export const FORM_ACTIONS = {
  SET_TOUCHED: 'SET_TOUCHED',
  SET_CONFIRMATION: 'SET_CONFIRMATION',
  SET_SUBMITTING: 'SET_SUBMITTING'
}

export const SPINNER_ACTIONS = {
  SET_ACTION_TAB_SPINNER: 'SET_ACTION_TAB_SPINNER',
  SET_EVENT_SPINNER: 'SET_EVENT_SPINNER'
}

export const SELECTED_PERSON_ACTIONS = {
  SET_EVENTS: 'SET_EVENTS',
  SET_EVENTS_TOTAL_AMOUNT: 'SET_EVENTS_TOTAL_AMOUNT',
  SET_EVENTS_CURRENT_PAGE: 'SET_EVENTS_CURRENT_PAGE',
  SET_EVENTS_PAGE_SIZE: 'SET_EVENTS_PAGE_SIZE',
  SET_SELECTED_TYPES: 'SET_SELECTED_TYPES',
  SET_DATE_RANGE: 'SET_DATE_RANGE',
  SET_EVENTS_FILTERS: 'SET_EVENTS_FILTERS'
}

export const CUSTOM_FIELDS_ACTIONS = {
  CREATE_FIELD: 'CREATE_FIELD',
  UPDATE_FIELD: 'UPDATE_FIELD',
  DELETE_FIELD: 'DELETE_FIELD',
  CHANGE_FIELD_VISIBILITY: 'CHANGE_FIELD_VISIBILITY',
  SET_CUSTOM_FIELDS_STATE: 'SET_CUSTOM_FIELDS_STATE'
}

export const GROUP_ACTIONS = {
  SET_DATES_RANGE: 'SET_DATES_RANGE',
  SET_CURRENT_LESSON: 'SET_CURRENT_LESSON',
  SET_DRAGGING_STUDENT: 'SET_DRAGGING_STUDENT',
  SET_REPLACE_MARK_ID: 'SET_REPLACE_MARK_ID',
  // SET_DRAGGING_STUDENT_STATUS: 'SET_DRAGGING_STUDENT_STATUS',
  SET_CURRENT_STUDENT: 'SET_CURRENT_STUDENT',
  SET_MARK_DRAWER_OPEN: 'SET_MARK_DRAWER_OPEN',
  SET_SIGNS_DRAWER_OPEN: 'SET_SIGNS_DRAWER_OPEN',
  SET_PRESENCE_DRAWER_OPEN: 'SET_PRESENCE_DRAWER_OPEN',
  SET_DIPLOMA_DRAWER_OPEN: 'SET_DIPLOMA_DRAWER_OPEN',
  SET_SHOP_CART_OPEN: 'SET_SHOP_CART_OPEN',
  SET_EXERCISE_BOOK_OPEN: 'SET_EXERCISE_BOOK_OPEN',
  SET_CONFIRM_OPEN: 'SET_CONFIRM_OPEN',
  SET_CHILD_DETAILS_OPEN: 'SET_CHILD_DETAILS_OPEN',
  SET_ADD_CHILD_MODAL_OPEN: 'SET_ADD_CHILD_MODAL_OPEN',
  SET_CANCEL_REWORK_MODAL_OPEN: 'SET_CANCEL_REWORK_MODAL_OPEN',
  SET_ADD_CHILD_DRAWER_OPEN: 'SET_ADD_CHILD_DRAWER_OPEN',
  SET_ASSIGN_WORK_OFF_OPEN: 'SET_ASSIGN_WORK_OFF_OPEN',
  SET_STEM_DRAWER_OPEN: 'SET_STEM_DRAWER_OPEN',
  SET_SMS_DRAWER_OPEN: 'SET_SMS_DRAWER_OPEN',
  SET_GROUP_SETTINGS_OPEN: 'SET_GROUP_SETTINGS_OPEN',
  SET_ONE_TIME_LESSON_OPEN: 'SET_ONE_TIME_LESSON_OPEN',
  SET_CHOSEN_LESSON_DATE: 'SET_CHOSEN_LESSON_DATE',
  SET_GROUP: 'SET_GROUP',
  SET_TABLE_SPINNER: 'SET_TABLE_SPINNER',
  SET_HEADER_SPINNER: 'SET_HEADER_SPINNER',
  SET_MODAL_SPINNER: 'SET_MODAL_SPINNER',
  SET_DRAWER_SPINNER: 'SET_DRAWER_SPINNER',
  SET_CHILD_CARD_ACTIVE_KEY: 'SET_CHILD_CARD_ACTIVE_KEY',
  SET_GROUPS_COURSES: 'SET_GROUPS_COURSES',
  SET_GROUPS_LIST: 'SET_GROUPS_LIST',
  SET_SCHOOLS_LIST: 'SET_SCHOOLS_LIST',
  SET_FREE_CLASSROOMS: 'SET_FREE_CLASSROOMS',
  SET_FREE_TIME: 'SET_FREE_TIME',
  SET_TEACHERS: 'SET_TEACHERS',
  SET_LESSONS_COUNTER: 'SET_LESSONS_COUNTER',
  SET_BlOCK_VISIBLE: 'SET_BlOCK_VISIBLE',
  SET_SCROLL_TO_STUDENT_ID: 'SET_SCROLL_TO_STUDENT_ID',
  SET_REPLACE_PAYMENT_OPEN: 'SET_REPLACE_PAYMENT_OPEN',
  SET_FUTURE_LESSONS_DATES: 'SET_FUTURE_LESSONS_DATES',
  SET_HIGHLIGHTED_STUDENTS_IDS: 'SET_HIGHLIGHTED_STUDENTS_IDS',
  SET_STUDENTS_WITHOUT_ABSENCE_MARKS: 'SET_STUDENTS_WITHOUT_ABSENCE_MARKS',
  SET_STUDENTS_WITHOUT_COMPETENCES_MARKS: 'SET_STUDENTS_WITHOUT_COMPETENCES_MARKSS',
  SET_CLOSE_LESSON_MODAL_VISIBLE: 'SET_CLOSE_LESSON_MODAL_VISIBLE',
  SET_ACTIVE_FILTER: 'SET_ACTIVE_FILTER',
  SET_WORKING_OFF_DRAWER_OPEN: 'SET_WORKING_OFF_DRAWER_OPEN',
  SET_STUDENT_PRESENCE: 'SET_STUDENT_PRESENCE',
  SET_LOCAL_BADGES_DATA: 'SET_LOCAL_BADGES_DATA',
  SET_UPDATED_DIPLOMA_DATA: 'SET_UPDATED_DIPLOMA_DATA',
  SET_UPDATED_BADGE_DATA: 'SET_UPDATED_BADGE_DATA',
  SET_CONFIRM_CANCEL_BADGE_OPEN: 'SET_CONFIRM_CANCEL_BADGE_OPEN',
  SET_CONFIRM_CANCEL_DIPLOMA_OPEN: 'SET_CONFIRM_CANCEL_DIPLOMA_OPEN',
  SET_STUDENTS_LEADS: 'SET_STUDENTS_LEADS',
  SET_STUDENTS_CLIENTS: 'SET_STUDENTS_CLIENTS',
  SET_ADD_CHILD_TABLE_PAGINATION: 'SET_ADD_CHILD_TABLE_PAGINATION',
  SET_ADD_CHILD_TABLE_FILTERS: 'SET_ADD_CHILD_TABLE_FILTERS',
  SET_COURSE_DIRECTIONS: 'SET_COURSE_DIRECTIONS',
  SET_ADD_CHILD_COURSES: 'SET_ADD_CHILD_COURSES',
  SET_FREE_SEATS: 'SET_FREE_SEATS',
  SET_MISSED_LESSONS: 'SET_MISSED_LESSONS',
  SET_MISSED_TOPICS: 'SET_MISSED_TOPICS',
  SET_SERVICES_LIST: 'SET_SERVICES_LIST',
  SET_TARIFFS_LIST: 'SET_TARIFFS_LIST',
  SET_TABLE_SCROLL: 'SET_TABLE_SCROLL',
  SET_PRODUCTS_LIST: 'SET_PRODUCTS_LIST',
  SET_TEACHERS_FILTERS: 'SET_TEACHERS_FILTERS',
  CHANGE_REPLACEMENT_TEACHERS: 'CHANGE_REPLACEMENT_TEACHERS',
  SET_DROPPED_STATUS: 'SET_DROPPED_STATUS',
  SET_CANCEL_REWORK_DATA: 'SET_CANCEL_REWORK_DATA',
  SET_DATE_TO_START_PERMANENT: 'SET_DATE_TO_START_PERMANENT',
  SET_SHORT_VIEW: 'SET_SHORT_VIEW',
  SET_EMERGENCY_CONTACTS_OPEN: 'SET_EMERGENCY_CONTACTS_OPEN',
  SET_EMERGENCY_CONTACTS: 'SET_EMERGENCY_CONTACTS',
  SET_DISCOUNT_LIST_FOR_SCHOOL: 'SET_DISCOUNT_LIST_FOR_SCHOOL',
  SET_DISCOUNT_FOR_STUDENT: 'SET_DISCOUNT_LIST_FOR_SCHOOL'
}

export const TEACHER_SCHEDULE_ACTIONS = {
  SET_CURRENT_DATE: 'SET_CURRENT_DATE',
  SET_CALENDAR_TYPE: 'SET_CALENDAR_TYPE',
  SET_SCHEDULE: 'SET_SCHEDULE',
  EDIT_SCHEDULE: 'EDIT_SCHEDULE',
  SET_ACTIVE_EVENT: 'SET_ACTIVE_EVENT',
  SET_LIST_OF_REASONS: 'SET_LIST_OF_REASONS'
}

export const PROMO_CODES_ACTIONS = {
  SET_FILTERS: 'SET_PROMO_CODES_FILTERS',
  SET_PROMO_CODES: 'SET_PROMO_CODES',
  SET_CURRENT_PROMO_CODE: 'SET_CURRENT_PROMO_CODE',
  SET_SORTER: 'SET_PROMO_CODES_SORTER',
  SET_PAGINATION: 'SET_PROMO_CODES_PAGINATION',
  SET_PRODUCTS_FOR_FORM: 'SET_PRODUCTS_FOR_FORM',
  SET_SERVICES_FOR_FORM: 'SET_SERVICES_FOR_FORM'
}

export const DISCOUNTS_ACTIONS = {
  SET_DISCOUNTS_FILTERS: 'SET_DISCOUNTS_FILTERS',
  SET_DISCOUNTS: 'SET_DISCOUNTS',
  SET_CURRENT_DISCOUNT: 'SET_CURRENT_DISCOUNT',
  SET_DISCOUNTS_SORTER: 'SET_DISCOUNTS_SORTER',
  SET_DISCOUNTS_PAGINATION: 'SET_DISCOUNTS_PAGINATION'
}

export const SUPPLIERS_ACTIONS = {
  SET_FILTERS: 'SET_SUPPLIERS_FILTERS',
  SET_SUPPLIERS: 'SET_SUPPLIERS',
  SET_CURRENT_SUPPLIER: 'SET_CURRENT_SUPPLIER',
  SET_PAGINATION: 'SET_SUPPLIERS_PAGINATION',
  SET_SORTER: 'SET_SUPPLIERS_SORTER',
  SET_SCHOOL_GROUP_ID: 'SET_SUPPLIERS_SCHOOL_GROUP_ID',
  SET_SUPPLIERS_BY_SCHOOL: 'SET_SUPPLIERS_BY_SCHOOL'
}

export const PAYMENT_ACTIONS = {
  SET_PRODUCT_GROUPS: 'SET_PRODUCT_GROUPS',
  CREATE_PRODUCT_GROUP: 'SET_PRODUCT_GROUPS',
  GET_SERVICE_GROUPS: 'GET_SERVICE_GROUPS',
  CREATE_SERVICE_GROUP: 'CREATE_SERVICE_GROUP',
  GET_COURSES: 'GET_COURSES',
  SET_TEMPLATE_LINK: 'SET_TEMPLATE_LINK'
}

export const CALLS_JOURNAL_ACTIONS = {
  SET_CALLS_REASONS: 'SET_CALLS_REASONS',
  SET_CALLS_RESULTS: 'SET_CALLS_RESULTS',
  SET_CALLS_TABLE_DATA: 'SET_CALLS_TABLE_DATA',
  SET_CALLS_TABLE_FILTERS: 'SET_CALLS_TABLE_FILTERS',
  SET_CALLS_TABLE_PAGINATION: 'SET_CALLS_TABLE_PAGINATION'
}

export const SETTINGS_ACTIONS = {
  SET_COUNTRIES: 'SET_COUNTRIES',
  ADD_COUNTRY: 'ADD_COUNTRY',
  UPDATE_COUNTRY: 'UPDATE_COUNTRY',
  SET_SCHOOL_GROUPS: 'SET_SCHOOL_GROUPS',
  ADD_SCHOOL_GROUP: 'ADD_SCHOOL_GROUP',
  UPDATE_SCHOOL_GROUP: 'UPDATE_SCHOOL_GROUP',
  SET_SCHOOL_GROUPS_LOADING: 'SET_SCHOOL_GROUPS_LOADING',
  SET_SCHOOL_LOADING: 'SET_SCHOOL_LOADING',
  SET_SCHOOLS: 'SET_SCHOOLS',
  SET_SELECTED_COUNTRY_SCHOOLS: 'SET_SELECTED_COUNTRY_SCHOOLS',
  SET_SELECTED_SCHOOL: 'SET_SELECTED_SCHOOL',
  SET_SELECTED_PERIOD: 'SET_SELECTED_PERIOD',
  SET_SELECTED_SCHOOL_COURSES: 'SET_SELECTED_SCHOOL_COURSES',
  SET_PERIOD_LOADING: 'SET_PERIOD_LOADING',
  UPDATE_PERIOD_LEVEL: 'UPDATE_PERIOD_LEVEL',
  UPDATE_PERIOD_LEVEL_COURSE: 'UPDATE_PERIOD_LEVEL_COURSE',
  UPDATE_GROUP_IN_PERIOD: 'UPDATE_GROUP_IN_PERIOD',
  SET_LEVELS_EXPANDED: 'SET_LEVELS_EXPANDED',
  SET_COURSE_EXPANDED: 'SET_COURSE_EXPANDED',
  SET_SOURCE_LIST: 'SET_SOURCE_LIST',
  SET_SOURCE_LOADING: 'SET_SOURCE_LOADING',
  SET_SCHOOLS_FROM_SELECTED_GROUP: 'SET_SCHOOLS_FROM_SELECTED_GROUP'
}

export const TEACHERS_ACTIONS = {
  SET_LESSONS_PAYMENTS: 'SET_LESSONS_PAYMENTS',
  SET_LESSONS_PAYMENTS_TOTAL: 'SET_LESSONS_PAYMENTS_TOTAL',
  SET_LESSONS_PAYMENTS_FILTERS: 'SET_LESSONS_PAYMENTS_FILTERS',
  SET_LESSONS_PAYMENTS_PAGINATION: 'SET_LESSONS_PAYMENTS_PAGINATION',
  SET_LESSONS_PAYMENTS_SORTER: 'SET_LESSONS_PAYMENTS_SORTER',
  SET_LESSONS_PAYMENTS_LOADING: 'SET_LESSONS_PAYMENTS_LOADING',
  SET_ADDITIONAL_PAYMENTS: 'SET_ADDITIONAL_PAYMENTS',
  SET_ADDITIONAL_PAYMENTS_TOTAL: 'SET_ADDITIONAL_PAYMENTS_TOTAL',
  SET_ADDITIONAL_PAYMENTS_FILTERS: 'SET_ADDITIONAL_PAYMENTS_FILTERS',
  SET_ADDITIONAL_PAYMENTS_PAGINATION: 'SET_ADDITIONAL_PAYMENTS_PAGINATION',
  SET_ADDITIONAL_PAYMENTS_SORTER: 'SET_ADDITIONAL_PAYMENTS_SORTER',
  SET_ADDITIONAL_PAYMENTS_LOADING: 'SET_ADDITIONAL_PAYMENTS_LOADING',
  SET_CERTIFICATION: 'SET_CERTIFICATION',
  SET_QUALIFICATION_LEVELS: 'SET_QUALIFICATION_LEVELS',
  SET_COURSES_LIST: 'SET_COURSES_LIST',
  SET_CURRENT_PROFILE_SCHOOL: 'SET_CURRENT_PROFILE_SCHOOL',
  SET_ADDITIONAL_PAYMENT_MODAL_OPEN: 'SET_ADDITIONAL_PAYMENT_MODAL_OPEN',
  SET_SCHOOL_ADDITIONAL_PRICE: 'SET_SCHOOL_ADDITIONAL_PRICE',
  SET_ADDITIONAL_PAYMENTS_SETTINGS: 'SET_ADDITIONAL_PAYMENTS_SETTINGS',
  SET_ADDITIONAL_PAYMENTS_SETTINGS_LOADING: 'SET_ADDITIONAL_PAYMENTS_SETTINGS_LOADING'
}
export const REMINDERS_ACTIONS = {
  SET_REMINDERS_LIST: 'SET_REMINDERS_LIST',
  SET_REMINDERS_LOADING: 'SET_REMINDERS_LOADING',
  SET_FAMILY_REMINDERS_LIST: 'SET_FAMILY_REMINDERS_LIST'
}

export const REPORTS_ACTIONS = {
  SET_START_DATE: 'SET_START_DATE',
  SET_END_DATE: 'SET_END_DATE',
  SET_SCHOOL_IDS: 'SET_SCHOOL_IDS',
  SET_SCHOOL_ID: 'SET_SCHOOL_ID',
  SET_PERIOD_ID: 'SET_PERIOD_ID'
}
