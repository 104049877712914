import axios from 'axios';
import { GET_LEAD_BY_ID, EDIT_LEED_BY_ID_API, LEAD_COMMENTS } from '../constants/api';

export const getLeadById = id => {
  return axios({
    url: `${GET_LEAD_BY_ID}/${id}`,
    method: 'get',
    headers: { 'content-type': 'application/json' },
    withCredentials: true,
  });
};

export const updateLeadById = (id, data) => {
  return axios({
    url: `${GET_LEAD_BY_ID}/${id}`,
    method: 'put',
    headers: { 'content-type': 'application/json' },
    withCredentials: true,
    data: JSON.stringify(data)
  });
};

export const addLead = data => {
  return axios({
    url: `${EDIT_LEED_BY_ID_API}`,
    method: 'post',
    headers: { 'content-type': 'application/json' },
    withCredentials: true,
    data: JSON.stringify(data)
  });
};

export const getLeadComments = leadId => {
  return axios({
    url: `${GET_LEAD_BY_ID}/${leadId}/comments`,
    method: 'get',
    headers: { 'content-type': 'application/json' },
    withCredentials: true,
  });
};

export const createComment = data => {
  return axios({
    url: `${LEAD_COMMENTS}`,
    method: 'post',
    headers: { 'content-type': 'application/json' },
    withCredentials: true,
    data: JSON.stringify(data)
  });
};