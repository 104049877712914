import * as api from '../../utils/widgetCall'
import moment from 'moment'
import { CALL_STATUSES, CALL_TYPES } from '../../constants/text'
import { getSchoolGroupIdBySchoolId } from '../../utils/schools'
import { getUser } from '../../utils/api'

export const WIDGET_CALL_TYPES = {
  GET_SCHOOLS: 'GET_SCHOOLS',
  GET_REASONS: 'GET_REASONS',
  GET_CALLS: 'GET_CALLS',
  CLOSE_CALL: 'CLOSE_CALL',
  GET_ALL_PARENTS_BY_FILTERS: 'GET_ALL_PARENTS_BY_FILTERS',
  OPEN_WIDGET: 'OPEN_WIDGET',
  CLOSE_WIDGET: 'CLOSE_WIDGET',
  GET_CALL: 'GET_CALL',
  SET_CALLS_LIST: 'SET_CALLS_LIST',
  GET_MAPPING_PHONES: 'GET_MAPPING_PHONES',
  UPDATE_MAPPING_PHONE: 'UPDATE_MAPPING_PHONE',
  CHANGE_MAPPING_PHONE_VISIBILITY: 'CHANGE_MAPPING_PHONE_VISIBILITY',
  CHANGE_MUTE_INCOMING_CALLS: 'CHANGE_MUTE_INCOMING_CALLS',
  SET_CALLS_STATISTICS: 'SET_CALLS_STATISTICS'
}

export const changeVisibilityMappingPhoneModal = (visible) => {
  return {
    type: WIDGET_CALL_TYPES.CHANGE_MAPPING_PHONE_VISIBILITY,
    payload: visible
  }
}

export const openMappingPhoneModal = () => (dispatch) => {
  dispatch(changeVisibilityMappingPhoneModal(true))
}

export const closeMappingPhoneModal = () => (dispatch) => {
  dispatch(changeVisibilityMappingPhoneModal(false))
}

export const changeMuteIncomingCalls = (muted) => (dispatch, getState) => {
  const { auth: { currentSchoolId } } = getState()
  dispatch({
    type: WIDGET_CALL_TYPES.CHANGE_MUTE_INCOMING_CALLS,
    payload: muted
  })
  getAllCalls(currentSchoolId)(dispatch, getState)
}

export const setCallsList = (list, mutedIncomingCalls = false) => {
  const calls = list
    ?.filter(call => mutedIncomingCalls ? call.type.toUpperCase() !==
      CALL_TYPES.INCOMING : true)
    ?.map(call => {
      const newCall = {
        ...call,
        status: call.status.toUpperCase(),
        sortOrder: 0
      }

      if (newCall.status === CALL_STATUSES.IN_PROCESS) {
        newCall.sortOrder = 2
      }

      if (newCall.status === CALL_STATUSES.CALLING) {
        newCall.sortOrder = 1
      }

      if (Object.values(CALL_STATUSES)
        .some(status => status === newCall.status)) {
        return newCall
      }

      return {
        ...newCall,
        status: CALL_STATUSES.UNCLOSED
      }
    })
    ?.sort((a, b) => {
      if (b.sortOrder - a.sortOrder !== 0) {
        return b.sortOrder - a.sortOrder
      }

      return moment(a.createDate).isAfter(moment(b.createDate)) ? -1 : 1
    })

  return {
    type: WIDGET_CALL_TYPES.SET_CALLS_LIST,
    payload: calls || []
  }
}

const openWidgetAndActiveCall = (dispatch, getState) => {
  const {
    widgetCall: {
      calls,
      phoneChannelDTO
    }
  } = getState()

  const filterPhones = calls?.filter(call => {
    return call.status === CALL_STATUSES.CALLING || call.status ===
      CALL_STATUSES.IN_PROCESS
  }) || []

  if (filterPhones.length > 0) {
    openWidget(dispatch, getState)
    filterPhones.forEach(call => {
      if (call.status === CALL_STATUSES.IN_PROCESS &&
        +phoneChannelDTO?.number === +call.internalNumber) {
        getCallById(call.id)
      }

      if (call.status === CALL_STATUSES.CALLING) {
        getCallById(call.id)
      }
    })
  }
}

export const setCallsStatistics = (data) => ({
  type: WIDGET_CALL_TYPES.SET_CALLS_STATISTICS,
  payload: data
})
export const getCallsStatistics = () => async (dispatch, getState) => {
  try {
    const schoolId = getState().auth.currentSchoolId
    console.log('GET STAT')
    const result = await api.getCallStatistics(schoolId)
    if (result.data) {
      dispatch(setCallsStatistics(result.data))
    } else {
      dispatch(setCallsStatistics({}))
    }
  } catch (e) {
    console.log(e)
    dispatch(setCallsStatistics({}))
  }
}
export const getAllSchools = () => async (dispatch) => {
  try {
    const schools = await api.getAllSchools()
    dispatch({
      type: WIDGET_CALL_TYPES.GET_SCHOOLS,
      payload: schools
    })
  } catch (e) {
    console.log(e)
  }
}

export const getReasons = (schoolId) => async (dispatch) => {
  try {
    const reasons = await api.getReasons(schoolId)

    dispatch({
      type: WIDGET_CALL_TYPES.GET_REASONS,
      payload: reasons
    })
  } catch (err) {
    console.error(`catch an error ${err}`)
  }
}

export const getCallById = (callId) => async (dispatch, getState) => {
  try {
    const call = await api.getCallById(callId)
    // const {widgetCall: {calls}} = getState();
    // const call = calls.find(i => i.id === callId);

    dispatch({
      type: WIDGET_CALL_TYPES.GET_CALL,
      payload: call
    })
  } catch (err) {
    console.error(`catch an error ${err}`)
  }
}

export const getAllCalls = (schoolId) => async (dispatch, getState) => {
  const {
    calls,
    ...payload
  } = await api.getAllCalls(schoolId)
  const { widgetCall: { mutedIncomingCalls } } = getState()

  dispatch(setCallsList(calls, mutedIncomingCalls))
  openWidgetAndActiveCall(dispatch, getState)

  dispatch({
    type: WIDGET_CALL_TYPES.GET_CALLS,
    payload
  })
}

export const updateCall = (callId, data) => async (dispatch, getState) => {
  try {
    const {
      widgetCall: {
        calls,
        mutedIncomingCalls
      }
    } = getState()

    dispatch(setCallsList(calls?.map(c => {
      if (c.id === callId) {
        return data
      }
      return c
    }), mutedIncomingCalls))
    openWidgetAndActiveCall(dispatch, getState)
  } catch (err) {
    console.error(`catch an error ${err}`)
  }
}

export const saveCall = (callId, data) => async (dispatch, getState) => {
  try {
    await api.saveCall(callId, data)
  } catch (err) {
    console.error(`catch an error ${err}`)
  }
}

export const createCall = (call) => async (dispatch, getState) => {
  try {
    const {
      widgetCall: {
        calls,
        mutedIncomingCalls
      }
    } = getState()
    dispatch(setCallsList([...calls, call], mutedIncomingCalls))
    openWidgetAndActiveCall(dispatch, getState)
  } catch (err) {
    console.error(`catch an error ${err}`)
  }
}

export const deleteCall = (callId) => async (dispatch, getState) => {
  try {
    const {
      widgetCall: {
        calls,
        mutedIncomingCalls
      }
    } = getState()
    dispatch(
      setCallsList(calls?.filter(c => c.id !== callId), mutedIncomingCalls))
    openWidgetAndActiveCall(dispatch, getState)
  } catch (err) {
    console.error(`catch an error ${err}`)
  }
}

export const closeCall = () => (dispatch) => {
  dispatch({
    type: WIDGET_CALL_TYPES.CLOSE_CALL
  })
}

export const openWidget = () => (dispatch) => {
  dispatch({
    type: WIDGET_CALL_TYPES.OPEN_WIDGET
  })
}

export const closeWidget = () => (dispatch) => {
  dispatch({
    type: WIDGET_CALL_TYPES.CLOSE_WIDGET
  })
}

export const getAllParentsByFilters = (
  schoolId, filters) => async (dispatch) => {
  try {
    const data = await getSchoolGroupIdBySchoolId(schoolId)
    const schoolGroupId = data?.data?.data?.id
    console.log({ filters })
    const parents = await api.getAllParentsByFilters(
      { schoolGroupId, ...filters })

    dispatch({
      type: WIDGET_CALL_TYPES.GET_ALL_PARENTS_BY_FILTERS,
      payload: parents
    })
  } catch (err) {
    console.error(`catch an error ${err}`)
  }
}
export const getMappingPhones = (schoolIds) => async (dispatch) => {
  try {
    const phones = await api.getMappingPhones(schoolIds)

    dispatch({
      type: WIDGET_CALL_TYPES.GET_MAPPING_PHONES,
      payload: phones
    })
  } catch (err) {
    console.error(`catch an error ${err}`)
  }
}
export const updateMappingPhone = (schoolId, phone) => async (dispatch) => {
  try {
    const response = await getUser()
    const employeeId = response?.data?.data?.id
    const success = await api.updateMappingPhone(schoolId, employeeId, phone.id)

    if (success) {
      dispatch({
        type: WIDGET_CALL_TYPES.UPDATE_MAPPING_PHONE,
        payload: phone
      })
    }
  } catch (err) {
    console.error(`catch an error ${err}`)
  }
}
export const makeCall = async (phone) => {
  try {
    await api.makeCall(phone)
  } catch (err) {
    console.error(`catch an error ${err}`)
  }
}

export const setTechnicalCall = async (callId) => {
  try {
    await api.setTechnicalCall(callId)
  } catch (err) {
    console.error(`catch an error ${err}`)
  }
}
