import axios from 'axios'
import {
  BASE_UAA_SERVICE_URL,
  DELETE_LEEDS_API,
  EDIT_LEED_BY_ID_API,
  EDIT_LEEDS_API,
  GET_LEED_ADDITIONAL_FIELDS_API,
  GET_LEEDS_AS_XLS_API,
  LEEDS_DATA_API
} from '../constants/api'
import { STATUS, SUBSTATUS } from '../constants/text'
import { deleteObjectFields } from './objectUtils'

export const getLeedsData = async (options) => {
  const sortStrategyMap = {
    ascend: 'asc',
    descend: 'desc'
  }

  const {
    pagination,
    regDate,
    leedsStatusFilter,
    inProgressLeedsSubStatusFilter,
    completedLeedsSubStatusFilter,
    lastActivitySortValue,
    filters
  } = options

  let status = null
  if (leedsStatusFilter !== STATUS.ALL) {
    status = leedsStatusFilter
  }

  let substatus = null
  if (leedsStatusFilter === STATUS.NEW) {
    substatus = 'created'
  } else if (leedsStatusFilter === STATUS.IN_WORK) {
    if (inProgressLeedsSubStatusFilter !== SUBSTATUS.DISABLED &&
      inProgressLeedsSubStatusFilter !== SUBSTATUS.ALL) {
      substatus = inProgressLeedsSubStatusFilter
    }
  } else if (leedsStatusFilter === STATUS.FINISHED) {
    if (completedLeedsSubStatusFilter !== SUBSTATUS.DISABLED &&
      completedLeedsSubStatusFilter !== SUBSTATUS.ALL) {
      substatus = completedLeedsSubStatusFilter
    }
  }

  let sortParams = 'sort=created_at,desc'
  if (lastActivitySortValue) {
    sortParams = `sort=modifyDate,${sortStrategyMap[lastActivitySortValue]}`
  }

  const response = await axios({
    url: `${LEEDS_DATA_API}?page=${pagination.current -
    1}&size=${pagination.pageSize}&${sortParams}`,
    method: 'post',
    headers: { 'content-type': 'application/json' },
    withCredentials: true,
    data: {
      blackList: filters.blackList === null ? null : !!parseInt(
        filters.blackList),
      fromCreatedDate: regDate.from,
      toCreatedDate: regDate.to,
      fromModifyDate: null,
      toModifyDate: null,
      schoolIdList: filters.schools,
      status,
      substatus
    }
  })

  return response.data
}

export const getLeadsData = ({
  page,
  size,
  sortOrder,
  sortField,
  fromCreatedDate,
  toCreatedDate,
  schoolIdList,
  fromLastActivityDate,
  toLastActivityDate,
  status,
  familyId,
  mainParentName,
  phone,
  email,
  substatus,
  blackList
}) => {
  let sortParams = ''
  if (sortOrder) {
    sortParams = `sort=${sortField},${sortOrder > 0 ? 'asc' : 'desc'}`
  }

  return axios({
    url: `${LEEDS_DATA_API}?page=${page}&size=${size}&${sortParams}`,
    method: 'post',
    headers: { 'content-type': 'application/json' },
    withCredentials: true,
    data: {
      blackList,
      fromCreatedDate,
      toCreatedDate,
      fromLastActivityDate,
      toLastActivityDate,
      schoolIdList,
      status,
      substatus,
      familyId,
      mainParentName,
      phone,
      email
    }
  })
}

export const getLeadCustomField = (currentSchoolId) => {
  const url = `${BASE_UAA_SERVICE_URL}/custom-field-pattern?entityType=parent&schoolId=${currentSchoolId}`

  return axios({
    url: url,
    method: 'get',
    headers: { 'content-type': 'application/json' },
    withCredentials: true
  })
}

export const submitNewLeedData = (leedData) => {
  console.log(leedData)
}

export const submitNewLeedFieldData = async (fieldData) => {
  const { fieldName, placeholder, visible } = fieldData

  // await axios({
  //   url: ADD_LEED_ADDITIONAL_FIELD_API,
  //   method: 'post',
  //   headers: { 'content-type': 'application/json' },
  //   withCredentials: true,
  //   data: {
  //     name: fieldName,
  //     placeholder,
  //     visible,
  //     type: 'lead',
  //   },
  // });

  return 'ok' // TODO replace with commented code above
}

export const getLeedAdditionalFields = async () => {
  const response = await axios({
    url: `${GET_LEED_ADDITIONAL_FIELDS_API}/1`, // todo replace hardcoded schoolId with real one
    method: 'get',
    headers: { 'content-type': 'application/json' },
    withCredentials: true
  })

  console.log(response)
}

export const editLeed = async (updatedLeed) => {
  const response = await axios({
    url: `${EDIT_LEED_BY_ID_API}/${updatedLeed.id}`,
    method: 'put',
    headers: { 'content-type': 'application/json' },
    withCredentials: true,
    data: deleteObjectFields(
      {
        ...updatedLeed,
        schoolId: updatedLeed.schoolNameTimezoneDTO?.id
      },
      ['schoolNameDTO']
    )
  })

  return response.data
}

export const editLeadApplication = (applicationId, data) => {
  return axios({
    url: `${BASE_UAA_SERVICE_URL}/students/application/${applicationId}`,
    method: 'put',
    headers: { 'content-type': 'application/json' },
    withCredentials: true,
    data
  })
}

export const getLeedsAsXls = (options) => {
  console.log({ options })
  const {
    schoolIdList,
    fromCreatedDate,
    toCreatedDate,
    fromLastActivityDate,
    toLastActivityDate,
    status,
    substatus,
    blackList,
    email,
    phone,
    familyId,
    mainParentName
  } = options

  return axios({
    url: GET_LEEDS_AS_XLS_API,
    method: 'post',
    headers: { 'content-type': 'application/json' },
    withCredentials: true,
    data: {
      schoolIdList,
      fromCreatedDate,
      toCreatedDate,
      fromLastActivityDate,
      toLastActivityDate,
      status,
      substatus,
      blackList,
      email,
      familyId,
      mainParentName,
      phone
    }
  })
}

export const deleteLeeds = (ids) => {
  return axios({
    url: DELETE_LEEDS_API,
    method: 'delete',
    headers: { 'content-type': 'application/json' },
    withCredentials: true,
    data: ids
  })
}

export const editLeeds = (updatedLeeds) => {
  return axios({
    url: EDIT_LEEDS_API,
    method: 'put',
    headers: { 'content-type': 'application/json' },
    withCredentials: true,
    data: updatedLeeds.map((updatedLeed) => {
      return deleteObjectFields(
        {
          ...updatedLeed,
          schoolId: updatedLeed.schoolNameTimezoneDTO.id
        },
        ['schoolNameDTO']
      )
    })
  })
}

export const getSimilarLeadsAndClients = (searchParams) => {
  return axios({
    url: `${BASE_UAA_SERVICE_URL}/leads/creation/filter`,
    method: 'post',
    headers: { 'content-type': 'application/json' },
    withCredentials: true,
    data: searchParams
  })
}

export const deleteLeadApplication = (applicationId) => {
  return axios({
    url: `${BASE_UAA_SERVICE_URL}/students/application/${applicationId}`,
    method: 'delete',
    headers: { 'content-type': 'application/json' },
    withCredentials: true
  })
}

export const addApplicationToFamily = data => {
  return axios({
    url: `${BASE_UAA_SERVICE_URL}/leads/creation/existing-lead-creation`,
    method: 'post',
    headers: { 'content-type': 'application/json' },
    withCredentials: true,
    data
  })
}
