import { FORM_ACTIONS } from '../actions/types';

const initialState = {
  touchedFields: {},
  confirmation: true,
  isSubmitting: false,
};

export const formReducer = (state = initialState, action) => {
  switch (action.type) {
    case FORM_ACTIONS.SET_TOUCHED:
      return {...state, touchedFields: action.payload};
    case FORM_ACTIONS.SET_CONFIRMATION:
      return {...state, confirmation: action.payload};
    case FORM_ACTIONS.SET_SUBMITTING:
      return {...state, isSubmitting: action.payload};
    default:
      return state;
  }
};
