import axios from 'axios'
import {
  BASE_CALL_SERVICE_URL,
  BASE_SCHOOL_SERVICE_URL,
  BASE_UAA_SERVICE_URL
} from '../constants/api'
import { getFamilyById } from './family'

export const getAllSchools = async () => {
  const url = `${BASE_SCHOOL_SERVICE_URL}/schools`
  const response = await axios.get(url)
  return response?.data?.data || []
}

export const getReasons = async (schoolId) => {
  const url = `${BASE_CALL_SERVICE_URL}/reasons/school/${schoolId}`
  const response = await axios.get(url)
  return response?.data?.data || []
}

export const getAllParentsByFilters = async ({
  schoolGroupId,
  name,
  familyId,
  phoneNumber
}) => {
  let url = `${BASE_UAA_SERVICE_URL}/parents/schoolGroup/${schoolGroupId}/filter?`
  if (phoneNumber) url += `phoneNumber=${phoneNumber}&`
  if (name) url += `name=${name}&`
  if (familyId) url += `familyId=${familyId}&`

  const response = await axios.get(url)
  return response?.data?.data || []
}

export const getAllCalls = async (schoolId) => {
  const url = `${BASE_CALL_SERVICE_URL}/calls/school/${schoolId}`
  const response = await axios.get(url)
  return response?.data?.data || {
    calls: [],
    lostCallsCount: null,
    unproccesedCallsCount: null,
    mappingPhone: null
  }
}

export const saveCall = async (callId, data) => {
  const url = `${BASE_CALL_SERVICE_URL}/calls/${callId}`
  const response = await axios.post(url, data)
  return response?.data?.data || {}
}

export const getCallById = async (callId) => {
  const url = `${BASE_CALL_SERVICE_URL}/calls/${callId}`
  const response = await axios.get(url)
  return response?.data?.data || {}
}

export const getCallStatistics = async (schoolId) => {
  const url = `${BASE_CALL_SERVICE_URL}/calls/statistic/school/${schoolId}`
  return axios.get(url)
}

export const getMappingPhones = async (schoolIds) => {
  const url = `${BASE_CALL_SERVICE_URL}/phone-channels/get-by-school-ids`
  const response = await axios.post(url, { schoolIds })
  return response?.data?.data || []
}

export const updateMappingPhone = async (
  schoolId, employeeId, phoneNumberId) => {
  const url = `${BASE_CALL_SERVICE_URL}/phone-channels/${phoneNumberId}/claim`
  const response = await axios.put(url, {
    schoolId,
    employeeId
  })
  return response?.data?.data || false
}

export const makeCall = async (phone) => {
  const url = `${BASE_CALL_SERVICE_URL}/calls/create-outgoing-call/current?externalNumber=${phone}`
  const response = await axios.put(url)
  return response?.data?.data || false
}

export const setTechnicalCall = async (callId) => {
  const url = `${BASE_CALL_SERVICE_URL}/calls/${callId}/technical`
  const response = await axios.put(url)
  return response?.data?.data || false
}

export const getChildren = async (familyId) => {
  const response = await getFamilyById(familyId)
  const students = response?.data?.data?.studentDTOS || []
  // todo isActive field...
  console.log({ students })
  return students?.map(student => ({
    fullName: `${student?.firstName || ''} ${student?.lastName || ''}`,
    avatar: student?.imageUrl,
    isActive: false,
    id: student?.id
  }))
}

export const getChildrenDetail = async (childrenId) => {
  const response = await axios.get(
    `${BASE_SCHOOL_SERVICE_URL}/education-groups/student/${childrenId}`)
  console.log({ response })
  const groups = response?.data?.data || []
  console.log({ groups })
  // todo payType and payDate
  // const g = groups?.reduce((acc, group) => {
  //   return [...acc, ...group?.schedules?.reduce((schedulesAcc, schedule) => {
  //     return [...schedulesAcc, {
  //       name: group.name,
  //       groupId: group.id,
  //       id: schedule.id,
  //       time: `${schedule.dayOfWeek} ${schedule.startTime}`
  //     }];
  //   }, []) || []];
  // }, []);

  return {
    groups,
    stocks: [],
    offers: []
  }

  // return new Promise((resolve) => {
  //   setTimeout(() => {
  //     resolve({
  //       stocks: [
  //         {
  //           stock: 'Приведи друга',
  //           id: 1,
  //           description: 'Отримай знижку 20%',
  //           fromDate: '20.12.2021',
  //           dueDate: '20.12.2021',
  //           age: '6-99',
  //           active: true
  //         },
  //         {
  //           stock: 'Приведи друга',
  //           id: 2,
  //           description: 'Отримай знижку 20%',
  //           fromDate: '20.12.2021',
  //           dueDate: '20.12.2021',
  //           age: '6-99',
  //           active: true
  //         },
  //       ],
  //       groups: [
  //         {
  //           name: 'DEVELOPER. Розробка ігор та 3-D моделювання',
  //           id: 1,
  //           time: 'пн. 18-00',
  //           date: {
  //             value: '20.12.2021',
  //             payType: 1
  //           }
  //         },
  //         {
  //           name: 'DEVELOPER. Розробка ігор та 3-D моделювання',
  //           id: 2,
  //           time: 'пн. 18-00',
  //           date: {
  //             value: '20.12.2021',
  //             payType: 3,
  //           }
  //         },
  //       ],
  //       offers: [
  //         {
  //           id: 1,
  //           name: 'Захоплююча математика',
  //           date: 'чт. 14:30'
  //         },
  //         {
  //           id: 2,
  //           name: 'Програмування та англійська мова',
  //           date: 'чт. 14:30'
  //         },
  //       ]
  //     });
  //   }, 500);
  // });
}
