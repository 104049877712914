import {
  addFamilyToBlackList,
  changeEmergencyParent,
  changeMainParent,
  createFamily,
  createParent,
  getFamilyById,
  removeFamilyFromBlackList,
  updateFamilyById
} from '../../utils/family'
import { setSpinner } from './index'
import { FAMILY_ACTION } from './types'
import { getSchoolGroupBySchoolId } from '../../utils/schools'
import { getAdviceTopics } from '../../utils/parent'
import { handlePhoneNumberError } from './utils'
import { getPersonEvents } from './selectedPerson'

export const setFamily = data => ({
  type: FAMILY_ACTION.SET_FAMILY,
  payload: data
})

export const setStudent = data => ({
  type: FAMILY_ACTION.SET_APPLICATION_STUDENT,
  payload: data
})

export const getFamily = id => dispatch => {
  dispatch(setSpinner(true))
  getFamilyById(id)
    .then(result => {
      if (result.data && result.data.statusCode &&
        result.data.statusCode.code === 0 && result.data.data) {
        dispatch(setFamily(result.data.data))
      }
    }).catch(error => {
      console.log('get family error happened', error)
    }).finally(() => dispatch(setSpinner(false)))
}

export const updateFamily = (id, data) => dispatch => {
  dispatch(setSpinner(true))
  updateFamilyById(id, data)
    .then(result => {
      if (result.data && result.data.statusCode &&
        result.data.statusCode.code === 0) {
        dispatch(getFamily(id))
        dispatch(setSpinner(false))
      }
    }).catch(error => {
      console.log('update family error happened', error)
      dispatch(setSpinner(false))
    })
}

export const changeMainContact = (familyId, parentId) => dispatch => {
  dispatch(setSpinner(true))
  changeMainParent(familyId, parentId)
    .then(result => {
      if (result.data && result.data.statusCode &&
        result.data.statusCode.code === 0) {
        dispatch(getFamily(familyId))
        dispatch(setSpinner(false))
      }
    }).catch(error => {
      console.log('change main contact error happened', error)
      dispatch(setSpinner(false))
    })
}

export const changeEmergencyContact = (familyId, parentId) => dispatch => {
  dispatch(setSpinner(true))
  changeEmergencyParent(familyId, parentId)
    .then(result => {
      if (result.data && result.data.statusCode &&
        result.data.statusCode.code === 0) {
        dispatch(getFamily(familyId))
        dispatch(setSpinner(false))
      }
    }).catch(error => {
      console.log('change emergency contact error happened', error)
      dispatch(setSpinner(false))
    })
}

export const createNewFamily = data => dispatch => {
  dispatch(setSpinner(true))
  createFamily(data)
    .then(result => {
      if (result.data && result.data.statusCode &&
        result.data.statusCode.code === 0) {
        dispatch(setSpinner(false))
      }
    }).catch(error => {
      console.log('create family error happened', error)
      dispatch(setSpinner(false))
    })
}

export const createNewParent = (
  familyId, data, callback, errorHandler) => dispatch => {
  dispatch(setSpinner(true))
  createParent(familyId, data)
    .then(result => {
      if (result.data && result.data.statusCode &&
        result.data.statusCode.code === 0) {
        dispatch(getFamily(familyId))
        dispatch(setSpinner(false))
        callback()
      } else if (result.data && result.data.statusCode &&
        result.data.statusCode.code === 3 &&
        result.data.statusCode.code === 3) {
        handlePhoneNumberError(result.data.data)
      }
    }).catch(error => {
      if (error.message.includes('409')) {
        errorHandler()
      }
      console.log('create parent error happened', error)
      dispatch(setSpinner(false))
    })
}

export const setFamilySchoolGroup = data => ({
  type: FAMILY_ACTION.SET_FAMILY_SCHOOL_GROUP,
  payload: data
})

export const getFamilySchoolGroup = schoolId => dispatch => {
  getSchoolGroupBySchoolId(schoolId)
    .then(result => {
      if (result.data && result.data.statusCode &&
        result.data.statusCode.code === 0) {
        dispatch(setFamilySchoolGroup(result.data.data))
      }
    }).catch(error => {
      console.log('get family school group happened', error)
    })
}

export const setMembersState = (member) => ({
  type: FAMILY_ACTION.SET_MEMBERS_STATE,
  payload: member
})

export const clearMembersState = () => ({
  type: FAMILY_ACTION.CLEAR_MEMBERS_STATE,
  payload: []
})

export const setAdviceTopics = data => ({
  type: FAMILY_ACTION.SET_ADVICE_TOPICS,
  payload: data
})

export const getAllAdviceTopics = () => dispatch => {
  getAdviceTopics()
    .then(result => {
      if (result.data && result.data.statusCode &&
        result.data.statusCode.code === 0) {
        dispatch(setAdviceTopics(result.data.data))
      }
    }).catch(error => {
      console.log('get advice topics error happened', error)
    })
}

export const addToBlackList = (familyId, data) => async dispatch => {
  try {
    dispatch(setSpinner(true))

    const result = await addFamilyToBlackList(familyId, data)
    await dispatch(getPersonEvents(familyId))
    if (result?.data?.statusCode?.code === 0) {
      dispatch(getFamily(familyId))
    }
  } catch (err) {
    console.log('add family to black list err', err)
  } finally {
    dispatch(setSpinner(false))
  }
}
export const removeFromBlackListAction = (familyId, data) => async dispatch => {
  try {
    dispatch(setSpinner(true))

    const result = await removeFamilyFromBlackList(familyId, data)
    await dispatch(getPersonEvents(familyId))
    if (result?.data?.statusCode?.code === 0) {
      dispatch(getFamily(familyId))
    }
  } catch (err) {
    console.log('add family to black list err', err)
  } finally {
    dispatch(setSpinner(false))
  }
}

export const changeVisibleAdultPopup = (visible) => {
  return {
    type: FAMILY_ACTION.CHANGE_VISIBLE_ADULT_POPUP,
    payload: visible
  }
}
export const setDefaultValuesAdultPopup = (data) => {
  return {
    type: FAMILY_ACTION.SET_DEFAULT_PROPS_ADULT_POPUP,
    payload: data
  }
}
