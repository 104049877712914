/**
 * @param {Object} obj - Object to delete keys from
 * @param {Array.<String>} keys - Keys to delete from the object
 * @returns {Object} Returns object without specified keys
 */
const deleteObjectFields = (obj, keys) => {
  const result = { ...obj };

  for (const key of keys) {
    delete result[key];
  }

  return result;
};

/**
 * @param {Array.<Object>} objs - Array of objects to select unique objects from
 * @param {String} key - The key to compare objects by
 * @returns {Array.<Object>} Returns unique objects based on provided key
 */
const getUniqueObjects = (objs, key) => {
  const uniqueObjects = [];

  objs.forEach((obj) => {
    if (!uniqueObjects.find((uniqueObj) => uniqueObj[key] === obj[key])) {
      uniqueObjects.push(obj);
    }
  });

  return uniqueObjects;
};

export { deleteObjectFields, getUniqueObjects };
