import axios from 'axios'
import {
  BASE_NOTIFICATION_SERVICE_URL,
  BASE_UAA_SERVICE_URL,
  CLIENTS_API,
  EVENTS_API
} from '../constants/api'

export const createEvent = data => {
  return axios({
    url: EVENTS_API,
    method: 'post',
    headers: { 'content-type': 'application/json' },
    withCredentials: true,
    data: JSON.stringify(data)
  })
}

export const createReminderQuery = data => {
  return axios({
    url: `${BASE_UAA_SERVICE_URL}/reminders`,
    method: 'post',
    headers: { 'content-type': 'application/json' },
    withCredentials: true,
    data
  })
}

export const getClientEvents = async (
  clientId, {
    page,
    size,
    ...rest
  } = {}, userRole) => {
  let url = `${CLIENTS_API}/${clientId}/events?`

  if (page !== undefined) {
    url += `page=${page}&`
  }
  if (size !== undefined) {
    url += `size=${size}&`
  }
  if (userRole) {
    url += `authority=${userRole}`
  }
  const response = await axios.post(url, rest, {
    headers: { 'content-type': 'application/json' },
    withCredentials: true
  })

  return {
    list: response?.data?.data?.content || [],
    total: response?.data?.data?.totalElements
  }
}

export const updateComment = (commentId, data) => {
  return axios({
    url: `${EVENTS_API}/${commentId}`,
    method: 'put',
    headers: { 'content-type': 'application/json' },
    withCredentials: true,
    data: JSON.stringify(data)
  })
}

export const sendEmail = data => {
  return axios({
    url: `${BASE_NOTIFICATION_SERVICE_URL}/esputnik/mail/send`,
    method: 'post',
    headers: { 'content-type': 'application/json' },
    withCredentials: true,
    data: JSON.stringify(data)
  })
}

export const sendMessages = (data) => {
  return axios({
    url: `${BASE_NOTIFICATION_SERVICE_URL}/notifications/multiple`,
    method: 'post',
    headers: { 'content-type': 'application/json' },
    withCredentials: true,
    data: JSON.stringify(data)
  })
}
