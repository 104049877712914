export const mockFinSettings = [
  {
    id: 22,
    createdAt: new Date(),
    school: 'Сосюри, 5',
    author: 'Володимир Петрович Сидоров',
    title: 'Повна',
    periodType: 'Щомісячна',
    dateFrom: new Date(),
    dateTo: new Date(),
    amount: '10',
    tariff: '100',
    total: 1000,
    comment: 'New York Park'
  }
]

export const mockCertification = [
  {
    key: '1',
    id: 1,
    recordDate: new Date(),
    eventDate: new Date(),
    eventType: 'Створення профайлу',
    educationalLevel: 'Junior',
    educationalCourses: 'Підвищення кваліфікації, 2015р',
    teacherLevel: 'STEM-викладач',
    recordAuthor: 'Петро Петров',
    comment: '',
    certificateNumber: '1213245427468'
  },
  {
    key: '2',
    id: 2,
    recordDate: new Date(),
    eventDate: new Date(),
    eventType: 'Створення профайлу',
    educationalLevel: 'Junior',
    educationalCourses: 'Підвищення кваліфікації, 2015р',
    teacherLevel: 'STEM-викладач',
    recordAuthor: 'Петро Петров',
    comment: '',
    certificateNumber: '1213245427468'
  },
  {
    id: 3,
    key: '3',
    recordDate: new Date(),
    eventDate: new Date(),
    eventType: 'Створення профайлу',
    educationalLevel: 'Junior',
    educationalCourses: 'Підвищення кваліфікації, 2015р',
    teacherLevel: 'STEM-викладач',
    recordAuthor: 'Петро Петрович Петров',
    comment: '',
    certificateNumber: '1213245427468'
  }
]

export const mockLessonsPayments = [
  {
    key: '1',
    date: (new Date().toISOString().match(/\d*-\d*-\d*/) || [])[0]?.split(
      '-').reverse().join('.'),
    school: 'Browdfsdf',
    studyLevel: 32,
    course: 'New York No. 1',
    group: 'developer',
    qualification: 'developer',
    category: 'developer',
    surcharge: 'developer',
    total: '500'
  },
  {
    key: '2',
    date: (new Date().toISOString().match(/\d*-\d*-\d*/) || [])[0]?.split(
      '-').reverse().join('.'),
    school: 'Brown',
    studyLevel: 32,
    course: 'New York No. 2',
    group: 'developer',
    qualification: 'developer',
    category: 'developer',
    surcharge: 'developer',
    total: '600'
  },
  {
    key: '3',
    date: (new Date().toISOString().match(/\d*-\d*-\d*/) || [])[0]?.split(
      '-').reverse().join('.'),
    school: 'Brown',
    studyLevel: 32,
    course: 'New York No. 2',
    group: 'developer',
    qualification: 'developer',
    category: 'developer',
    surcharge: 'developer',
    total: '600'
  },
  {
    key: '4',
    date: (new Date().toISOString().match(/\d*-\d*-\d*/) || [])[0]?.split(
      '-').reverse().join('.'),
    school: 'Brown',
    studyLevel: 32,
    course: 'New York No. 2',
    group: 'developer',
    qualification: 'developer',
    category: 'developer',
    surcharge: 'developer',
    total: '600'
  },
  {
    key: '5',
    date: (new Date().toISOString().match(/\d*-\d*-\d*/) || [])[0]?.split(
      '-').reverse().join('.'),
    school: 'Brown',
    studyLevel: 32,
    course: 'New York No. 2',
    group: 'developer',
    qualification: 'developer',
    category: 'developer',
    surcharge: 'developer',
    total: '600'
  },
  {
    key: '6',
    date: (new Date().toISOString().match(/\d*-\d*-\d*/) || [])[0]?.split(
      '-').reverse().join('.'),
    school: 'Brown',
    studyLevel: 32,
    course: 'New York No. 2',
    group: 'developer',
    qualification: 'developer',
    category: 'developer',
    surcharge: 'developer',
    total: '600'
  }]

export const mockAdditionalPayments = [
  {
    key: '1',
    date: (new Date().toISOString().match(/\d*-\d*-\d*/) || [])[0]?.split(
      '-').reverse().join('.'),
    school: 'Brown',
    paymentType: 32,
    amount: '100',
    tariff: 'developer',
    comment: 'developer',
    total: '500'
  },
  {
    key: '2',
    date: (new Date().toISOString().match(/\d*-\d*-\d*/) || [])[0]?.split(
      '-').reverse().join('.'),
    school: 'Brown',
    paymentType: 32,
    amount: '200',
    tariff: 'developer',
    comment: 'developer',
    total: '600'
  }]

export const mockCourses = [
  { id: 212, name: 'Підвищення кваліфікації, 2015р' },
  { id: 312, name: 'STEM-викладач, 2017р' },
  { id: 31242, name: 'Курс молодого бійця, 2020р' }]
