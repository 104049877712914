export const BASE_URL = process.env.NODE_ENV === 'production'
  ? process.env.REACT_APP_BASE_URL
  : process.env.REACT_APP_BASE_LOCAL_URL

export const SSE_API = process.env.REACT_APP_BASE_URL_SSE
export const BILL_API = process.env.REACT_APP_BASE_URL_BILL

export const BASE_SCHOOL_SERVICE_URL = `${BASE_URL}/services/schoolservice/api`
export const BASE_UAA_SERVICE_URL = `${BASE_URL}/services/uaaservice/api`
export const BASE_TASK_SERVICE_URL = `${BASE_URL}/services/taskservice/api`
export const BASE_NOTIFICATION_SERVICE_URL = `${BASE_URL}/services/notificationservice/api`
export const BASE_FILE_SERVICE_URL = `${BASE_URL}/services/fileservice`
export const BASE_PAYMENT_SERVICE_URL = `${BASE_URL}/services/paymentservice`
export const BASE_CALL_SERVICE_URL = `${BASE_URL}/services/callservice/api`

export const LOGIN_API = `${BASE_URL}/auth/login`
export const RESET_PSWD_INIT_API = `${BASE_URL}/services/uaaservice/api/account/reset-password/init`
export const RESET_PSWD_FINISH_API = `${BASE_URL}/services/uaaservice/api/account/reset-password/finish`

export const PAGES_MENU_API = `${BASE_URL}/services/uaaservice/api/settings/page-menu`

export const LEEDS_DATA_API = `${BASE_URL}/services/uaaservice/api/leads/filter`
export const ADD_LEED_ADDITIONAL_FIELD_API = `${BASE_URL}/services/uaaservice/api/additional-field`
export const GET_LEED_ADDITIONAL_FIELDS_API = `${BASE_URL}/services/uaaservice/api/additional-field/lead/school`
export const EDIT_LEED_BY_ID_API = `${BASE_URL}/services/uaaservice/api/leads`
export const GET_LEEDS_AS_XLS_API = `${BASE_URL}/services/uaaservice/api/leads/filter/xls`
export const DELETE_LEEDS_API = `${BASE_URL}/services/uaaservice/api/leads`
export const EDIT_LEEDS_API = `${BASE_URL}/services/uaaservice/api/leads`

export const GET_CURRENT_EMPLOYEES_API = `${BASE_URL}/services/uaaservice/api/employees/current`
export const GET_EMPLOYEE_GENERAL_API = `${BASE_URL}/services/uaaservice/api/employees/generalInfo/`
export const GET_EMPLOYEE_DETAILED_API = `${BASE_URL}/services/uaaservice/api/employees/detailedInfo/`
export const PUT_EMPLOYEE_GENERAL_API = `${BASE_URL}/services/uaaservice/api/employees/generalInfo`
export const PUT_EMPLOYEE_DETAILED_API = `${BASE_URL}/services/uaaservice/api/employees/detailedInfo`
export const CHANGE_ROLE_API = `${BASE_URL}/services/uaaservice/api/settings/change-role`
export const EMPLOYMENT_INFO_API = `${BASE_URL}/services/uaaservice/api/employmentInformation`
export const GET_LEAD_BY_ID = `${BASE_URL}/services/uaaservice/api/leads`
export const ONE_FAMILY_API = `${BASE_URL}/services/uaaservice/api/family`
export const ALL_FAMILIES_API = `${BASE_URL}/services/uaaservice/api/families`
export const CHANGE_ACTIVE_SCHOOL = `${BASE_URL}/services/uaaservice/api/settings/change-active-school`
export const PARENT_API = `${BASE_URL}/services/uaaservice/api/parent`
export const GET_SCHOOL_GROUP = `${BASE_URL}/services/schoolservice/api/schools/group-school-name`
export const LEAD_COMMENTS = `${BASE_URL}/services/uaaservice/api/comments`
export const STUDENT_API = `${BASE_URL}/services/uaaservice/api/students`
export const CLIENTS_API = `${BASE_URL}/services/uaaservice/api/clients`
export const WISHES_API = `${BASE_URL}/services/uaaservice/api/parents/wishes`
export const ADVICES_TOPIC_API = `${BASE_URL}/services/uaaservice/api/parents/adviceTopics`
export const CLASSROOMS = `${BASE_SCHOOL_SERVICE_URL}/classrooms`
export const COURSES_BY_SCHOOLS = `${BASE_URL}/services/schoolservice/api/courses/schools`
export const COURSE_API = `${BASE_URL}/services/schoolservice/api/courses`
export const GET_EMPLOYEE_SCHOOL_ID_LIST = `${BASE_URL}/services/uaaservice/api/employees/getSchoolsIds`
export const EMPLOYEES_API = `${BASE_URL}/services/uaaservice/api/employees`
export const GROUPS_BY_COURSES = `${BASE_SCHOOL_SERVICE_URL}/education-groups/courses`
export const PARENT_TYPES_API = `${BASE_UAA_SERVICE_URL}/parents/types`
export const COMMUNICATION_TYPES_API = `${BASE_UAA_SERVICE_URL}/communications-types`
export const EVENTS_API = `${BASE_UAA_SERVICE_URL}/events`

// distribution start
export const DISTRIBUTION_PARENTS_API = `${BASE_UAA_SERVICE_URL}/parents/mailing`
export const DISTRIBUTION_TEMPLATES_API = `${BASE_NOTIFICATION_SERVICE_URL}/notification-patterns`
export const MAILING_NOTIFICATIONS_API = `${BASE_NOTIFICATION_SERVICE_URL}/mailing-notifications`
export const CREATE_DISTRIBUTION_API = `${MAILING_NOTIFICATIONS_API}/create`
// distribution end

export const ALBUMS_API = `${BASE_URL}/services/fileservice/api/albums`
export const FILES_API = `${BASE_URL}/services/fileservice/api/files`

export const CUSTOM_FIELDS_API = `${BASE_URL}/services/uaaservice/api/custom-field-pattern`
export const GROUPS_API = `${BASE_SCHOOL_SERVICE_URL}/education-groups`

export const GET_COUNTRIES_LIST = `${BASE_SCHOOL_SERVICE_URL}/countries/all`
export const GET_CURRENCY_LIST = `${BASE_SCHOOL_SERVICE_URL}/currencies/all`
export const GET_LANGUAGES_LIST = `${BASE_SCHOOL_SERVICE_URL}/languages/all`
export const GET_TIMEZONES_LIST = `${BASE_SCHOOL_SERVICE_URL}/time-zones/all`
export const GET_COUNTRIES = `${BASE_SCHOOL_SERVICE_URL}/countries`
export const GET_COUNTRY_INFO = `${BASE_SCHOOL_SERVICE_URL}/country`
export const CREATE_SCHOOL_GROUP = `${BASE_SCHOOL_SERVICE_URL}/school-groups`
export const GET_SCHOOLS_BY_SCHOOL_GROUP = `${BASE_SCHOOL_SERVICE_URL}/settings/school-groups`
export const CREATE_COUNTRY = `${BASE_SCHOOL_SERVICE_URL}/countries`
export const GET_SCHOOL_INFO = `${BASE_SCHOOL_SERVICE_URL}/schools/settings`
export const CREATE_SCHOOL = `${BASE_SCHOOL_SERVICE_URL}/schools`
export const GET_EMPLOYEES_FOR_APPOINTMENT = `${BASE_UAA_SERVICE_URL}/employees/authorities/simplified`
export const SCHOOL_YEAR_SCHEDULE = `${BASE_SCHOOL_SERVICE_URL}/school-schedules`
export const PERIOD_TYPES = `${BASE_SCHOOL_SERVICE_URL}/school-period-types`
export const SCHOOL_PERIODS = `${BASE_SCHOOL_SERVICE_URL}/school-periods`
export const COURSE_LEVELS = `${BASE_SCHOOL_SERVICE_URL}/directions`
export const COURSE_LEVELS_LIST = `${BASE_SCHOOL_SERVICE_URL}/directions/list`
export const COURSES = `${BASE_SCHOOL_SERVICE_URL}/courses`
