import React, { useEffect, useState, lazy, Suspense } from 'react';
import { Switch, Route } from 'react-router-dom';
import i18n from 'i18n-js';
import moment from 'moment';
import 'moment/locale/ru';
import 'moment/locale/ro';
import 'moment/locale/uk';
import { ConfigProvider } from 'antd';
import { AppWrapper } from '../Wrappers/AppWrapper';
import ErrorBoundary from '../../containers/ErrorBoundary/ErrorBoundary';
import { LOCALE_TO_MOMENT, ANT_LOCALES } from '../../constants/text';
import routes from '../../routes/routes.json';
import PageLoader from '../PageLoader/PageLoader';

const Dashboard = lazy(() => import('../../pages/Dashboard/Dashboard'));
const Home = lazy(() => import('../../pages/Home/Home'));
const Signin = lazy(() => import('../../pages/Signin/Signin'));
const ResetPswdInit = lazy(() => import('../../pages/ResetPswdInit/ResetPswdInit'));
const ResetPswdFinish = lazy(() => import('../../pages/ResetPswdFinish/ResetPswdFinish'));

const DEFAULT_LANG = 'ua';

export const App = () => {
  const [antConfigLang, setAntConfigLang] = useState();

  useEffect(() => {
    const lang = localStorage.getItem('language');
    i18n.locale = lang || DEFAULT_LANG;
    moment.locale(LOCALE_TO_MOMENT[i18n.locale]);
    setAntConfigLang(lang || DEFAULT_LANG);
  }, []);

  return (
    <ConfigProvider locale={ANT_LOCALES[antConfigLang]}>
      <ErrorBoundary>
        <Suspense fallback={<PageLoader/>}>
          <AppWrapper>
            <Switch>
              <Route path={routes.HOME_PAGE} exact component={Home}/>
              <Route path={routes.LOGIN_PAGE} exact component={Signin}/>
              <Route path={routes.RESET_PASSWORD_INIT} exact component={ResetPswdInit}/>
              <Route path={routes.RESET_PASSWORD_FINISH} exact component={ResetPswdFinish}/>

              <Route path={routes.DASHBOARD} component={Dashboard}/>
            </Switch>
          </AppWrapper>
        </Suspense>
      </ErrorBoundary>
    </ConfigProvider>
  );
};
