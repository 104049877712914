import axios from 'axios'
import {
  BASE_SCHOOL_SERVICE_URL,
  BASE_URL,
  COMMUNICATION_TYPES_API,
  EVENTS_API,
  GET_CURRENT_EMPLOYEES_API,
  LOGIN_API,
  PARENT_TYPES_API,
  RESET_PSWD_FINISH_API,
  RESET_PSWD_INIT_API
} from '../constants/api'
import { history } from '../history'
import { notification } from 'antd'
import i18n from 'i18n-js'

const withToken = axios.interceptors.request.use((config) => {
  if (config.headers['No-Auth']) {
    return config
  }
  const { host } = window.location
  const accessToken = window.localStorage.getItem(`${host}_access_token`) // TODO remove
  if (accessToken) {
    config.headers.authorization = `Bearer ${accessToken}`
    config.timeout = 120000
  } else {
    config.headers.authorization = null
  }
  return config
})

const removeToken = () => {
  axios.interceptors.request.eject(withToken)
}

axios.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    if (error?.response?.status === 401) {
      history.push('/signin')
    }

    if (error.response?.status === 500 && error.response.data?.detail &&
      typeof error.response.data.detail === 'object') {
      const {
        parameters,
        messageTemplateEn
      } = error.response.data.detail

      if (parameters && messageTemplateEn) {
        const paramsObj = parameters.reduce((a, b) => {
          const [key, value] = Object.entries(b)[0]
          return {
            ...a,
            [key]: value
          }
        }, {})

        notification.error({
          message: i18n.t(`UploadingErrors.${messageTemplateEn}`, paramsObj)
        })
      }
    }

    // return Error object with Promise
    return Promise.reject(error)
  }
)

export const login = (data) => {
  const {
    email,
    password
  } = data // data from login form

  return axios({
    url: LOGIN_API,
    method: 'post',
    headers: {
      'content-type': 'application/json',
      'No-Auth': true
    },
    data: JSON.stringify({
      username: email,
      password: password
    }),
    withCredentials: true
  })
}

export const logout = () => {
  try {
    return axios.post(`${BASE_URL}/auth/logout`, {})
  } catch (e) {
    console.error(e)
  }
}

export const resetPswdInit = (email) => {
  return axios({
    url: RESET_PSWD_INIT_API,
    method: 'post',
    headers: { 'content-type': 'application/json' },
    data: email
  })
}

export const resetPswdFinish = (data) => {
  return axios({
    url: RESET_PSWD_FINISH_API,
    method: 'post',
    headers: { 'content-type': 'application/json' },
    data: JSON.stringify(data)
  })
}

export const getUser = () => {
  return axios({
    url: GET_CURRENT_EMPLOYEES_API,
    method: 'get',
    headers: { 'content-type': 'application/json' },
    withCredentials: true
  })
}

export const getParentTypes = () => {
  return axios({
    url: PARENT_TYPES_API,
    method: 'get',
    headers: { 'content-type': 'application/json' },
    withCredentials: true
  })
}

export const getCommunicationTypes = () => {
  return axios({
    url: COMMUNICATION_TYPES_API,
    method: 'get',
    headers: { 'content-type': 'application/json' },
    withCredentials: true
  })
}

export const getEventTypes = () => {
  return axios({
    url: `${EVENTS_API}/types`,
    method: 'get',
    headers: { 'content-type': 'application/json' },
    withCredentials: true
  })
}

export const search = (searchString, controller) => {
  return axios({
    url: `${BASE_SCHOOL_SERVICE_URL}/search`,
    method: 'get',
    headers: { 'content-type': 'application/json' },
    withCredentials: true,
    signal: controller,
    params: {
      request: searchString
    }
  })
}
