import { PAYMENT_ACTIONS } from '../actions/types';

const initialState = {
  productGroups: null,
  serviceGroups: null,
  courses: null,
  templateLink: null,
};

export const paymentReducer = (state = initialState, action) => {
  switch (action.type) {
    case PAYMENT_ACTIONS.SET_PRODUCT_GROUPS:
      return {...state, productGroups: action.payload};
    case PAYMENT_ACTIONS.GET_SERVICE_GROUPS:
      return {...state, serviceGroups: action.payload};
    case PAYMENT_ACTIONS.CREATE_PRODUCT_GROUP:
      return {...state, productGroups: action.payload};
    case PAYMENT_ACTIONS.CREATE_SERVICE_GROUP:
      return {...state, serviceGroups: action.payload};
    case PAYMENT_ACTIONS.GET_COURSES:
      return {...state, courses: action.payload};
    case PAYMENT_ACTIONS.SET_TEMPLATE_LINK:
      return {...state, templateLink: action.payload};

    default:
      return state;
  }
};