import {
  BASE_PAYMENT_SERVICE_URL,
  BASE_SCHOOL_SERVICE_URL
} from '../constants/api'
import axios from 'axios'
import { t } from 'i18n-js'

export const getOrdersByFamilyId = async ({ familyId }) => {
  const url = `${BASE_PAYMENT_SERVICE_URL}/api/orders/family/${familyId}`
  const response = await axios.get(url)
  return response && response.data && response.data.data
    ? response.data.data
    : []
}

export const getOrders = async ({
  page = 0,
  size = 10,
  sort,
  ...rest
}) => {
  const url = `${BASE_PAYMENT_SERVICE_URL}/api/orders/filter?page=${page}&size=${size}`
  const response = await axios.post(url, rest)
  return response?.data?.data?.content || []
}

export const filterSchoolOrders = async ({
  page,
  size,
  sort,
  schoolId,
  ...rest
}) => {
  let url = `${BASE_PAYMENT_SERVICE_URL}/api/orders/school/${schoolId}/filter?`
  if (page !== undefined) url += `page=${page}&`
  if (size !== undefined) url += `size=${size}&`
  const response = await axios.post(url, rest)
  return {
    list: response?.data?.data?.content || [],
    total: response?.data?.data?.totalElements,
    current: (response?.data?.data?.number || 0) + 1
  }
}

export const createFamilyOrder = async (schoolId, familyId, adminId) => {
  const url = `${BASE_PAYMENT_SERVICE_URL}/api/order`
  const response = await axios.post(url, {
    schoolId,
    familyId,
    adminId
  })
  if (response?.data?.statusCode?.code === 2) {
    throw new Error(t('LeadTabCart.beforeCreatingACartNeedDefineASuppliers'))
  }
  return response && response.data && response.data.data
    ? response.data.data
    : []
}
export const getSchoolGroupBySchoolId = async (schoolId) => {
  const url = `${BASE_SCHOOL_SERVICE_URL}/school-group/school/${schoolId}`
  const response = await axios.get(url)
  return response && response.data && response.data.data
    ? response.data.data
    : {}
}

export const exportFinancesByFilters = async (
  schoolId, filters, templateId) => {
  let url = `${BASE_PAYMENT_SERVICE_URL}/api/orders/school/${schoolId}/excel?`

  if (templateId !== undefined) {
    url += `orderTemplateId=${templateId}&`
  }
  return await axios.post(url, filters, { responseType: 'blob' })
}

export const loadReportFor1C = (schoolId, filters) => {
  const url = `${BASE_PAYMENT_SERVICE_URL}/api/orders/school/${schoolId}/excel/1c`
  return axios.post(url, filters, { responseType: 'blob' })
}

export const getSchoolGroupTemplates = async (schoolGroupId) => {
  const url = `${BASE_PAYMENT_SERVICE_URL}/api/orders/school-group/${schoolGroupId}/template`
  const response = await axios.get(url)
  return response && response.data && response.data.data
    ? response.data.data
    : {}
}

export const createSchoolGroupTemplate = async (schoolGroupId, data) => {
  const url = `${BASE_PAYMENT_SERVICE_URL}/api/orders/school-group/${schoolGroupId}/template`
  const response = await axios.post(url, data)
  return response && response.data && response.data.data
    ? response.data.data
    : {}
}

export const updateSchoolGroupTemplate = async (templateId, data) => {
  const url = `${BASE_PAYMENT_SERVICE_URL}/api/orders/template/${templateId}`
  const response = await axios.put(url, data)
  return response && response.data && response.data.data
    ? response.data.data
    : {}
}

export const deleteSchoolGroupTemplate = async (templateId) => {
  const url = `${BASE_PAYMENT_SERVICE_URL}/api/orders/template/${templateId}`
  const response = await axios.delete(url)
  return response && response.data && response.data.data
    ? response.data.data
    : {}
}
