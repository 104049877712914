import { SPINNER_ACTIONS } from './types';

export const setActionTabsSpinner = flag => ({
  type: SPINNER_ACTIONS.SET_ACTION_TAB_SPINNER,
  payload: flag
});

export const setEventSpinner = flag => ({
  type: SPINNER_ACTIONS.SET_EVENT_SPINNER,
  payload: flag
});