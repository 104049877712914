import { SETTINGS_ACTIONS } from '../actions/types'

const initialState = {
  countriesData: [],
  selectedSchool: {},
  selectedCountrySchools: [],
  selectedPeriod: {},
  selectedSchoolCourses: [],
  isSchoolGroupsLoading: [],
  isCoursesLoading: [],
  isCountriesLoading: false,
  isSchoolLoading: false,
  isSchoolPeriodLoading: false,
  isSchoolPeriodsListLoading: false,
  levelsExpanded: [],
  courseExpanded: [],
  cabinets: [],
  isSourceLoading: false,
  schoolsInSelectedGroup: []
}

export const generalSettingsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SETTINGS_ACTIONS.SET_COUNTRIES: {
      return { ...state, countriesData: action.data }
    }
    case SETTINGS_ACTIONS.ADD_COUNTRY: {
      return { ...state, countriesData: [...state.countriesData, action.data] }
    }
    case SETTINGS_ACTIONS.UPDATE_COUNTRY: {
      const countryIndex = state.countriesData.findIndex(
        item => item.id === action.data?.id)
      if (countryIndex >= 0) {
        const newCountriesData = [...state.countriesData]
        newCountriesData.splice(countryIndex, 1, action.data)
        return { ...state, countriesData: [...newCountriesData] }
      }
      return state
    }

    case SETTINGS_ACTIONS.SET_SCHOOL_GROUPS: {
      const countryIndex = state.countriesData.findIndex(
        item => item.id === action.countryId)
      if (countryIndex >= 0) {
        const newCountriesDatData = [...state.countriesData]
        newCountriesDatData[countryIndex].schoolGroups = action.data
        return { ...state, countriesData: newCountriesDatData }
      }
      return state
    }
    case SETTINGS_ACTIONS.SET_SCHOOL_GROUPS_LOADING: {
      const newData = [...state.isSchoolGroupsLoading]
      newData[action.data?.countryId] = action.data?.status
      return {
        ...state,
        isSchoolGroupsLoading: newData
      }
    }
    case SETTINGS_ACTIONS.ADD_SCHOOL_GROUP: {
      const countryIndex = state.countriesData.findIndex(
        item => item.id === action.data?.countryId)
      if (countryIndex >= 0) {
        const newCountriesData = [...state.countriesData]
        newCountriesData[countryIndex].schoolGroups = [
          ...newCountriesData[countryIndex].schoolGroups,
          action.data]
        return { ...state, countriesData: [...newCountriesData] }
      }
      return state
    }
    case SETTINGS_ACTIONS.UPDATE_SCHOOL_GROUP: {
      console.log(action.data)
      const countryIndex = state.countriesData.findIndex(
        item => item.id === action.data?.countryId)
      console.log('countryIndex', countryIndex)
      if (countryIndex >= 0) {
        const newCountriesData = [...state.countriesData]
        const schoolGroupIndex = newCountriesData[countryIndex].schoolGroups?.findIndex(
          group => group.id === action.data?.id)
        console.log('schoolGroupIndex', schoolGroupIndex)
        newCountriesData[countryIndex].schoolGroups[schoolGroupIndex] = action.data
        return { ...state, countriesData: [...newCountriesData] }
      }
      return state
    }
    case SETTINGS_ACTIONS.UPDATE_PERIOD_LEVEL: {
      const levels = state.selectedPeriod?.levels
      console.log(levels, action.data)
      if (Array.isArray(levels)) {
        const levelIndex = levels.findIndex(item => item.id === action.data?.id)
        if (levelIndex >= 0) {
          console.log(levels[levelIndex])
          console.log(action.data)
          levels[levelIndex] = {
            ...levels[levelIndex],
            levelId: action.data?.levelId,
            name: action.data?.name
          }
          return ({
            ...state,
            selectedPeriod: { ...state.selectedPeriod, levels: levels }
          })
        } else {
          return ({
            ...state,
            selectedPeriod: {
              ...state.selectedPeriod,
              levels: [...levels, action.data]
            }
          })
        }
      }
      return state
    }
    case SETTINGS_ACTIONS.UPDATE_PERIOD_LEVEL_COURSE: {
      const levels = state.selectedPeriod?.levels
      if (Array.isArray(levels)) {
        const levelIndex = levels.findIndex(
          item => item.id === action.data?.levelId)
        if (levelIndex >= 0) {
          let courses = levels[levelIndex].courseDTOList
          console.log({ courses })
          if (!courses || !Array.isArray(courses)) {
            courses = [action.data]
          } else {
            const courseIndex = courses.findIndex(
              el => el.id === action.data?.id)
            if (courseIndex >= 0) {
              courses[courseIndex] = { ...courses[courseIndex], ...action.data }
            } else {
              courses.push(action.data)
            }
          }
          levels[levelIndex].courseDTOList = [...courses]
          console.log(levels[levelIndex].courseDTOList)
          return ({
            ...state,
            selectedPeriod: { ...state.selectedPeriod, levels: levels }
          })
        }
      }
      return state
    }

    case SETTINGS_ACTIONS.SET_SELECTED_SCHOOL_COURSES: {
      return { ...state, selectedSchoolCourses: action.data }
    }

    case SETTINGS_ACTIONS.SET_LEVELS_EXPANDED: {
      return { ...state, levelsExpanded: action.data }
    }
    case SETTINGS_ACTIONS.SET_COURSE_EXPANDED: {
      return { ...state, courseExpanded: action.data }
    }

    case SETTINGS_ACTIONS.UPDATE_GROUP_IN_PERIOD: {
      const levels = state.selectedPeriod?.levels
      if (Array.isArray(levels)) {
        const levelIndex = levels.findIndex(
          item => item.id === action.data?.levelId)
        if (levelIndex >= 0) {
          const courses = levels[levelIndex].courseDTOList
          const courseIndex = courses.findIndex(
            el => el.id === action.data?.courseId)
          if (courseIndex >= 0) {
            let groups = courses[courseIndex].educationalGroups
            console.log('Groups before', groups)
            if (!groups || groups?.length === 0) {
              groups = [action.data]
            } else if (Array.isArray(groups)) {
              const groupIndex = groups.findIndex(
                gr => gr.id === action.data.id)
              if (groupIndex >= 0) {
                console.log('groupIndex', groupIndex)
                console.log(action.data)
                groups[groupIndex] = action.data
                console.log('groups[groupIndex]', groups[groupIndex])
              } else {
                groups.push(action.data)
              }
            }
            console.log('Groups after:', groups)
            courses[courseIndex].educationalGroups = groups
            levels[levelIndex].courseDTOList = [...courses]
            console.log('levels:', levels)
            return ({
              ...state,
              selectedPeriod: { ...state.selectedPeriod, levels: levels }
            })
          }
        }
      }
      return state
    }

    case SETTINGS_ACTIONS.SET_SELECTED_SCHOOL: {
      return { ...state, selectedSchool: action.data }
    }
    case SETTINGS_ACTIONS.SET_SELECTED_PERIOD: {
      return { ...state, selectedPeriod: action.data }
    }
    case SETTINGS_ACTIONS.SET_SCHOOL_LOADING: {
      return { ...state, isSchoolLoading: action.data }
    }
    case SETTINGS_ACTIONS.SET_PERIOD_LOADING: {
      return { ...state, isSchoolPeriodLoading: action.data }
    }

    case SETTINGS_ACTIONS.SET_SOURCE_LIST: {
      return { ...state, [action.listType]: action.data }
    }

    case SETTINGS_ACTIONS.SET_SOURCE_LOADING: {
      return { ...state, isSourceLoading: action.data }
    }

    case SETTINGS_ACTIONS.SET_SCHOOLS_FROM_SELECTED_GROUP: {
      return { ...state, schoolsInSelectedGroup: action.data }
    }

    case SETTINGS_ACTIONS.SET_SELECTED_COUNTRY_SCHOOLS: {
      return { ...state, selectedCountrySchools: action.data }
    }

    default:
      return state
  }
}
