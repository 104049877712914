import React from 'react';
import { Spin } from 'antd';

const PageLoader = () => {
  return <div style={{
    width: '100vw',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }}>
    <Spin spinning size='large'/>
  </div>;
};

export default React.memo(PageLoader);