import * as eventsApi from '../../utils/events'
import { sendEmail, sendMessages } from '../../utils/events'
import { setSpinner } from './index'

export const EVENT_TYPES = {
  GET_EVENTS: 'GET_EVENTS',
  UPDATE_EVENTS: 'UPDATE_EVENTS'
}

export const sendEmailToParent = (data) => () => {
  sendEmail(data).then(result => {
    console.log(result)
  }).catch(err => {
    console.log('send email ro parent error', err)
  })
}

export const sendMessageToParent = (data, callback = () => {}) => (
  dispatch, getState) => {
  dispatch(setSpinner(true))
  sendMessages(data).then(result => {
    if (result.data && result.data.statusCode && result.data.statusCode.code ===
      0) {
      callback()
      const { family: { family } } = getState()
      dispatch(getClientEvents(family.id, { page: 0, size: 30 }))
    }
  }).catch(err => {
    console.log('send message to parent error', err)
  }).finally(() => dispatch(setSpinner(false)))
}

export const getClientEvents = (clientId, filters) => async (
  dispatch, getState) => {
  try {
    const { currentRole } = getState().auth
    const { list, total } = await eventsApi.getClientEvents(clientId, filters,
      currentRole)
    dispatch({
      type: EVENT_TYPES.GET_EVENTS,
      payload: {
        list,
        total
      }
    })
  } catch (err) {
    console.error('catch an error', err)
  }
}

export const updateEventList = (clientId, filters) => async (
  dispatch, getState) => {
  try {
    const { currentRole } = getState().auth
    const { list, total } = await eventsApi.getClientEvents(clientId, filters,
      currentRole)

    dispatch({
      type: EVENT_TYPES.UPDATE_EVENTS,
      payload: {
        list,
        total
      }
    })
  } catch (err) {
    console.error('catch an error', err)
  }
}
