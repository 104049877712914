import { EVENT_TYPES } from '../actions/events';

const initialState = {
  events: [],
  paging: {
    total: 0,
  },
};

export const eventReducer = (state = initialState, action) => {
  const { payload, type } = action;

  switch (type) {
    case EVENT_TYPES.GET_EVENTS :
      return {
        ...state,
        events: payload.list,
        paging: {
          ...state.paging,
          total: payload.total
        }
      };
    case EVENT_TYPES.UPDATE_EVENTS :
      return {
        ...state,
        events: [...state.events, ...payload.list],
        paging: {
          ...state.paging,
          total: payload.total
        }
      };
    default:
      return state;
  }
};
