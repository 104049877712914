import {BASE_PAYMENT_SERVICE_URL} from '../constants/api';
import axios from 'axios';

export const getProducts = async (schoolId, { size, page, sort, ...rest}) => {
  let url = `${BASE_PAYMENT_SERVICE_URL}/api/product/filter?`;

  if (page !== undefined) {
    url += `&page=${page}`;
  }
  if (size !== undefined) {
    url += `&size=${size}`;
  }

  // todo sort
  const response = await axios.post(url, { schoolId, ...rest });
  return response && response.data ? {
    list: response?.data?.data?.content || [],
    total: response?.data?.data?.totalElements || 0,
    current: (response?.data?.data?.number || 0) + 1
  } : {list: [], total: 0};
};

export const getProductById = async (productId) => {
  const url = `${BASE_PAYMENT_SERVICE_URL}/api/product/${productId}`;
  const response = await axios.get(url);
  return response?.data?.data || {};
};

export const updateProduct = async (id, params) => {
  const url = `${BASE_PAYMENT_SERVICE_URL}/api/product/${id}`;
  const response = await axios.put(url, params);
  return response && response.data && response.data.data ? response.data.data : {};
};

export const createProduct = async (schoolId, params) => {
  const url = `${BASE_PAYMENT_SERVICE_URL}/api/product`;
  const response = await axios.post(url, { schoolId, ...params });
  return response && response.data && response.data.data ? response.data.data : false;
};

export const deleteProduct = async (productId) => {
  const url = `${BASE_PAYMENT_SERVICE_URL}/api/product/${productId}`;
  const response = await axios.delete(url);
  return !!(response && response.data);
};

export const getPriceLinks = async () => {
  const url = `${BASE_PAYMENT_SERVICE_URL}/api/priceLink`;
  const response = await axios.get(url);
  return response && response.data && response.data.data ? response.data.data : [];
};

export const createPriceLink = async (data) => {
  const url = `${BASE_PAYMENT_SERVICE_URL}/api/priceLink`;
  const response = await axios.post(url, data);
  return response && response.data && response.data.data ? response.data.data : false;
};

export const updatePriceLink = async (id, data) => {
  const url = `${BASE_PAYMENT_SERVICE_URL}/api/priceLink/${id}`;
  const response = await axios.put(url, data);
  return response && response.data && response.data.data ? response.data.data : false;
};

export const deletePriceLink = async (id) => {
  const url = `${BASE_PAYMENT_SERVICE_URL}/api/priceLink/${id}`;
  const response = await axios.delete(url);
  return response && response.data && response.data.data ? response.data.data : false;
};

export const getActiveDiscounts = async (schoolId) => {
  const url = `${BASE_PAYMENT_SERVICE_URL}/api/activeDiscount?schoolId=${schoolId}`;
  const response = await axios.get(url);
  return response && response.data && response.data.data ? response.data.data : [];
};

export const createActiveDiscount = async (discount, schoolId) => {
  const url = `${BASE_PAYMENT_SERVICE_URL}/api/activeDiscount`;
  const response = await axios.post(url, {discountId: discount, enabledAD: false, schoolId});
  return response && response.data && response.data.data ? response.data.data : false;
};

export const updateActiveDiscount = async (activeDiscount, value) => {
  const url = `${BASE_PAYMENT_SERVICE_URL}/api/activeDiscount/${activeDiscount.id}`;
  const response = await axios.put(url, {...activeDiscount, enabledAD: value});
  return response && response.data && response.data.data ? response.data.data : false;
};
export const getProductsLastUpdated = async schoolId => {
  const url = `${BASE_PAYMENT_SERVICE_URL}/api/product/lastUpdatedDate?schoolId=${schoolId}`;
  const response = await axios.get(url);
  return response && response.data && response.data.data ? response.data.data : null;
};

export const uploadExcelFile = async (data, date) => {
    const url = `${BASE_PAYMENT_SERVICE_URL}/api/product-price/upload?startDate=${date}`;
    return axios.post(url, data);
};
