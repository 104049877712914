import { CART_TYPES } from '../actions/cart'

const initialState = {
  products: [],
  services: [],
  productsTotal: 0,
  servicesTotal: 0,
  total: 0,
  familyChildren: [],
  piggyBank: undefined,
  deposit: [],
  journal: [],
  stems: [],
  leadChildren: [],
  nextProcessingList: [],
  orderStatuses: undefined,
  results: {},
  payment: {},
  order: {},
  orderLoading: false
}

export const cartReducer = (state = initialState, action) => {
  const { payload, type } = action

  switch (type) {
    case CART_TYPES.SET_ORDER_LOADING:
      return { ...state, orderLoading: action.payload }
    case CART_TYPES.GET_ORDER :
      return {
        ...state,
        ...payload
      }
    case CART_TYPES.GET_FAMILY_CHILDREN :
      return {
        ...state,
        familyChildren: payload
      }
    case CART_TYPES.ADD_PRODUCT_ROW :
      return {
        ...state,
        products: [...state.products, payload]
      }
    case CART_TYPES.ADD_SERVICE_ROW :
      return {
        ...state,
        services: [...state.services, payload]
      }
    case CART_TYPES.CREATE_ORDER_PRODUCT :
      return {
        ...state,
        ...payload
      }
    case CART_TYPES.CREATE_ORDER_SERVICE :
      return {
        ...state,
        ...payload
      }
    case CART_TYPES.UPDATE_PRODUCT_ROW :
      return {
        ...state,
        products: payload
      }
    case CART_TYPES.UPDATE_SERVICE_ROW :
      return {
        ...state,
        services: payload
      }
    case CART_TYPES.UPDATE_EXISTS_PRODUCT_ROW :
      return {
        ...state,
        ...payload
      }
    case CART_TYPES.UPDATE_EXISTS_SERVICE_ROW :
      return {
        ...state,
        ...payload
      }
    case CART_TYPES.SAVE_ORDER :
      return {
        ...state,
        activePayment: undefined
      }
    case CART_TYPES.DELETE_ORDER_DETAIL :
      return {
        ...state,
        products: state?.products?.filter(i => i.id !== payload),
        services: state?.services?.filter(i => i.id !== payload)
      }
    case CART_TYPES.DELETE_EXISTS_ORDER_DETAIL :
      return {
        ...state,
        activePayment: undefined
      }
    case CART_TYPES.GET_PIGGY_BANK :
      return {
        ...state,
        piggyBank: payload
      }
    case CART_TYPES.GET_ORDER_STATUSES :
      return {
        ...state,
        orderStatuses: payload
      }
    case CART_TYPES.ADD_DEPOSIT_ROW :
      return {
        ...state,
        ...payload
      }
    case CART_TYPES.GET_ORDER_RESULTS :
      return {
        ...state,
        results: payload
      }
    case CART_TYPES.SPENT_FROM_DEPOSIT :
      return {
        ...state,
        payment: payload
      }
    case CART_TYPES.CHANGE_PIGGY_BANK_AMOUNT :
      return {
        ...state,
        services: payload
      }
    case CART_TYPES.UPDATE_STEMS :
      return {
        ...state,
        stems: payload
      }
    case CART_TYPES.GET_FAMILY_STEMS :
      return {
        ...state,
        stems: payload
      }
    case CART_TYPES.SET_ORDER_FOP :
      return {
        ...state,
        results: payload
      }
    default:
      return state
  }
}
