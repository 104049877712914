import { PRODUCT_PRICES_TYPES } from '../actions/productPrices'

const initialState = {
  products: [],
  pagination: {
    total: null,
    current: 1,
    pageSize: 10
  },
  priceLinks: [],
  lastUpdated: null,
  activeDiscounts: []
}

export const productPricesReducer = (state = initialState, action) => {
  const { payload, type } = action

  switch (type) {
    case PRODUCT_PRICES_TYPES.GET_PRODUCTS:
      return {
        ...state,
        products: payload.list,
        pagination: {
          total: payload.total,
          current: payload.current
        }
      }
    // case PRODUCT_PRICES_TYPES.CREATE_PRODUCT:
    //   return {...state, products: payload};
    // case PRODUCT_PRICES_TYPES.UPDATE_PRODUCT:
    //   return {...state, products: payload};
    case PRODUCT_PRICES_TYPES.GET_PRICE_LINKS:
      return { ...state, priceLinks: payload }
    case PRODUCT_PRICES_TYPES.GET_ACTIVE_DISCOUNTS:
      return { ...state, activeDiscounts: payload }
    case PRODUCT_PRICES_TYPES.UPDATE_ACTIVE_DISCOUNT:
      return { ...state, activeDiscounts: payload }
    case PRODUCT_PRICES_TYPES.GET_PRODUCTS_LAST_UPDATED:
      return { ...state, lastUpdated: payload }
      // case PRODUCT_PRICES_TYPES.DELETE_PRODUCT:
      //   return {...state, products: payload};

    default:
      return state
  }
}
