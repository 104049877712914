import axios from 'axios'
import {
  BASE_SCHOOL_SERVICE_URL,
  BASE_UAA_SERVICE_URL,
  CLASSROOMS,
  EMPLOYEES_API,
  GET_CURRENT_EMPLOYEES_API,
  GET_SCHOOL_GROUP
} from '../constants/api'

export const getSchoolGroupBySchoolId = id => {
  return axios({
    url: `${GET_SCHOOL_GROUP}/${id}`,
    method: 'get',
    headers: { 'content-type': 'application/json' },
    withCredentials: true
  })
}

export const getActiveSchoolGroupByRoleAndEmployeeId = (role, userId) => {
  return axios.get(`${BASE_UAA_SERVICE_URL}/employees/school-name-list?authority=${role}&employeeId=${userId}`)
}

export const getSchoolsTree = (showHidden) => (
  axios({
    url: `${BASE_SCHOOL_SERVICE_URL}/schools/country-group-list`,
    method: 'get',
    headers: { 'content-type': 'application/json' },
    withCredentials: true,
    params: { hidden: showHidden }
  }
  ))

export const getListOfTeachersForReplace = () => {
  return axios.get(`${BASE_UAA_SERVICE_URL}/employees/teacher/level`)
}

export const getSchoolClassrooms = schoolId => {
  return axios.get(`${BASE_SCHOOL_SERVICE_URL}/schools/${schoolId}/classrooms`)
}

export const getAllClassroomsBySchoolIdList = (schoolIdList) => {
  return axios({
    url: `${CLASSROOMS}/list`,
    method: 'post',
    headers: { 'content-type': 'application/json' },
    withCredentials: true,
    data: JSON.stringify(schoolIdList)
  })
}

export const getAllSchoolsList = (authority, employeeId) => {
  const query = {
    authority,
    employeeId
  }
  return axios({
    url: `${EMPLOYEES_API}/school-name-list`,
    method: 'get',
    headers: { 'content-type': 'application/json' },
    withCredentials: true,
    params: query
  })
}

export const getAllTeachersList = () => {
  return axios({
    url: `${GET_CURRENT_EMPLOYEES_API}/schoolIds`,
    method: 'get',
    headers: { 'content-type': 'application/json' },
    withCredentials: true
  })
}

export const getTeachersBySchoolId = async (schoolId, {
  page,
  size,
  ...rest
} = {}) => {
  let url = `${EMPLOYEES_API}/${schoolId}/teachers?`

  if (page !== undefined) {
    url += `page=${page}&`
  }

  if (size !== undefined) {
    url += `size=${size}&`
  }

  const response = await axios.post(url, rest)
  return response && response.data ? {
    list: response?.data?.data?.content || [],
    total: response?.data?.data?.totalElements || 0,
    current: (response?.data?.data?.number || 0) + 1
  } : {
    list: [],
    total: 0
  }
}

export const getSchoolsGroupsAsList = () => {
  return axios(`${BASE_SCHOOL_SERVICE_URL}/school-groups/getSchoolGroupsAsList`)
}

export const getSchoolGroupByEmployeeId = (id, role) => {
  return axios.get(`${BASE_SCHOOL_SERVICE_URL}/school-group/employee/${id}?authority=${role}`)
}

export const getSchoolGroupIdBySchoolId = schoolId => {
  return axios.get(`${BASE_SCHOOL_SERVICE_URL}/school-group/school/${schoolId}`)
}
